/**
 * @notice cfdStateContract stats
 * @param cfdStateContract contract
 * @param account account Address
 * @param exchangeAddress exchangeAddress Address
 * @return { lastestLongTime , lastestShortTime }
 * 
 */
export const tradingState = async (
  cfdStateContract: any,
  account: any,
  exchangeAddress: any,
) => {
  return cfdStateContract.functions.tradingState(
    exchangeAddress,
    account,
  )
}

/**
 * @notice cfdStateContract stats
 * @param cfdStateContract contract
 * @return waitingPeriodSecs uint256
 * 
 */
export const waitingPeriodSecs = async (
  cfdStateContract: any,
) => {
  return cfdStateContract.functions.waitingPeriodSecs()
}
