import BigNumber from "bignumber.js"

// See details in
// https://www.notion.so/perp/How-to-calculate-liquidation-price-in-Frontend-2ce73c7a1d50402b81a11b4a76efc78d

// liquidationPrice = spotPrice + (openNotional * (maintenanceMarginRatio - marginRatio)) / position
export function getLiquidationPriceWithOpenMarginRatio(
    spotPrice: BigNumber,
    maintenanceMarginRatio: BigNumber,
    openNotional: BigNumber,
    openPositionSize: BigNumber,
    openMarginRatio: BigNumber,
): BigNumber {
    return spotPrice.plus(openNotional.multipliedBy(maintenanceMarginRatio.minus(openMarginRatio)).dividedBy(openPositionSize))
}

// liquidationPrice = spotPrice + (openNotional * maintenanceMarginRatio - currentMargin) / position
export function getLiquidationPriceWithOpenMargin(
    spotPrice: BigNumber,
    maintenanceMarginRatio: BigNumber,
    openNotional: BigNumber,
    openPositionSize: BigNumber,
    openMargin: BigNumber,
): BigNumber {
    return spotPrice.plus(openNotional.multipliedBy(maintenanceMarginRatio).minus(openMargin).dividedBy(openPositionSize))
}
