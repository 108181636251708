import { useEffect, useState } from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import BigNumber from 'bignumber.js'
// Hooks
import { useCfd, usePlatform } from '.'
// Utils
import { tradingState, waitingPeriodSecs } from '../utils/cfd-state'

export const useTradingState = (exchangeAddress: string | undefined): UseQueryResult => {
  const [active, setActive] = useState<boolean>(false)
  const { cfdStateContract, account } = useCfd()
  const { PlatformState: { chainEnabled } } = usePlatform()

  const tradingStateQuery: UseQueryResult = useQuery(`tradingState-${exchangeAddress}`, async () => {
    if (!cfdStateContract) return null
    
    const tx = await tradingState(
      cfdStateContract,
      account,
      exchangeAddress,
    )
    const secs = await waitingPeriodSecs(cfdStateContract)

    return {
      lastestLongTime: new BigNumber(tx?.lastestLongTime?._hex),
      lastestShortTime: new BigNumber(tx?.lastestShortTime?._hex),
      secs: new BigNumber(secs),
    }
  }, {
    enabled: active,
    refetchInterval: 10000,
    initialData: () => {
      return {
        lastestLongTime: new BigNumber(0),
        lastestShortTime: new BigNumber(0),
        secs: new BigNumber(0),
      }
    },
    // onSuccess: (_data: any) => {
    //   console.group('🔎 useTradingState GraphQL result')
    //   console.log(_data)
    //   console.groupEnd()
    // },
    onError: (_error: any) => {
      console.group('❌ useTradingState GraphQL error')
      console.log(_error)
      console.groupEnd()
    }
  })

  useEffect(() => {
    if (!cfdStateContract || !exchangeAddress || !account) return
    if (!chainEnabled) {
      setActive(false)
      return
    }
    setActive(true)
  }, [cfdStateContract, exchangeAddress, account, chainEnabled])

  return tradingStateQuery
}