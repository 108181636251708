import React from 'react'
import { Button as ButtonRebass, Flex } from 'rebass/styled-components'
// Hooks
import { useTheme } from '../../../../hooks'
// Interfaces
import { ButtonProps } from './interfaces'
// Styles
import { Loader } from './index.css'

const Button: React.FC<ButtonProps> = ({
  size,
  loading,
  disabled,
  className,
  children,
  ...rest
}) => {
  const { buttonSize } = useTheme()
  const [width, setWidth] = React.useState<number>(0)
  const [height, setHeight] = React.useState<number>(0)
  const [showLoader, setShowLoader] = React.useState<boolean>(false)
  const ref = React.useRef<any>(null)

  React.useEffect(() => {
    if (loading) {
      setShowLoader(true)
    }

    if (!loading && showLoader) {
      const timeout = setTimeout(() => {
        setShowLoader(false)
      }, 200)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [loading, showLoader])

  React.useEffect(() => {
    if (!children) return
    if (ref.current && ref.current.getBoundingClientRect().width) {
      setWidth(ref.current.getBoundingClientRect().width)
    }
    if (ref.current && ref.current.getBoundingClientRect().height) {
      setHeight(ref.current.getBoundingClientRect().height)
    }
  }, [children, ref])

  switch (size) {
    case 'lg':
      return (
        <ButtonRebass
          disabled={loading || disabled}
          px={buttonSize.xl}
          py={buttonSize.lg}
          width={width ? width : null}
          height={height ? height : null}
          {...rest}
          ref={ref}
          className={className}
        >
          <Flex justifyContent='center' alignItems='center'>
            {showLoader ? <Loader /> : children}
          </Flex>
        </ButtonRebass>
      )
    case 'md':
      return (
        <ButtonRebass
          disabled={loading || disabled}
          px={buttonSize.lg}
          py={buttonSize.md}
          width={width ? width : null}
          height={height ? height : null}
          {...rest}
          ref={ref}
          className={className}
        >
          <Flex justifyContent='center' alignItems='center'>
            {showLoader ? <Loader /> : children}
          </Flex>
        </ButtonRebass>
      )
    case 'sm':
      return (
        <ButtonRebass
          disabled={loading || disabled}
          px={buttonSize.lg}
          py={buttonSize.sm}
          width={width ? width : null}
          height={height ? height : null}
          {...rest}
          ref={ref}
          className={className}
        >
          <Flex justifyContent='center' alignItems='center'>
            {showLoader ? <Loader /> : children}
          </Flex>
        </ButtonRebass>
      )
    default:
      return (
        <ButtonRebass
          disabled={loading || disabled}
          width={width ? width : null}
          height={height ? height : null}
          {...rest}
          ref={ref}
          className={className}
        >
          <Flex justifyContent='center' alignItems='center'>
            {showLoader ? <Loader /> : children}
          </Flex>
        </ButtonRebass>
      )
  }
}

export default Button
