import React from 'react'
import numeral from 'numeral'
import BigNumber from 'bignumber.js'
// Hooks
import { useCfd, usePlatform, useActiveWeb3React } from './'
import { useQuery, useQueries } from 'react-query'
// Utils
//import { getPosition } from '../utils/cfd'
import { getPersonalPositionWithFundingPayment, getUnrealizedPnl } from '../utils/cfd-viewer'
import { bnToDec } from '../utils'
// Constants
import { NUMBER_FORMAT } from '../constants'
import { getMarginRatio } from '../utils/cfd'
import { maintenanceMarginRatio } from '../utils/system-settings'
import { getSpotPrice } from '../utils/exchange'
import { getLiquidationPriceWithOpenMarginRatio } from '../utils/liquidation-calculations'

import EXCHANGE_ABI from '../constants/abis/exchange.json'
import { getContract } from '../utils'

const useGetPositions = (contractsList: any) => {
  const [active, setActive] = React.useState<boolean>(false)
  const { systemSettingsContract, cfdViewerContract } = useCfd()
  const [list, setList] = React.useState([])
  const { library, account } = useActiveWeb3React()
  const { PlatformState: { chainEnabled } } = usePlatform()

  React.useEffect(() => {
    //console.log('getting position for account, contractsList, library', account, contractsList, library)
    if (!cfdViewerContract || !contractsList || contractsList?.isLoading || !library || !account) return
    if (!chainEnabled) {
      setActive(false)
      return
    }
    //console.log('getting position setActive')
    setList(contractsList.data)
    setActive(true)
  }, [account, cfdViewerContract, contractsList, chainEnabled, library])
  
  const result = useQueries(list?.map((contract: any) => (
    {
      queryKey: `getPosition-${contract.addr}`, 
      queryFn: async () => {
        //const tx = await getPosition(cfdContract, account, contract.addr)
        //console.log('getting position for contract', contract)
        if (!library || !account) return

        try {
          const exchangeContract = getContract(
            contract.addr,
            EXCHANGE_ABI,
            library,
            undefined,
          )
          const position = await getPersonalPositionWithFundingPayment(cfdViewerContract, account, contract.addr)
          //console.log('position', position)
          const positionSize = new BigNumber(position?.position.size)
          const openNotional = new BigNumber(position?.position.openNotional)
          const margin = new BigNumber(position?.position.margin)
          const spotPrice = new BigNumber(await getSpotPrice(exchangeContract))
          //console.log('spotPrice', spotPrice.toFixed(18))
          const pnl = positionSize.absoluteValue().isGreaterThan(0) ? new BigNumber(await getUnrealizedPnl(cfdViewerContract, account, contract.addr)) : new BigNumber(0)
          const marginRatio = positionSize.absoluteValue().isGreaterThan(0) ? new BigNumber(await getMarginRatio(cfdViewerContract, account, contract.addr)).dividedBy(1e18) : new BigNumber(0)
          //console.log('marginRatio', marginRatio.toFixed(18))
          const maintenanceMarginRatioResp: BigNumber = new BigNumber(await maintenanceMarginRatio(systemSettingsContract))
          
          const leverage = marginRatio.isGreaterThan(0) ? new BigNumber(1).dividedBy(marginRatio) : new BigNumber(0)
          //console.log('leverage', leverage.toFixed(18))
          //console.log('openNotional', openNotional.toFixed(18))
          //console.log('positionSize', positionSize.toFixed(18))
          const entryPrice = openNotional.dividedBy(positionSize).absoluteValue()
          //console.log('entryPrice', entryPrice.toFixed(18))
  
          const liquidationPrice = getLiquidationPriceWithOpenMarginRatio(
            spotPrice,
            maintenanceMarginRatioResp,
            openNotional,
            positionSize,
            marginRatio.multipliedBy(1e18),
          )
          //console.log('liquidationPrice', liquidationPrice.dividedBy(1e18).toFixed(18))
  
          return {
            exchange: contract.addr,
            result: {
              blockNumber: position[0].blockNumber,
              lastUpdatedCumulativeOvernightFeeRate: position[0].lastUpdatedCumulativeOvernightFeeRate,
              lastUpdatedCumulativePremiumFraction: position[0].lastUpdatedCumulativePremiumFraction,
              liquidityHistoryIndex: position[0].liquidityHistoryIndex,
              margin,
              openNotional,
              size: positionSize,
              liquidationPrice,
              leverage,
              entryPrice,
              pnl,
              marginRatio
            },
            exchangeContract: exchangeContract,
          }
          
        } catch (e) {
          console.log(`error getPosition-${contract.addr}`, e)
        }
      },
      enabled: active,
      cacheTime: 5000,
      refetchInterval: 10000,
      onSuccess: (_data: any) => {
         /*
         console.group('🔎 GetPosition Smart Contract result')
         console.log(_data)
         console.groupEnd()
         */
      },
      onError: (_error: any) => {
        /*
        console.group('❌ GetPosition Smart Contract error')
        console.log(_error)
        console.groupEnd()
        */
      }
    }),
  ))

  return result
}

const useGetPosition = (): any => {
  const { PlatformState: { contractLists } } = usePlatform()
  const positions = useGetPositions(contractLists)
  
  return {
    getPositionData: positions?.map((position: any) => position?.data?.result ? ({
      hide: !!!numeral(bnToDec(position?.data?.result.size)).value(),
      key: `position-${position?.data?.exchange}`,
      isLoading: position.isLoading,
      exchange: position?.data?.exchange,
      lastUpdatedCumulativeOvernightFeeRate: new BigNumber(position?.data?.result.lastUpdatedCumulativeOvernightFeeRate[0]?._hex).dividedBy(1e18),
      lastUpdatedCumulativePremiumFraction: new BigNumber(position?.data?.result.lastUpdatedCumulativePremiumFraction[0]?._hex).dividedBy(1e18),
      liquidityHistoryIndex: new BigNumber(position?.data?.result.liquidityHistoryIndex?._hex).dividedBy(1e18),
      margin: position?.data?.result.margin.dividedBy(1e18),
      open: position?.data?.result.openNotional.dividedBy(1e18),
      size: position?.data?.result.size.dividedBy(1e18),
      pnl: position?.data?.result.pnl.dividedBy(1e18),
      liquidationPrice: position?.data?.result.liquidationPrice.dividedBy(1e18),
      entryPrice: position?.data?.result.entryPrice,
      leverage: position?.data?.result.leverage,
      marginRatio: position?.data?.result.marginRatio,
      exchangeContract: position?.data?.exchangeContract
    }) : [])
  }
}

export default useGetPosition
