import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { UseMutationResult, useMutation, useQuery, UseQueryResult } from 'react-query'
import { request } from 'graphql-request'
// Contants
import { 
  CURRENT_LPINFOSTATISTICS,
  H24_LPINFOSTATISTICS,
  FIRSTTIME_LPINFOSTATISTICS,
  MY_CURRENT_LPINFOSTATISTICS,
  MY_H24_LPINFOSTATISTICS,
  APY_LP_INFO_STATS,
  APY_LP_INFO_STATS_BY_TIMESTAMP
 } from '../queries'
import { SUBGRAPH_URL_KOVAN, APY_VALUE, TOTAL_LIQUIDITY_VALUE } from '../constants'
// Utils
import BigNumber from 'bignumber.js'
import { usePlatform } from '.'
import { useState } from 'react'
// Hooks
import { useCfd } from '.'
// Utils
import { totalSupply,balanceOf } from '../utils/exchange'
import { getLpTokenPrice } from '../utils/cfd-vault'
import { getMMLiquidity } from '../utils/cfd'
import { useWeb3React } from '@web3-react/core'
import { useCFDVaultContract, useExchangeContract } from './useContract'

// import { big2BigNum, bigNum2Big, decimal2Big } from '../constants/dataType'

export const useTotalSupply = () => {
  // const [val, setVal] = useState(new BigNumber(0))
  const { exchangeContract, account } = useCfd()
  const [active, setActive] = React.useState(false)

  const result: any = useQuery(
    'useTotalSupply',
    async() => {
      const balance = await totalSupply(exchangeContract)
      return new BigNumber(balance).dividedBy(1e18)
    },
    {
      enabled: active,
      initialData: () => new BigNumber(0),
      refetchInterval: 100000,
      cacheTime: 100000,
      onError: (error) => {
        console.group('❌ useTotalSupply error response')
        console.log(error)
        console.groupEnd()
      }
    }  
  )

  React.useEffect(() => {
    if (!account || !exchangeContract) return
    setActive(true)
  }, [account, exchangeContract])

  return result
}

export const useGetLpTokenPrice = () => {
  const [active, setActive] = useState(false)
  const { cfdVaultContract, account } = useCfd()

  const { PlatformState: { activePair } } = usePlatform()

  const result: any = useQuery(
    'useGetLpTokenPrice',
    async() => {
      const liquidityVolumn = await getLpTokenPrice(cfdVaultContract, activePair?.addr)
      /*
      console.log('useCalc.cfdVaultContract', cfdVaultContract)
      console.log('useCalc.activePair?.addr', activePair?.addr)
      console.log('useGetLpTokenPrice.liquidityVolumn[0]', liquidityVolumn[0].toString())
      console.log('useGetLpTokenPrice.liquidityVolumn[1]', liquidityVolumn[1].toString())
      */
      const bigLiquidityVolumn = new BigNumber(liquidityVolumn[1]._hex)
      //console.log('useGetLpTokenPrice.bigLiquidityVolumn', bigLiquidityVolumn.toFixed(8))

      return new BigNumber(bigLiquidityVolumn).dividedBy(1e18)
    },
    {
      enabled: active,
      initialData: () => new BigNumber(0),
      refetchInterval: 100000,
      cacheTime: 100000,
      onError: (error) => {
        console.group('❌ useGetLpTokenPrice error response')
        console.log(error)
        console.groupEnd()
      }
    }  
  )

  React.useEffect(() => {
    if (!account || !cfdVaultContract?.functions) return
    setActive(true)
  }, [account, cfdVaultContract])

  return result
}

export const useCurrentDeposit = (): UseQueryResult<any, any> => {
  const [active, setActive] = useState<boolean>(false)
  const { account } = useWeb3React()
  const { PlatformState: { activePair: { addr } } } = usePlatform()
  const { cfdVaultContract } = useCfd()

  const result: UseQueryResult<any, any> = useQuery(
    'useCurrentDeposit',
    async() => {
      if (!account || !cfdVaultContract || !addr) return
      const liquidityVolumn = await getLpTokenPrice(cfdVaultContract, addr)
      const bigLiquidityVolumn = new BigNumber(liquidityVolumn[0]._hex).dividedBy(1e18)
      return bigLiquidityVolumn
    },
    {
      enabled: active,
      initialData: null,
      retry: 10,
      onError: (error) => {
        console.group('❌ useCurrentDeposit error response')
        console.log(error)
        console.groupEnd()
      }
    }  
  )

  React.useEffect(() => {
    if (!account || !cfdVaultContract || !addr) return
    setActive(true)
  }, [account, addr, cfdVaultContract])

  return result
}

export const useGetMMLiquidity = () => {
    const [active, setActive] = useState(false)
    const { cfdContract, account } = useCfd()
    // const [val, setVal] = React.useState(new BigNumber(0))

    const { PlatformState: { activePair } } = usePlatform()

    const result: any = useQuery(
      'useGetMMLiquidity',
      async() => {
        const liquidityVolume = await getMMLiquidity(cfdContract, activePair?.addr)
        return new BigNumber(liquidityVolume).dividedBy(1e18)
      },
      {
        enabled: active,
        initialData: () => new BigNumber(0),
        refetchInterval: 10000,
        onError: (error) => {
          console.group('❌ useGetMMLiquidity error response')
          console.log(error)
          console.groupEnd()
        }
      }  
    )
  
    React.useEffect(() => {
      if (!account || !cfdContract) return
      setActive(true)
    }, [account, cfdContract])
   
    return result
  }

  // balanceOf
  export const useGetBalanceOf = () => {
    const [active, setActive] = useState(false)
    const { exchangeContract, account } = useCfd()

    const result: any = useQuery(
      'useGetBalanceOf',
      async() => {
        const balance = await balanceOf(exchangeContract, account)
        const result = new BigNumber(balance).dividedBy(1e18)
        return result
      },
      {
        enabled: active,
        initialData: null,
        cacheTime: 0,
        onError: (error) => {
          console.group('❌ useGetBalanceOf error response')
          console.log(error)
          console.groupEnd()
        }
      }  
    )
  
    React.useEffect(() => {
      if (!account || !exchangeContract) return
      setActive(true)
    }, [account, exchangeContract])
  
    return result
  }

  export const useGetBalanceOfByExchange = (addr: string) => {
    const [active, setActive] = useState(false)
    const { account } = useCfd()
    const exchangeContract = useExchangeContract(addr)

    const result: any = useQuery(
      ['useGetBalanceOf', addr],
      async() => {
        const balance = await balanceOf(exchangeContract, account)
        const result = new BigNumber(balance).dividedBy(1e18)
        return result
      },
      {
        enabled: active,
        initialData: null,
        cacheTime: 0,
        onError: (error) => {
          console.group('❌ useGetBalanceOf error response')
          console.log(error)
          console.groupEnd()
        }
      }  
    )
  
    React.useEffect(() => {
      if (!account || !exchangeContract) return
      setActive(true)
    }, [account, exchangeContract])
  
    return result
  }

// quit my current lpprice lpamount:  directly use liquidity current deposit
export const useMyCurrentLpInfoStastics = ():any => {
  const [active, setActive] = React.useState<boolean>(false) 
  const { PlatformState: { activePair, chainEnabled } } = usePlatform()
  const { account } = useWeb3React()

  const myCurrentLpInfoStatistics = useQuery('MY_CURRENT_LPINFOSTATISTICS', async () => {
    if (!activePair?.addr || account) return
    const { liquidityChangedEvents } = await request(SUBGRAPH_URL_KOVAN, MY_CURRENT_LPINFOSTATISTICS,{
      first: 1,
      orderBy: 'timestamp', 
      orderDirection: 'desc',
      exchange: activePair?.addr,
      trader: account
    })

    const result: any = _.first(liquidityChangedEvents)

    const currentLpInfoStastics = new BigNumber(result.lpFundAfter).dividedBy(1e18)
    const currentLpNum = new BigNumber(result.lpTokenAfter).dividedBy(1e18)
    const currentLpValueChange = new BigNumber(currentLpNum.multipliedBy(activePair.lpTokenPrice))    

    return {
      currentLpInfoStastics,
      currentLpValueChange
    }
  }, {
    enabled: active,
    cacheTime: 100000,
    onError: (_error: any) => {
      console.group('❌ useMyCurrentLpInfoStastics_GraphQL_error')
      console.log('error', _error)
      console.groupEnd()
    }
  })
  React.useEffect(() => {
    if (!activePair?.addr) return
    if (!chainEnabled) {
      setActive(false)
      return
    }
    setActive(true)
  }, [activePair, chainEnabled])

  return myCurrentLpInfoStatistics
}

export const useMyBefore24hLpInfoStastics = (): UseQueryResult => {
  const [active, setActive] = React.useState<boolean>(false)
  const { PlatformState: { activePair } } = usePlatform()
  const { account } = useWeb3React()

  const { data: { before24hLpInfoStastics: before24h_lpPrice } }: any = useBefore24hLpInfoStastics()
  const before24hMyLpInfoStastics: UseQueryResult = useQuery('MY_H24_LPINFOSTATISTICS', async () => {
    if (!activePair?.addr || !account) return
    const { liquidityChangedEvents } = await request(SUBGRAPH_URL_KOVAN, MY_H24_LPINFOSTATISTICS,{
      first: 1, 
      orderBy: 'timestamp', 
      orderDirection: 'desc',
      exchange: activePair?.addr,
      timestamp_lt: moment().add(-1, 'day').format('X'),
      trader: account
    })

    // console.log('liquidityChangedEvents', liquidityChangedEvents)

    const result: any = _.first(liquidityChangedEvents)

    let before24hLpInfoStastics = new BigNumber(0)
    before24hLpInfoStastics = new BigNumber(result?.lpFundAfter).dividedBy(1e18)
    const before24hLpNum = new BigNumber(result?.lpTokenAfter).dividedBy(1e18)
    const before24hLpValueChange = new BigNumber(before24hLpNum.multipliedBy(new BigNumber(before24h_lpPrice)))

    return {
      before24hLpInfoStastics: !result?.length ? new BigNumber(0) : before24hLpInfoStastics,
      before24hLpValueChange: !result?.length ? new BigNumber(0) : before24hLpValueChange
    }
  }, {
    enabled: active,
    cacheTime: 100000,
    onError: (_error: any) => {
      console.group('❌ useMyBefore24hLpInfoStastics_GraphQL_error')
      console.log('error', _error)
      console.groupEnd()
    }
  })

  React.useEffect(() => {
    if (!activePair?.addr || !account) return
    setActive(true)
  }, [account, activePair?.addr])

  return before24hMyLpInfoStastics
}

export const useCurrentLpInfoStastics = (): UseQueryResult => {
  const [active, setActive] = React.useState<boolean>(false)
  const { PlatformState: { activePair } } = usePlatform()
  const { data: firstTimestamp }: any = useFirstTimeLpInfoStastics()
  const [fulltime] = React.useState(new BigNumber(365 * 24 * 3600))

  // const ammaddress = addresses.ExchangeProxy
  const currentLpInfoStatistics: UseQueryResult = useQuery('CURRENT_LPINFOSTATISTICS', async () => {
    if (!activePair?.addr || !activePair?.lpTokenPrice) return
    const { lpInfoStatistics } = await request(SUBGRAPH_URL_KOVAN, CURRENT_LPINFOSTATISTICS,{
      first: 1,
      orderBy: 'timestamp', 
      orderDirection: 'desc',
      lpAddr: activePair?.addr
    })

    const result: any = _.first(lpInfoStatistics)

    const currentTime = result?.timestamp
    const currentTimeminusTirsttime = currentTime - firstTimestamp
    const currentLpNum = new BigNumber(result?.lpNum).dividedBy(1e18)
    const currentLpValueChange = activePair?.lpTokenPrice?.multipliedBy(currentLpNum)
    const currentLpInfoStasticsRate = activePair?.lpTokenPrice?.minus(1).multipliedBy(fulltime).dividedBy(currentTimeminusTirsttime)
    return {
      currentLpInfoStastics: activePair?.lpTokenPrice,
      currentLpInfoStasticsRate,
      currentLpValueChange
    }
  }, {
    enabled: active,
    cacheTime: 100000,
    initialData: () => new BigNumber(0),
    onError: (_error: any) => {
      console.group('❌ useCurrentLpInfoStastics_GraphQL_error')
      console.log('error', _error)
      console.groupEnd()
    }
  })

  React.useEffect(() => {
    if (!activePair?.addr || !activePair?.lpTokenPrice) return
    setActive(true)
  }, [activePair])

  return currentLpInfoStatistics
}


export const useApy = (resolution: string): UseQueryResult => {
  const [active, setActive] = useState<boolean>(false)
  const { PlatformState: { activePair: { addr, lpTokenPriceNoFee } } } = usePlatform()
  const result: UseQueryResult = useQuery(
    `APY Calculation - ${resolution}`,
    async (): Promise<BigNumber | null | string> => {
      if (!addr || !lpTokenPriceNoFee) return null
      
      switch (resolution) {
        case APY_VALUE['1DAY']: {
          const { lpInfoStatistics } = await request(SUBGRAPH_URL_KOVAN, APY_LP_INFO_STATS_BY_TIMESTAMP, {
            first: 1,
            orderBy: 'timestamp', 
            orderDirection: 'desc',
            lpAddr: addr,
            timestamp: Number(moment().add(-1, 'days').format('X'))
          })

          if (!lpInfoStatistics.length) return '--'
          const lpInfo: any = _.first(lpInfoStatistics)
          const lpPrice1dayAgo = new BigNumber(Number(lpInfo.lpPrice)).dividedBy(1e18)
          
          return ((lpTokenPriceNoFee.minus(lpPrice1dayAgo)).multipliedBy(new BigNumber(365*24*60*60))).dividedBy(lpPrice1dayAgo.multipliedBy(new BigNumber(24*60*60))).multipliedBy(100)
        }
        case APY_VALUE['1WEEK']: {
          const { lpInfoStatistics } = await request(SUBGRAPH_URL_KOVAN, APY_LP_INFO_STATS_BY_TIMESTAMP, {
            first: 1,
            orderBy: 'timestamp', 
            orderDirection: 'desc',
            lpAddr: addr,
            timestamp: Number(moment().add(-7, 'days').format('X'))
          })

          if (!lpInfoStatistics.length) return '--'
          const lpInfo: any = _.first(lpInfoStatistics)
          const lpPrice7daysAgo = new BigNumber(Number(lpInfo.lpPrice)).dividedBy(1e18)
          
          return ((lpTokenPriceNoFee.minus(lpPrice7daysAgo)).multipliedBy(new BigNumber(365*24*60*60))).dividedBy(lpPrice7daysAgo.multipliedBy(new BigNumber(24*60*60*7))).multipliedBy(100)
        }
        case APY_VALUE['1MONTH']: {
          const { lpInfoStatistics } = await request(SUBGRAPH_URL_KOVAN, APY_LP_INFO_STATS_BY_TIMESTAMP, {
            first: 1,
            orderBy: 'timestamp', 
            orderDirection: 'desc',
            lpAddr: addr,
            timestamp: Number(moment().add(-1, 'month').format('X'))
          })

          if (!lpInfoStatistics.length) return '--'
          const lpInfo: any = _.first(lpInfoStatistics)
          const lpPrice30daysAgo = new BigNumber(Number(lpInfo.lpPrice)).dividedBy(1e18)
          
          return ((lpTokenPriceNoFee.minus(lpPrice30daysAgo)).multipliedBy(new BigNumber(365*24*60*60))).dividedBy(lpPrice30daysAgo.multipliedBy(new BigNumber(24*60*60*30))).multipliedBy(100)
        }
        default:
        case APY_VALUE.ALL_TIME: {
          // All-time:(LP_Price_with_unsettled_fee - 1st_LP_Price_from_subgraph)*365*24*60*60/(currentTimestamp - 1st_LP_price_timestamp_from_subgraph)
          const { lpInfoStatistics } = await request(SUBGRAPH_URL_KOVAN, APY_LP_INFO_STATS, {
            first: 1,
            orderBy: 'timestamp', 
            orderDirection: 'asc',
            lpAddr: addr
          })
  
          const firstLp: any = _.first(lpInfoStatistics)
          const lpPriceFirstLp: BigNumber = new BigNumber(Number(firstLp.lpPrice)).dividedBy(1e18)
          const currentTimestamp = new BigNumber(Number(moment().format('X')))
          const timestampFirstLp = new BigNumber(Number(firstLp.timestamp))
          const oneYearPeriod: BigNumber = new BigNumber(365*24*60*60)
          // console.group('APY calculation - ALL TIME')
          // console.log('Active pair address:', addr)
          // console.log('All-time:(LP_Price_with_unsettled_fee - 1st_LP_Price_from_subgraph)*365*24*60*60/(currentTimestamp - 1st_LP_price_timestamp_from_subgraph)')
          // console.log('activePair.lpTokenPriceNoFee', lpTokenPriceNoFee.toString())
          // console.log('lpPriceFirstLp', lpPriceFirstLp.toString())
          // console.log('currentTimestamp', currentTimestamp.toString())
          // console.log('timestampFirstLp', timestampFirstLp.toString())
          // console.log('oneYearPeriod', oneYearPeriod.toString())
          // console.log('((((lpTokenPriceNoFee.minus(lpPriceFirstLp)).multipliedBy(oneYearPeriod)).dividedBy(currentTimestamp.minus(timestampFirstLp))).dividedBy(lpPriceFirstLp)).multipliedBy(new BigNumber(100)) =', ((((lpTokenPriceNoFee.minus(lpPriceFirstLp)).multipliedBy(oneYearPeriod)).dividedBy(currentTimestamp.minus(timestampFirstLp))).dividedBy(lpPriceFirstLp)).multipliedBy(new BigNumber(100)).toString())
          // console.groupEnd()

          return ((((lpTokenPriceNoFee.minus(lpPriceFirstLp)).multipliedBy(oneYearPeriod)).dividedBy(currentTimestamp.minus(timestampFirstLp))).dividedBy(lpPriceFirstLp)).multipliedBy(new BigNumber(100))
        }
      }
    },
    {
      enabled: active,
      onError: (_error) => {
        console.group('❌ useBefore24hLpInfoStastics_GraphQL_error')
        console.log('error', _error)
        console.groupEnd()
      }
    }
  )

  React.useEffect(() => {
    if (!addr || !lpTokenPriceNoFee) return
    setActive(true)
  }, [addr, lpTokenPriceNoFee])

  return React.useMemo(() => result, [result])
}

export const useLpPriceChange = (): UseQueryResult => {
  const [active, setActive] = useState<boolean>(false)
  const { PlatformState: { activePair: { addr, lpTokenPrice } } } = usePlatform()
  const { data: balanceOf }: UseQueryResult<any, any> = useGetBalanceOfByExchange(addr)
  const { account } = useCfd()

  const result: UseQueryResult<any, any> = useQuery(
    'LP Price Change 24h',
    async (): Promise<{
      change: BigNumber | null | string
      rate: BigNumber | null | string
    } | undefined> => {
      if (!addr || !lpTokenPrice || !account || !balanceOf) return

      const yesterday = moment().add(-1, 'day').format('X')

      const { liquidityChangedEvents } = await request(SUBGRAPH_URL_KOVAN, MY_H24_LPINFOSTATISTICS,{
        first: 1, 
        orderBy: 'timestamp', 
        orderDirection: 'desc',
        exchange: addr,
        timestamp_lt: Number(yesterday),
        trader: account
      })

      if (!liquidityChangedEvents.length) return {
        change: '--',
        rate: '--'
      }

      const liquidityChangedInfo: any = _.first(liquidityChangedEvents)
      const lpNum24hAgo = new BigNumber(liquidityChangedInfo.lpTokenAfter).dividedBy(1e18)
      
      const { lpInfoStatistics } = await request(SUBGRAPH_URL_KOVAN, APY_LP_INFO_STATS_BY_TIMESTAMP, {
        first: 1,
        orderBy: 'timestamp', 
        orderDirection: 'desc',
        lpAddr: addr,
        timestamp: Number(yesterday)
      })
      
      if (!lpInfoStatistics.length) return {
        change: '--',
        rate: '--'
      }

      const lpInfo24hAgo: any = _.first(lpInfoStatistics)
      const lpPrice24hAgo = new BigNumber(lpInfo24hAgo.lpPrice).dividedBy(1e18)

      // console.group('LP Token change')
      // console.log('lpTokenPrice', new BigNumber(lpTokenPrice).toString())
      // console.log('balanceOf', balanceOf.toString())
      // console.log('balanceOf.multipliedBy(lpTokenPrice)', balanceOf.multipliedBy(lpTokenPrice).toString(), 'lpNum24hAgo', lpNum24hAgo.multipliedBy(lpPrice24hAgo).toString())
      // console.log('change', (balanceOf.multipliedBy(lpTokenPrice)).minus(lpNum24hAgo.multipliedBy(lpPrice24hAgo)).toString())
      // console.groupEnd()

      setActive(false)
      
      return {
        change: (balanceOf.multipliedBy(lpTokenPrice)).minus(lpNum24hAgo.multipliedBy(lpPrice24hAgo)),
        rate: (((balanceOf.multipliedBy(lpTokenPrice)).minus(lpNum24hAgo.multipliedBy(lpPrice24hAgo))).multipliedBy(100)).dividedBy(lpNum24hAgo.multipliedBy(lpPrice24hAgo))
      }
    },
    {
      enabled: active,
      initialData: {
        change: null,
        rate: null
      },
      onError: (_error) => {
        console.group('❌ useLpPriceChange error')
        console.log('error', _error)
        console.groupEnd()
      }
    }
  )

  React.useEffect(() => {
    // console.log('addr, lpTokenPrice, account, balanceOf', addr, lpTokenPrice, account, balanceOf)
    if (!addr || !lpTokenPrice || !account || !balanceOf) {
      setActive(false)
      return
    }
    setActive(true)
  }, [account, addr, balanceOf, lpTokenPrice])

  return result
}

export const useBefore24hLpInfoStastics = (): UseQueryResult => {
  const [active, setActive] = React.useState<boolean>(false)
  const { PlatformState: { activePair } } = usePlatform()

  const before24hLpInfoStastics = useQuery('H24_LPINFOSTATISTICS', async () => {
    const { lpInfoStatistics } = await request(SUBGRAPH_URL_KOVAN, H24_LPINFOSTATISTICS,{
      first: 1, 
      orderBy: 'timestamp', 
      orderDirection: 'asc',
      lpAddr: activePair?.addr,
      timestamp_gt: Number(moment().add(-1, 'day').format('X'))
    })

    const result: any = _.first(lpInfoStatistics)

    const before24hLpInfoStasticsNew = new BigNumber(result?.lpPrice).dividedBy(1e18)
    const before24hLpNum = new BigNumber(result?.lpNum).dividedBy(1e18)
    const before24hLpValueChange = before24hLpInfoStasticsNew.multipliedBy(before24hLpNum)

    return {
      before24hLpInfoStastics: before24hLpInfoStasticsNew,
      before24hLpValueChange  
    }
  }, {
    enabled: active,
    initialData: () => new BigNumber(0),
    cacheTime: 100000,
    onError: (_error: any) => {
      console.group('❌ useBefore24hLpInfoStastics_GraphQL_error')
      console.log('error', _error)
      console.groupEnd()
    }
  })

  React.useEffect(() => {
    if (!activePair?.addr) return
    setActive(true)
  }, [activePair])

  return before24hLpInfoStastics
}

export const useFirstTimeLpInfoStastics = (): UseQueryResult => {
  const [active, setActive] = React.useState<boolean>(false)
  const { PlatformState: { activePair } } = usePlatform()

  // const timestamp_lt = moment().unix() - 60*60*24
  const firstTimeLpInfoStastics: UseQueryResult = useQuery('FIRSTTIME_LPINFOSTATISTICS', async () => {
    if (!activePair?.addr) return
    const { lpInfoStatistics } = await request(SUBGRAPH_URL_KOVAN, FIRSTTIME_LPINFOSTATISTICS,{
      first: 1, 
      orderBy: 'timestamp', 
      orderDirection: 'asc',
      lpAddr: activePair?.addr
    })
    const result: any = _.first(lpInfoStatistics)
    return result.timestamp
  }, {
    enabled: active,
    cacheTime: 100000,
    initialData: () => new BigNumber(0),
    onError: (_error: any) => {
      console.group('❌ useBefore24hLpInfoStastics GraphQL error')
      console.log('error', _error)
      console.groupEnd()
    }
  })

  React.useEffect(() => {
    if (!activePair?.addr) return
    setActive(true)
  }, [activePair])

  return firstTimeLpInfoStastics
}