import { ColorProps } from './interfaces'

const lightTheme: ColorProps = {
  backdrop: 'rgba(47, 54, 61, 0.9)',
  lightBackdrop: 'rgba(255, 255, 255, 0.8)',
  white: 'rgba(255,255,255,1)',
  black: 'rgba(0,0,0,1)',
  purple: 'rgba(104,99,255,1)',
  blue: {
    '100': 'rgba(19,166,203,1)',
    '200': 'rgba(24,47,71,1)'
  },
  red: {
    // '100': 'rgba(255,71,140,1)',
    // '200': 'rgba(213,40,76,1)'
    '100': 'rgba(255,56,83,1)',
    '200': 'rgba(255,56,83,1)'
  },
  green: {
    // '100': 'rgba(41,181,0,1)',
    // '200': 'rgba(41,181,0,1)'
    '100': 'rgba(2,193,118,1)',
    '200': 'rgba(2,193,118,1)'
  },
  grey: {
    '100': 'rgba(101,111,122,1)',
    '200': 'rgba(81,90,99,1)',
    '300': 'rgba(224,227,235,1)',
    '400': 'rgba(19,25,30,1)',
    '500': 'rgba(95,104,115,1)',
    '600': 'rgba(174,178,181,1)',
    '700': 'rgba(241,244,251,1)',
    '800': 'rgba(140,151,163,1)',
    '900': 'rgba(59,79,99,1)',
    '1000': 'rgba(185,192,199,1)',
    '1100': 'rgba(47,68,90,1)',
    '1200': 'rgba(47,68,90,1)'
  }
}

export default lightTheme
