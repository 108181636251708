import React from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import { GraphQLClient, gql } from 'graphql-request'
import _ from 'lodash'
import { ColorProps } from '../theme/colors/interfaces'
import { FRONTEND_API_URL } from '../constants'
import { useWeb3React } from '@web3-react/core'
import { LightTheme, DarkTheme } from '../theme/colors'

interface ColorContextProps {
  id: number | string | null
  LightTheme: ColorProps
  DarkTheme: ColorProps
  isLoading?: boolean
  refetch?: any
  jwt?: string
}

const initialState: ColorContextProps = {
  id: null,
  LightTheme,
  DarkTheme
}

export const ColorContext = React.createContext<ColorContextProps>({
  ...initialState
})

const client = new GraphQLClient(FRONTEND_API_URL, { headers: {} })

export default function ColorContextProvider({ children }: { children: React.ReactNode }) {
  const { account } = useWeb3React()

  const query: UseQueryResult<any, any> = useQuery('fetch color pallete', async () => {
    if (!account) return
    const loginMutation: any = await client.request(
      gql`
        mutation Login ($identifier: String!, $password: String!) {
          login(input: { identifier: $identifier, password: $password }) {
            jwt
          }
        }
      `,
      {
        identifier: 'dcfdfrontend',
        password: 'Sma4PR9ZP3dSmQzG'
      }
    )
    const jwt: string = loginMutation.login.jwt

    const { userConfigs }: any = await client.request(
      gql`
        query GetUserConfig ($account: String!) {
          userConfigs (where: { wallet: $account }) {
            id,
            colors_light,
            colors_dark
          }
        }
      `,
      {
        account
      },
      {
        authorization: `Bearer ${jwt}`
      }
    )

    if (!userConfigs?.length) {
      return {
        ...initialState,
        id: null,
        jwt
      }
    }

    const userConfig: any = _.first(userConfigs)
    
    const result: ColorContextProps = {
      id: userConfig.id,
      LightTheme: userConfig.colors_light,
      DarkTheme: userConfig.colors_dark,
      jwt 
    }

    return result
  },
  {
    enabled: !!account,
    initialData: initialState,
    retry: 0,
    onError: () => {
      return initialState
    }
  })

  return (
    <ColorContext.Provider
      value={{
        ...query?.data,
        isLoading: query.isLoading,
        refetch: query.refetch
      }}
    >
      {children}
    </ColorContext.Provider>
  )
}

