import { Contract } from '@ethersproject/contracts'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import BigNumber from 'bignumber.js'
import { ChainId } from '../sdk'

const ETHERSCAN_PREFIXES: { [chainId in ChainId]: string } = {
  1: '',
  3: 'ropsten.',
  4: 'rinkeby.',
  5: 'goerli.',
  42: 'kovan.',
  56: 'bscmainnet',
  97: 'bsctestnet',
}
const BSC_PREFIXES: {
  [key: number]: string
} = {
  56: '',
  97: 'testnet.',
}
//https://testnet.bscscan.com
//https://bscscan.com
export function getEtherscanLink(
  chainId: ChainId,
  data: string,
  type: 'transaction' | 'token' | 'address',
): string {
  const chainIdInt = parseInt(chainId.toString())
  let prefix = ''
//console.log(chainId, chainIdInt)
  if (chainIdInt === 56 || chainIdInt === 97) {
    prefix = `https://${
      BSC_PREFIXES[chainIdInt] || BSC_PREFIXES[56]
    }bscscan.com`
  } else {
    prefix = `https://${
      ETHERSCAN_PREFIXES[chainId] || ETHERSCAN_PREFIXES[1]
    }etherscan.io`
  }

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}

/**
 * Returns true if the string value is zero in hex
 * @param hexNumberString
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isZero(hexNumberString: string) {
  return /^0x0*$/.test(hexNumberString)
}

// returns the checksummed address if the address is valid, otherwise returns false
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

// account is not optional
export function getSigner(
  library: Web3Provider,
  account: string,
): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(
  library: Web3Provider,
  account?: string,
): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(
  address: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  ABI: any,
  library: Web3Provider,
  account?: string,
): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(
    address,
    ABI,
    getProviderOrSigner(library, account) as any,
  )
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

export const bnToDec = (bn: BigNumber, decimals = 18): BigNumber => {
  return new BigNumber(bn).dividedBy(new BigNumber(10).pow(decimals))
}

export const decToBn = (dec: number, decimals = 18): BigNumber => {
  return new BigNumber(dec).multipliedBy(new BigNumber(10).pow(decimals))
}