// Libs
import React from 'react'
import BigNumber from 'bignumber.js'
import { useQuery } from 'react-query'
// Hooks
import { useTokenContract } from './useContract'
import { useWeb3React } from '@web3-react/core'
// Utils
import { allowance } from '../utils/erc20'
import { usePlatform } from '.'

const useAllowance = (tokenAddress: string, contractAddress: string) => {
  const { account } = useWeb3React()
  const tokenContract = useTokenContract(tokenAddress)
  const [active, setActive] = React.useState(false)
  const { PlatformState: { chainEnabled } } = usePlatform()

  const allowanceResp: any = useQuery(
    'allowance',
    async() => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const result = await allowance(tokenContract!, account!, contractAddress!)
      return new BigNumber(result)
    },
    {
      enabled: active,
      initialData: () => new BigNumber(0),
      refetchInterval: 10000,
      onError: (error) => {
        console.group('❌ Allowance error response')
        console.log( error)
        console.groupEnd()
      }
    }  
  )

  React.useEffect(() => {
    if (!tokenContract || !account || !contractAddress) return
    if (!chainEnabled) {
      setActive(false)
      return
    }
    setActive(true)
  }, [account, contractAddress, tokenContract, chainEnabled])

  return allowanceResp.data
}

export default useAllowance
