import { ApolloQueryResult, useSubscription, useQuery, ApolloClient, NormalizedCacheObject, InMemoryCache } from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { gql } from 'graphql-request'
import { SUBGRAPH_URL_WS } from '../constants'
import { SUBSCRIBE_TEST, Q_TEST_CANDLES, SUBSCRIBE_CANDLES_TEST } from '../hooks/dataFeed2'

export const GQL_REQUEST_AMOUNT = 9999

export enum QueryTraderPositionHistoryEvent {
  PositionChanged = "PositionChanged",
  PositionLiquidated = "PositionLiquidated",
  FundingRateUpdated = "FundingRateUpdated",
}

export type CandleStick = {
  market: string
  resolution: string
  startTime: number
  open: string
  high: string
  low: string
  close: string
  volume: string
  quoteAssetVol: string
  txCount: number
}

interface SubscribeCandleStickResponse {
  data: {
    onUpsertCandleStick: CandleStick
  }
}

export function getTranslatedResolution(origin: string): string {
  const resolutionRegex = /(\d*)([hHdD]?)/
  const defaultAmount = "1"
  const defaultUnit = "m"
  let ret = `${defaultAmount}${defaultUnit}`

  const matched = origin.match(resolutionRegex)
  if (matched) {
    const amount = matched[1] || defaultAmount
    const unit = matched[2] || defaultUnit

    if (amount === "60" && unit === "m") {
      ret = "1h"
    } else {
      ret = `${amount}${unit}`
    }
  }
  return ret.toLowerCase()
}

interface GetCandleStickResponse {
  listCandleSticks: { items: CandleStick[] }
}

interface SubscribeCandleStickResponse {
  data: {
      onUpsertCandleStick: CandleStick
  }
}

export const CONTRACT_LISTS = gql`
  query ContractLists ($type: Int!) {
    contractLists(where: { type: $type }, orderBy: name, orderDirection: asc) {
      name
      addr
      quoteAssetName
      quoteAssetAddr
    }
  }
`

export const POSITION_CHANGED_EVENTS_ALL_TRADES = gql`
  query PositionChangedEvents ($skip: Int, $first: Int, $orderBy: String, $orderDirection: String, $amm: String) {
    positionChangedEvents(
      skip: $skip, 
      first: $first, 
      orderBy: $orderBy, 
      orderDirection: $orderDirection,
      where: {
        amm: $amm,
        exchangedPositionSize_not: 0
      }
    ) {
      id,
      exchangedPositionSize,
      positionNotional
      timestamp
    }
  }
`

export const QUERY_AMM_STATISTICS_LTE = gql`
  query PositionChangedEvents ($amm: Bytes,$timestamp_lte: BigInt,$orderBy: PositionChangedEvent_orderBy, $orderDirection: OrderDirection, $first: Int) {
    positionChangedEvents(
      first: $first, 
      orderBy: $orderBy, 
      orderDirection: $orderDirection,
      where: {
        amm: $amm,
        timestamp_lte: $timestamp_lte
      }
    ) {
      positionNotional
      exchangedPositionSize
      margin
      spotPrice
      timestamp
    }
  }
`

export const QUERY_AMM_STATISTICS = gql`
  query PositionChangedEvents ($amm: Bytes,$timestamp_gt: BigInt,$orderBy: PositionChangedEvent_orderBy, $orderDirection: OrderDirection, $first: Int) {
    positionChangedEvents(
      first: $first, 
      orderBy: $orderBy, 
      orderDirection: $orderDirection,
      where: {
        amm: $amm,
        timestamp_gt: $timestamp_gt
      }
    ) {
      positionNotional
      exchangedPositionSize
      margin
      spotPrice
      timestamp
    }
  }
`

export const STATISTICS_QUERY = gql`
  query StatisticalQuery ($amm: String!,$time: Int!,$eid: Int!) {
    statisticalQuery(
        amm: $amm,
        eid: $eid,
        time: $time,
    ) {
      eid
      result
      timeStamp
      count
      amm
    }
  }
`

export const STATISTICS_QUERY_V2 = gql`
  query StatisticalQuery ($amm: String!,$time: Int!) {
    statisticalQuery(
        amm: $amm,
        time: $time,
    ) {
      eid
      result
      timeStamp
      count
      amm
    }
  }
`

export const QUERY_AMM_POSITIONS = gql`
  query AmmPositions ($amm: String)  {
    ammPositions(
      where: {
        amm: $amm
      }
    ) {
      tradingVolume,
      positionSize,
      margin
    }
  }
`

export const TOTAL_POSITION_SIZE = gql`
  query PositionChangedEvents {
    positionChangedEvents {
      positionNotional,
      margin,
      exchangedPositionSize
    }
  }
`

export const QUERY_LIQUIDITY_HISTORY = gql`
  query liquidityChangedEvents($orderBy: String, $exchange: String, $orderDirection: String, $first: Int){
    liquidityChangedEvents(
      first: $first, 
      orderBy: $orderBy, 
      orderDirection: $orderDirection, 
      where: {
        exchange: $exchange
      }
    ){
      id
      lpToken
      exchangeHex
      traderHex
      lpFund
      eventType
      timestamp
    }
  }
`

export const QUERY_MY_LIQUIDITY_HISTORY = gql`
  query liquidityChangedEvents($orderBy: String, $exchange: String, $trader: String, $orderDirection: String, $first: Int){
    liquidityChangedEvents(
      first: $first, 
      orderBy: $orderBy, 
      orderDirection: $orderDirection, 
      where: {
        exchange: $exchange,
        trader: $trader
      }
    ){
      id
      lpToken
      exchangeHex
      traderHex
      lpFund
      eventType
      timestamp
    }
  }
`

// current lpInfoStatistics price
export const CURRENT_LPINFOSTATISTICS = gql`
  query lpInfoStatistics($lpAddr: String, $orderBy: String, $orderDirection: String, $first: Int){
    lpInfoStatistics(
      first: $first, 
      orderBy: $orderBy, 
      orderDirection: $orderDirection, 
      where: {
        lpAddr: $lpAddr
      }
    ){
      
      lpAddr
      lpPrice
      lpNum
      timestamp
    }
  }
`

export const APY_LP_INFO_STATS = gql`
  query APY_LP_INFO_STATS($lpAddr: String, $orderBy: String, $orderDirection: String, $first: Int){
    lpInfoStatistics(
      first: $first, 
      orderBy: $orderBy, 
      orderDirection: $orderDirection, 
      where: {
        lpAddr: $lpAddr
      }
    ){
      lpPrice
      timestamp
    }
  }
`

export const APY_LP_INFO_STATS_BY_TIMESTAMP = gql`
  query APY_LP_INFO_STATS_BY_TIMESTAMP($lpAddr: String, $timestamp: BigInt, $orderBy: LpInfoStatistic_orderBy, $orderDirection: OrderDirection, $first: Int){
    lpInfoStatistics(
      first: $first, 
      orderBy: $orderBy, 
      orderDirection: $orderDirection, 
      where: {
        lpAddr: $lpAddr,
        timestamp_lte: $timestamp
      }
    ){
      lpAddr
      lpPrice
      lpNum
      timestamp
    }
  }
`

// 24h before lpInfoStatistics price
export const H24_LPINFOSTATISTICS = gql`
  query lpInfoStatistics($timestamp_gt: BigInt, $lpAddr: String, $orderBy: String, $orderDirection: String, $first: Int){
    lpInfoStatistics(
      first: $first, 
      orderBy: $orderBy, 
      orderDirection: $orderDirection, 
      where: {
        lpAddr: $lpAddr,
        timestamp_gt: $timestamp_gt
      }
    ){
      
      lpAddr
      lpPrice
      lpNum
      timestamp
    }
    }
`

// 24h before lpInfoStatistics price
export const FIRSTTIME_LPINFOSTATISTICS = gql`
  query lpInfoStatistics($lpAddr: String, $orderBy: String, $orderDirection: String, $first: Int){
    lpInfoStatistics(
      first: $first, 
      orderBy: $orderBy, 
      orderDirection: $orderDirection, 
      where: {
        lpAddr: $lpAddr
      }
    ){
      
      lpAddr
      lpPrice
      timestamp
    }
  }
`

// my current lpprice lpamount
export const MY_CURRENT_LPINFOSTATISTICS = gql`
  query liquidityChangedEvents($orderBy: String, $exchange: String, $trader: String, $orderDirection: String, $first: Int){
    liquidityChangedEvents(
      first: $first, 
      orderBy: $orderBy, 
      orderDirection: $orderDirection, 
      where: {
        exchange: $exchange,
        trader: $trader
      }
    ){
      traderHex
      eventType
      exchange
      timestamp
      lpToken
      lpFund
      lpFundAfter
      lpTokenAfter
    
    }
  }
`

// my h24 lpprice lpamount
export const MY_H24_LPINFOSTATISTICS = gql`
  query MY_H24_LPINFOSTATISTICS($timestamp_lt: BigInt, $orderBy: String, $exchange: String, $trader: String, $orderDirection: String, $first: Int){
    liquidityChangedEvents(
      first: $first, 
      orderBy: $orderBy, 
      orderDirection: $orderDirection, 
      where: {
        exchange: $exchange,
        trader: $trader,
        timestamp_lt: $timestamp_lt
      }
    ){
      traderHex
      eventType
      exchange
      timestamp
      lpToken
      lpFund
      lpFundAfter
      lpTokenAfter
    
    }
  }
`

// CURRENT_DEPOSIT
export const CURRENT_DEPOSIT = gql`
  query mmLiquidities($trader: String, $exchange: String){
    mmLiquidities(
      where: {
        trader: $trader,
        exchange: $exchange
      }
    ){
      exchange
      trader
      lpFund
    }
  } 
`

export const QUERY_CLOSED_POSITIONS = gql`
  query PositionChangedEvents ($first: Int, $orderBy: String, $orderDirection: String, $trader: String) {
    positionChangedEvents(
      first: $first, 
      orderBy: $orderBy, 
      orderDirection: $orderDirection,
      where: {
        positionSizeAfter: 0,
        trader: $trader
      }
    ) {
      id,
      exchangedPositionSize,
      spotPrice,
      timestamp,
      margin,
      positionNotional,
      fee,
      realizedPnl,
      unrealizedPnlAfter,
      positionSizeAfter,
      badDebt,
      liquidationPenalty,
      fundingPayment,
      amm
    	fee
    	overnightFee
    	fundingPayment
    }
  }
`

export const QUERY_USER_HISTORY_POSITIONS = gql`
  query PositionChangedEvents ($first: Int, $orderBy: String, $orderDirection: String, $trader: String) {
    positionChangedEvents(
      first: $first, 
      orderBy: $orderBy, 
      orderDirection: $orderDirection,
      where: {
        trader: $trader
      }
    ) {
      id,
      exchangedPositionSize,
      spotPrice,
      timestamp,
      margin,
      positionNotional,
      fee,
      realizedPnl,
      unrealizedPnlAfter,
      positionSizeAfter,
      badDebt,
      liquidationPenalty,
      fundingPayment,
      amm
    	fee
    	overnightFee
    	fundingPayment
    }
  }
`

export const QUERY_CANDLES = gql`
  query SpotPriceStatistics ($type: Int, $pairName: String) {
    spotPriceStatistics(
      first: 999,
      orderBy: timestamp,
      orderDirection: desc,
      where: {
        type: $type,
        pairName: $pairName
      }
    ) {
      type
      version
      seq
      startTime
      open
      high
      low
      close
      volume
      quoteAssetVol
      txCount
      timestamp
    }
  }
`

export const QUERY_CANDLES_HOMEPAGE = gql`
  query SpotPriceStatistics ($type: Int, $pairName: String) {
    spotPriceStatistics(
      first: 24,
      orderBy: timestamp,
      orderDirection: desc,
      where: {
        type: $type,
        pairName: $pairName
        volume_not: null
      }
    ) {
      close
      timestamp
    }
  }
`

export const SUBSCRIBE_CANDLES = gql`
  subscription SpotPriceStatistics ($type: Int, $pairName: String) {
    spotPriceStatistics(
      first: 999,
      orderBy: timestamp,
      orderDirection: desc,
      where: {
        type: $type,
        pairName: $pairName
      }
    ) {
      type
      version
      seq
      startTime
      open
      high
      low
      close
      volume
      quoteAssetVol
      txCount
      timestamp
    }
  }
`
/*
statType: BigInt!
Stat Type:1:Liquidity,2:TraderVolume,3:Fee,4:LPPrice

statRange: BigInt!
Stat Range type: 1:hours,2:days
*/
export const QUERY_SUMMARY_STAT_INFOS = gql`
  query SummaryStatinfos ($statType: Int, $statRange: Int, $lpAddr: String) {
    summaryStatInfos(
      first: 720, 
      where: 
        {
          statType: $statType, 
          statRange: $statRange, 
          lpAddr: $lpAddr
        }, 
        orderBy: statTime, 
        orderDirection: desc
      ) {
      id
      result
      statType
      lpAddr
      lpName
      statNum
      statTime
      statRange
    }
  }
`

export const CONTRACT_DATA = gql`
  query ContractDatas {
    contractDatas {
      address
      ammPrice
      priceHigh
      priceLow
      oraclePrice
      oracleTimestamp
      liquidityVolume
      tradingVolumeLong
      tradingVolumeShort
      tradingVolumeTotal
      volumeBefore
      volumeCurrent
      volumeChange
      tradingVolume24hQuoteAsset
      tradingVolume24h
      mmLiquidityVolume
      totalLpLiquidity
      totalLpUnrealizedPnl
      totalLpFeesAsync
      exchangeTotalSupply
      lpTokenPrice
      lpTokenPriceWithFee
    }
  }
`

export const CONTRACT_LIQUIDITY_DATA = gql`
  query ContractLiquidityDatas {
    contractLiquidityDatas {
      liquidityVolume
      tradingVolumeLong
      tradingVolumeShort
    }
  }
`

export const COMPETITION_RANKING = gql`
  query GetCompetitionRanking {
    getCompetitionRanking{
      id
      address
      pnl
      roi
      faucetAmount
    }
  }
`

export const QUERY_POSITION_CHANGED_EVENTS = gql`
  query PositionChangedEvents ($ammHex: String) {
    positionChangedEvents(
      first: 1,
      orderBy: timestamp,
      orderDirection: desc,
      where : {
        ammHex: $ammHex
      }
    ) {
      positionNotional,
      exchangedPositionSize
    }
  }
`


export async function queryCandles(
  client: any,
  ammAddress: string,
  resolution: string,
  limit: number = GQL_REQUEST_AMOUNT,
): Promise<CandleStick[]> {
  const updatedRes = getTranslatedResolution(resolution)
  const result: ApolloQueryResult<GetCandleStickResponse> = await client.query({
      query: QUERY_CANDLES,
      variables: {
          query: {
              marketResolution: { eq: `${ammAddress}#${updatedRes}` },
          },
          limit,
      },
  })
  return result.data.listCandleSticks.items
}
/*
export function subscribeCandles(
  client: any,
  ammAddress: string,
  resolution: string,
  callback: (data: SubscribeCandleStickResponse) => void,
): ZenObservable.Subscription {
  const updatedRes = getTranslatedResolution(resolution)
  const { data: { commentAdded }, loading } = useSubscription(
    SUBSCRIBE_CANDLES,
    { 
      variables: { 
        type: updatedRes, 
        pairName: 'ETH/USDC' 
      } 
    }
  );
  return commentAdded
}
*/
//ws://95.217.9.45:8001/subgraphs/name/cfdswap/cfdswap-subgraph
/*
export function subscribeCandles(
  //ammAddress: string,
  //resolution: string,
  callback: (data: any) => void,
): ZenObservable.Subscription {

  const wsLink = new WebSocketLink({
    uri: 'ws://95.217.9.45:8001/subgraphs/name/cfdswap/cfdswap-subgraph',
    options: {
      reconnect: true
    }
  });
  console.log('wsLink', wsLink)
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: wsLink,
  });
  const observable = client.subscribe<any>({
    query: SUBSCRIBE_CANDLES_TEST
  })
  //const test = client.query({ query: Q_TEST_CANDLES })
  console.log('test query on ws', test)
  return observable.subscribe(callback)
}
*/

export function subscribeCandles(
  //exchange: string,
  //resolution: string,
  callback: (data: any) => void,
): ZenObservable.Subscription {

  const wsLink = new WebSocketLink({
    uri: SUBGRAPH_URL_WS,
    options: {
      reconnect: true
    }
  });
  //console.log('wsLink', wsLink)
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: wsLink,
  });
  const observable = client.subscribe<any>({
    query: SUBSCRIBE_TEST
  })
  /*
  const test = client.query({ query: Q_TEST_CANDLES })
  console.log('test query on ws', test)
  */
  return observable.subscribe(callback)
}
