import { useCallback, useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
// Hooks
import { useCfd } from '.'
import { useWeb3React } from '@web3-react/core'
// Utils
import { getUnderLyingTwapPrice,getTwapPrice,getUnderLyingPrice,fundingPeriod,spreadRatio,getPositionSize } from '../utils/exchange'
import { overnightFeeRatio } from '../utils/system-settings'
import { usePlatform } from '.'
import { useExchangeContract } from './useContract'
import { useQuery, UseQueryResult } from 'react-query'
import moment from 'moment'
import { getLiquidityVolume } from '../utils/cfd'
// import { big2BigNum, bigNum2Big, decimal2Big } from '../constants/dataType'

export const useIndexPrice = (): any => {
  const [val, setVal] = useState(new BigNumber(0))
  const { exchangeContract } = useCfd()
  const { PlatformState: { chainEnabled } } = usePlatform()

  const { account } = useWeb3React()

  const fetchVal = useCallback(async () => {
    try {
      const balance = await getUnderLyingTwapPrice(exchangeContract)
      const newBalance = new BigNumber(balance).dividedBy(1e18)
      setVal(newBalance)
    } catch (error) {
      console.log('❌ useIndexPrice error', error)
    }
  }, [exchangeContract, setVal])

  useEffect(() => {
    if (account && exchangeContract && chainEnabled) {
      fetchVal()
      const refreshInterval = setInterval(fetchVal, 10000)
      return () => clearInterval(refreshInterval)
    }
  }, [account, exchangeContract, fetchVal, setVal, chainEnabled])

  return val
}

export const useOraclePrice = (): UseQueryResult => {
  const [active, setActive] = useState<boolean>(false)
  const { account } = useCfd()
  const { PlatformState: { activePair, chainEnabled } } = usePlatform()
  const exchangeContract = useExchangeContract(activePair?.addr)
  const [initialPrice, setInitialPrice] = useState<BigNumber | undefined>(undefined)

  const oraclePrice: UseQueryResult = useQuery(`useOraclePrice-${activePair?.addr}}`, async () => {
    const balance = await getUnderLyingPrice(exchangeContract)
    const result = new BigNumber(balance).dividedBy(1e18)

    return result
  }, {
    enabled: active,
    refetchInterval: 10000,
    cacheTime: 10000,
    initialData: initialPrice,
    onSuccess: (_data: any) => {
      setInitialPrice(_data)
    },
    onError: (_error: any) => {
      console.group('❌ useOraclePrice error')
      console.log('error', _error)
      console.groupEnd()
    }
  })

  useEffect(() => {
    if (!account || !exchangeContract || !activePair) return
    if (!chainEnabled) {
      setActive(false)
      return
    }
    setActive(true)
  }, [account, exchangeContract, activePair, chainEnabled])

  return oraclePrice
}

export const useGetPositionSize = (): any => {
  const [val, setVal] = useState(new BigNumber(0))
  const { exchangeContract } = useCfd()

  const { account } = useWeb3React()
  const { PlatformState: { chainEnabled } } = usePlatform()

  const fetchVal = useCallback(async () => {
    try {
      const positionSize = await getPositionSize(exchangeContract)
      const bigPositionSize = new BigNumber(positionSize).dividedBy(1e18)
      setVal(bigPositionSize)
    } catch (error) {
      console.log('❌ useGetPositionSize error', error)
    }
  }, [exchangeContract, setVal, chainEnabled])

  useEffect(() => {
    
    if (account && exchangeContract && chainEnabled) {
      fetchVal()
      const refreshInterval = setInterval(fetchVal, 10000)
      return () => clearInterval(refreshInterval)
    }
  }, [account, exchangeContract, fetchVal, setVal, chainEnabled])

  return val
}

export const useTransactionFee = (): any => {
  const { account } = useCfd()

  const [active, setActive] = useState<boolean>(false)
  const { PlatformState: { activePair, chainEnabled } } = usePlatform()
  const [initialFee, setInitialFee] = useState<BigNumber | undefined>(undefined)
  const exchangeContract = useExchangeContract(activePair?.addr)

  const transactionFee: UseQueryResult = useQuery(`useTransactionFee-${activePair?.addr}`, async () => {
    if (!exchangeContract || !activePair?.addr) return
    const transactionFee = await spreadRatio(exchangeContract)
    const result = new BigNumber(transactionFee).dividedBy(1e18)

    return result
  }, {
    enabled: active,
    refetchInterval: 10000,
    initialData: initialFee,
    onSuccess: (_data: BigNumber) => {
      setInitialFee(_data)
    },
    onError: (_error: any) => {
      console.group('❌ useTransactionFee error')
      console.log('error', _error)
      console.groupEnd()
    }
  })

  useEffect(() => {
    if (!account || !activePair?.addr || !exchangeContract) return
    if (!chainEnabled) {
      setActive(false)
      return
    }
    setActive(true)
  }, [account, activePair, exchangeContract, chainEnabled])
  
  return transactionFee
}

export const useLiquidityVolumn = (): UseQueryResult => {
  const { cfdContract, account } = useCfd()

  const [active, setActive] = useState<boolean>(false)
  const { PlatformState: { activePair, chainEnabled } } = usePlatform()

  const volume: UseQueryResult = useQuery(`useLiquidityVolumn-${activePair?.addr}`, async () => {
    const liquidityVolumn = await getLiquidityVolume(cfdContract,activePair?.addr)
    const result = new BigNumber(liquidityVolumn).dividedBy(1e18)

    return result
  }, {
    enabled: active,
    refetchInterval: 10000,
    onError: (_error: any) => {
      console.group('❌ useLiquidityVolumn error')
      console.log('error', _error)
      console.groupEnd()
    }
  })

  useEffect(() => {
    if (!account || !activePair || !cfdContract) return
    if (!chainEnabled) {
      setActive(false)
      return
    }
    setActive(true)
  }, [account, activePair, cfdContract, chainEnabled])
  
  return volume
}

export const useEstimatedFundingRate = (): UseQueryResult => {
  const { account } = useCfd()

  const [active, setActive] = useState<boolean>(false)
  const { PlatformState: { activePair, chainEnabled } } = usePlatform()
  const [initialFundingRate, setInitialFundingRate] = useState<BigNumber | undefined>(undefined)
  const exchangeContract = useExchangeContract(activePair?.addr)

  const fundingRate: UseQueryResult = useQuery(`useEstimatedFundingRate-${activePair?.addr}}`, async () => {
    if (!exchangeContract?.address) return
    const indexPrice = new BigNumber(await getUnderLyingTwapPrice(exchangeContract)).dividedBy(1e18)
  
    const oneDayInSec = 60 * 60 * 24
    const marketPrice = new BigNumber(await getTwapPrice(exchangeContract)).dividedBy(1e18)
    const premium = marketPrice.minus(indexPrice)
    const getFundingPeriod = await fundingPeriod(exchangeContract)
    const premiumFraction = premium.multipliedBy(getFundingPeriod).dividedBy(oneDayInSec)
    const predicted = premiumFraction.dividedBy(indexPrice)

    return predicted
  }, {
    enabled: active,
    refetchInterval: 10000,
    initialData: initialFundingRate,
    onSuccess: (_data: BigNumber) => {
      setInitialFundingRate(_data)
    },
    onError: (_error: any) => {
      console.group('❌ useEstimatedFundingRate error')
      console.log('error', _error)
      console.groupEnd()
    }
  })

  useEffect(() => {
    if (!account || !activePair || !exchangeContract) return
    if (!chainEnabled) {
      setActive(false)
      return
    }
    setActive(true)
  }, [account, activePair, exchangeContract, chainEnabled])
  
  return fundingRate


  // const [val, setVal] = useState(new BigNumber(0))
  // const { exchangeContract } = useCfd()

  // const { account } = useWeb3React()

  // const fetchVal = useCallback(async () => {
  //   try {
  //     const indexPrice = new BigNumber(await getUnderLyingTwapPrice(exchangeContract)).dividedBy(1e18)
  
  //     const oneDayInSec = 60 * 60 * 24
  //     const marketPrice = new BigNumber(await getTwapPrice(exchangeContract)).dividedBy(1e18)
  //     const premium = marketPrice.minus(indexPrice)
  //     const getFundingPeriod = await fundingPeriod(exchangeContract)
  //     const premiumFraction = premium.multipliedBy(getFundingPeriod).dividedBy(oneDayInSec)
  //     const predicted = premiumFraction.dividedBy(indexPrice)
  
  //     setVal(predicted)
  //   } catch (error) {
  //     console.log('❌ useEstimatedFundingRate error', error)
  //   }
  // }, [exchangeContract, setVal])

  // useEffect(() => {
    
  //   if (account && exchangeContract) {
  //     fetchVal()
  //     const refreshInterval = setInterval(fetchVal, 10000)
  //     return () => clearInterval(refreshInterval)
  //   }
  // }, [account, exchangeContract, fetchVal, setVal])
  // return val
}

export const useInterestRate = (): UseQueryResult => {
  const { account } = useCfd()
  const [active, setActive] = useState<boolean>(false)
  const [initialFee, setInitialFee] = useState<BigNumber | undefined>(undefined)
  const { systemSettingsContract } = useCfd()
  const { PlatformState: { chainEnabled } } = usePlatform()

  const interestRate: UseQueryResult = useQuery(`useInterestRate`, async () => {
    const interestRate = await overnightFeeRatio(systemSettingsContract)
    const result = new BigNumber(interestRate).dividedBy(1e18)

    return result
  }, {
    enabled: active,
    refetchInterval: 10000,
    initialData: initialFee,
    onSuccess: (_data: BigNumber) => {
      setInitialFee(_data)
    },
    onError: (_error: any) => {
      console.group('❌ useInterestRate error')
      console.log('error', _error)
      console.groupEnd()
    }
  })

  useEffect(() => {
    if (!account || !systemSettingsContract) return
    if (!chainEnabled) {
      setActive(false)
      return
    }
    setActive(true)
  }, [account, systemSettingsContract, chainEnabled])
  
  return interestRate


  // const [val, setVal] = useState(new BigNumber(0))
  // const { systemSettingsContract } = useCfd()

  // const { account } = useWeb3React()

  // const fetchVal = useCallback(async () => {
  //   try {
  //     const interestRate = await overnightFeeRatio(systemSettingsContract)
  //     const bitInterestRate = new BigNumber(interestRate).dividedBy(1e18)
  //     setVal(bitInterestRate)
  //   } catch (error) {
  //     console.log('❌ useEstimatedFundingRate error', error)
  //   }
  // }, [systemSettingsContract, setVal])

  // useEffect(() => {
    
  //   if (account && systemSettingsContract) {
  //     fetchVal()
  //     const refreshInterval = setInterval(fetchVal, 10000)
  //     return () => clearInterval(refreshInterval)
  //   }
  // }, [account, systemSettingsContract, fetchVal, setVal])

  // return val
}
