import React, { useState, useEffect, useCallback, createContext } from 'react'
import { Contract } from '@ethersproject/contracts'

import {
  initMarginRatio,
  maintenanceMarginRatio,
} from '../../utils/system-settings'

import {
  getMarginRatio,
  getPosition,
  getPositionNotionalAndUnrealizedPnl,
} from '../../utils/cfd'

import {
  useCFDContract,
  useCFDVaultContract,
  useCFDViewerContract,
  useSystemSettingsContract,
  useExchangeContract,
  useExchangeReaderContract,
  useCFDStateContract,
} from '../../hooks/useContract'

import { addresses } from '../../constants/addresses'
import { useWeb3React } from '@web3-react/core'

import { usePlatform } from '../../hooks'

interface CfdContext {
  startBlock: number
  bonusBlockNum: number
  cfdContract: Contract | null
  cfdVaultContract: Contract | null
  cfdViewerContract: Contract | null
  cfdStateContract: Contract | null 
  exchangeContract: Contract | null
  exchangeReaderContract: Contract | null
  systemSettingsContract: Contract | null
  account: string | null | undefined
  exchange: string | null | undefined
}

export const Context = createContext<CfdContext>({
  startBlock: 0,
  bonusBlockNum: 0,
  cfdContract: null,
  cfdVaultContract: null,
  cfdViewerContract: null,
  cfdStateContract: null,
  exchangeContract: null,
  exchangeReaderContract: null,
  systemSettingsContract: null,
  account: null,
  exchange: null,
})

interface CfdProps {
  children: React.ReactNode
}

const Cfd: React.FC<CfdProps> = ({ children }) => {
  const { PlatformState: { activePair, platformContractLists, contractLists } } = usePlatform()
  const cfdContract = useCFDContract(platformContractLists?.data?.CFD)
  const cfdVaultContract = useCFDVaultContract(platformContractLists?.data?.CFDVault)
  const systemSettingsContract = useSystemSettingsContract(
    platformContractLists?.data?.systemSettings,
  )
  //console.log('activePair context cfd', activePair)
  const exchangeContract = useExchangeContract(
    activePair?.addr,
  )
  const exchangeReaderContract = useExchangeReaderContract(
    platformContractLists?.data?.exchangeReader,
  )
  const cfdViewerContract = useCFDViewerContract(
    platformContractLists?.data?.CFDViewer,
  )
  const cfdStateContract = useCFDStateContract(
    platformContractLists?.data?.CFDState,
  )
  //console.log('exchangeContract context cfd', exchangeContract)
  const { account } = useWeb3React()
  const exchange = activePair?.addr

  const [startBlock, setStartBlock] = useState(0)
  const [bonusBlockNum, setBonusBlockNum] = useState(0)
  
 /*
  const platform = usePlatform()
  console.log('platform', platform)
 
  const { allTradesIsLoading, allTrades } = useGetAllTrades()
  console.log('allTradesIsLoading', allTradesIsLoading)
  console.log('allTrades', allTrades)
*/
/*
  const fetchData = useCallback(async () => {
    
    console.log('cfdContract', cfdContract)
    //const pool: Contract = ((await getSakeMasterPool(cfdContract)) as { [key: string]: Contract}).sake_master;
    //const startBlockResponse = await getStartBlock(pool);
    setStartBlock(1)
    //const bonusBlockNumResponse = await getBonusBlockNum(pool);
    setBonusBlockNum(1)
   
  }, [cfdContract, account])

  useEffect(() => {
    if (cfdContract) {
      fetchData()
    }
  }, [fetchData, cfdContract])
 */
  return (
    <Context.Provider
      value={{
        startBlock,
        bonusBlockNum,
        cfdContract,
        cfdVaultContract,
        cfdViewerContract,
        cfdStateContract,
        exchangeContract,
        exchangeReaderContract,
        systemSettingsContract,
        account,
        exchange,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Cfd
