import { useMutation, UseMutationResult } from 'react-query'
import { request, gql } from 'graphql-request'
import { BACKEND_API_URL } from '../constants'
import { useCfd, usePlatform, useToastify } from '.'
import { useTranslation } from 'react-i18next'
import { registerToken } from '../utils/wallet'

const prepareTimeString = (timeLeft: any): string | null => {
  if (!timeLeft) return null

  let days = ''
  let hours = ''
  let minutes = ''
  let seconds = ''

  if (timeLeft?.d) {
    if (timeLeft.d > 0) {
      if (timeLeft.d === 1) {
        days = '1 day'
      } else {
        days = `${timeLeft.d} days`
      }
    }
  }

  if (typeof timeLeft?.h !== undefined) {
    if (timeLeft.h > 0) {
      if (timeLeft.h === 1) {
        hours = '1 hour'
      } else {
        hours = `${timeLeft.h} hours`
      }
    } else if (timeLeft.h === 0 && timeLeft?.d > 0) {
      hours = '0 hours'
    }
  }

  if (typeof timeLeft?.m !== undefined) {
    if (timeLeft.m > 0) {
      if (timeLeft.m === 1) {
        minutes = '1 minute'
      } else {
        minutes = `${timeLeft.m} minutes`
      }
    } else if (timeLeft.m === 0 && (timeLeft?.h > 0 || timeLeft?.d > 0)) {
      minutes = '0 minutes'
    }
  }

  if (timeLeft?.s) {
    if (timeLeft.s > 0) {
      if (timeLeft.s === 1) {
        seconds = '1 second'
      } else {
        seconds = `${timeLeft.s} seconds`
      }
    }
  }

  return `${days} ${hours} ${minutes} ${seconds}`

}

const useFaucet = (): UseMutationResult<any, any, any> => {
  const { toast, txToast } = useToastify()
  const { t } = useTranslation('common')
  const { addTransaction, PlatformState: { activePair } } = usePlatform()
  const { account } = useCfd()
  const result: UseMutationResult<any, any, any> = useMutation(
    async (address) => await request(
      BACKEND_API_URL,
      gql`
        query FaucetRequest ($address: String!) {
          getFaucet(address: $address) {
            timeLeft {
              d,
              h,
              m,
              s
            }
            txId
            message
          }
        }
      `,
      {
        address
      }
    ),
    {
      onSuccess: (data: any) => {
        const { getFaucet: { timeLeft, txId, message } } = data

        switch (message) {
          case 'success': 
            txToast(t('faucet-request-success', { account }), txId, 'success')
            addTransaction({ hash: txId })
            registerToken(activePair.quoteAssetAddr, activePair.quoteAssetName, 18, '')
          break;
          case 'balanceExist':
            toast(t(message), 'warning')
            break;
          default:
            toast(`${t('time-left-warning')} ${prepareTimeString(timeLeft)}`, 'warning')
        }
      },
      onError: (error: any) => {
        console.log('❌ useFaucet error', error)
        toast(t('network-error'), 'error')
      }
    }
  )

  return result
}

export const useTwitterFaucet = (): UseMutationResult<any, any, any> => {
  const { toast, txToast } = useToastify()
  const { t } = useTranslation('common')
  const { addTransaction } = usePlatform()
  const { account } = useCfd()
  const result: UseMutationResult<any, any, any> = useMutation(
    async (username) => await request(
      BACKEND_API_URL,
      gql`
        query FaucetTwitterRequest ($username: String!, $account: String!) {
          getFaucetByRetweet(address: $account, username: $username) {
            timeLeft{
              d
              h
              m
              s
            }
            txId
            message
          }
        }
      `,
      {
        username,
        account
      }
    ),
    {
      onSuccess: (data: any) => {
        const { getFaucetByRetweet: { timeLeft, txId, message } } = data
        
        switch (message) {
          case 'success': 
            txToast(t('twitter-faucet-request-success', { account }), txId, 'success')
            addTransaction({ hash: txId })
          break;
          case 'missingRetweet':
            toast(t(message), 'warning')
          break;
          case 'balanceExist':
            toast(t(message), 'warning')
            break;
          default:
            toast(`${t('time-left-warning')} ${prepareTimeString(timeLeft)}`, 'warning')
        }
      },
      onError: (error: any) => {
        console.log('❌ useTwitterFaucet error', error)
        toast(t('network-error'), 'error')
      }
    }
  )

  return result
}

export default useFaucet