import { EXCHANGE_BASE_URL } from '../constants'
// the SushiSwap Default token list lives here
export const DEFAULT_TOKEN_LIST_URL = `${EXCHANGE_BASE_URL}/tokens.json`

export const DEFAULT_LIST_OF_LISTS: string[] = [
  DEFAULT_TOKEN_LIST_URL,
  // 't2crtokens.eth', // kleros
  `${EXCHANGE_BASE_URL}/tokens.json`,
]

export {}
