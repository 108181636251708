import React from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import { request } from 'graphql-request'
import _ from 'lodash'
import BigNumber from 'bignumber.js'
import moment from "moment"
// Contants
import { QUERY_AMM_STATISTICS,STATISTICS_QUERY,QUERY_AMM_STATISTICS_LTE, STATISTICS_QUERY_V2, CONTRACT_LIQUIDITY_DATA } from '../queries'
import { SUBGRAPH_URL_KOVAN,SUBGRAPH_URL_STASTICS_KOVAN } from '../constants'
// Utils
// import { bnToDec } from '../utils'
import { usePlatform } from '.'
// Interfaces
import { ContractListDataProps } from '../contexts/interfaces'

export const useVolumn24h = (timestamp_lte: any, exchange: string | boolean = false, name: string): UseQueryResult => {
  const [active, setActive] = React.useState<boolean>(false)
  // const timestamp_lte = time
  // const [volumenBeforeOrLast, setVolumenBeforeOrLast] = React.useState<BigNumber>(new BigNumber(0))
  const { PlatformState: { activePair } } = usePlatform()

  const volumenBeforeOrLast = useQuery(`volume24h-timestamp-${name}-${(!exchange) ? activePair?.addr : exchange}`, async () => {
    if(!activePair.addr) return
    const { positionChangedEvents } = await request(SUBGRAPH_URL_KOVAN, QUERY_AMM_STATISTICS_LTE, {
      first: 1,
      amm: (!exchange) ? activePair.addr : exchange,
      orderBy: 'timestamp', 
      orderDirection: 'desc',
      timestamp_lte
    })

    const data: any = _.first(positionChangedEvents)
    const result = new BigNumber(data?.spotPrice).dividedBy(1e18)

    return result
  }, {
    enabled: active,
    cacheTime: 100000,
    onError: (_error: any) => {
      console.group('❌ GetVolumn24h GraphQL error')
      console.log('error', _error)
      console.groupEnd()
    }
  })

  React.useEffect(() => {
    if (!activePair) return
    setActive(true)
  }, [activePair])

  return volumenBeforeOrLast
}

export const useVolume24hChangeByExchange = (exchange: string): BigNumber | undefined => { 
  // const timestamp_gt = moment().unix() - 60*60*24
  const { PlatformState: { contractLists: { data } } } = usePlatform()
  const [volumnChange, setVolumnChange] = React.useState<BigNumber | undefined>(undefined)
  React.useEffect(() => {
    if (!data.length) return
    const pair = data.find((o: any) => o.addr === exchange)
    setVolumnChange(pair.volumeChange)
  }, [data, exchange])

  return volumnChange
}
/*
export const useStatisticalQuery = (exchange: string | boolean = false): UseQueryResult => { 
  const [active, setActive] = React.useState<boolean>(false)
  const [timestamp_gt] = React.useState(moment().add(-1, 'day').format('X'))
  const { PlatformState: { activePair } } = usePlatform()

  const volume: UseQueryResult = useQuery(`useStatisticalQuery-${(!exchange) ? activePair?.addr : exchange}`, async () => {
    const { statisticalQuery } = await request(SUBGRAPH_URL_STASTICS_KOVAN, STATISTICS_QUERY_V2, {
      amm: (!exchange) ? activePair.addr : exchange,
      time: Number(timestamp_gt)
    })

    console.group('❌ useStatisticalQuery GraphQL successs')
    console.log('statisticalQuery', statisticalQuery)
    console.groupEnd()

    return statisticalQuery
  }, {
    enabled: active,
    refetchInterval: 1000000,
    onError: (_error: any) => {
      console.group('❌ useStatisticalQuery GraphQL error')
      console.log('error', _error)
      console.groupEnd()
    }
  })

  React.useEffect(() => {
    if (!activePair) return
    setActive(true)
  }, [activePair])

  return volume
}
*/
// 24h volumn : 1：positionChangedEvents  positionNotional sum
export const useStatisticalQueryVolumn = (exchange: string | boolean = false): UseQueryResult => { 
  const [active, setActive] = React.useState<boolean>(false)
  const [timestamp_gt] = React.useState(moment().add(-1, 'day').format('X'))
  const { PlatformState: { activePair } } = usePlatform()

  const volume: UseQueryResult = useQuery(`useStatisticalQueryVolumn-${(!exchange) ? activePair?.addr : exchange}`, async () => {
    if(!activePair?.addr) return
    const { statisticalQuery } = await request(SUBGRAPH_URL_STASTICS_KOVAN, STATISTICS_QUERY, {
      amm: (!exchange) ? activePair.addr : exchange,
      eid: 1,
      time: Number(timestamp_gt)
    })

    const result = new BigNumber(statisticalQuery.result).dividedBy(1e18)

    return result
  }, {
    enabled: active,
    cacheTime: 100000,
    refetchInterval: 1000000,
    onError: (_error: any) => {
      console.group('❌ useStatisticalQueryVolumn GraphQL error')
      console.log('error', _error)
      console.groupEnd()
    }
  })

  React.useEffect(() => {
    if (!activePair) return
    setActive(true)
  }, [activePair])

  return volume
}

// 24h Trading Volumn: 2：positionChangedEvents 中 exchangedPositionSize sum
export const useStatisticalQueryTradingVolumn24h = (exchange: string | boolean = false): UseQueryResult => { 
  const [active, setActive] = React.useState<boolean>(false)
  const [timestamp_gt] = React.useState(moment().add(-1, 'day').format('X'))
  const { PlatformState: { activePair } } = usePlatform()

  const tradingVolumn24h: UseQueryResult = useQuery(`useStatisticalQueryTradingVolumn24h-${(!exchange) ? activePair?.addr : exchange}`, async () => {
    if(!activePair?.addr) return
    const { statisticalQuery } = await request(SUBGRAPH_URL_STASTICS_KOVAN, STATISTICS_QUERY, {
      amm: (!exchange) ? activePair.addr : exchange,
      eid: 2,
      time: Number(timestamp_gt)
    })

    const result = new BigNumber(statisticalQuery.result).dividedBy(1e18)

    return result
  }, {
    enabled: active,
    cacheTime: 100000,
    refetchInterval: 1000000,
    onError: (_error: any) => {
      console.group('❌ useStatisticalQueryTradingVolumn24h GraphQL error')
      console.log('error', _error)
      console.groupEnd()
    }
  })

  React.useEffect(() => {
    if (!activePair) return
    setActive(true)
  }, [activePair])

  return tradingVolumn24h
}

// Trding Volumn Long eid:3
export const useStatisticalQueryTradingVolumnLong = (exchange: string | boolean = false): UseQueryResult => { 
  const [active, setActive] = React.useState<boolean>(false)
  // const [tradingVolumnLong, setTradingVolumnLong] = React.useState<BigNumber>(new BigNumber(0))
  const { PlatformState: { activePair } } = usePlatform()

  const tradingVolumnLong: UseQueryResult = useQuery(`useStatisticalQueryTradingVolumnLong-${(!exchange ? activePair?.addr : exchange)}`, async () => {
    if (!activePair?.addr) return
    const { statisticalQuery } = await request(SUBGRAPH_URL_STASTICS_KOVAN, STATISTICS_QUERY, {
      amm: (!exchange ? activePair.addr : exchange),
      eid: 3,
      time: 0
    })

    const result = new BigNumber(statisticalQuery.result).dividedBy(1e18)

    return result
  }, {
    enabled: active,
    refetchInterval: 10000,
    onError: (_error: any) => {
      console.group('❌ useStatisticalQueryTradingVolumnLong GraphQL error')
      console.log('error', _error)
      console.groupEnd()
    }
  })

  React.useEffect(() => {
    if (!activePair) return
    setActive(true)
  }, [activePair])

  return tradingVolumnLong
}

export const useTotalTradingVolume = (): UseQueryResult<any, any> => { 
  const totalTradingVolume: UseQueryResult<any, any> = useQuery('total trading volume', async () => {
    const { contractLiquidityDatas } = await request(SUBGRAPH_URL_KOVAN, CONTRACT_LIQUIDITY_DATA)
    const result: any = _.first(contractLiquidityDatas)

    return {
      //liquidityVolume: new BigNumber(result.liquidityVolume).dividedBy(1e18),
      liquidityVolume: new BigNumber(result.totalLpLiquidity).plus(new BigNumber(result.totalLpUnrealizedPnl)).plus(new BigNumber(result.totalLpFeesAsync)).dividedBy(1e18),
      tradingVolumeLong: new BigNumber(result.tradingVolumeLong)?.abs()?.dividedBy(1e18),
      tradingVolumeShort: new BigNumber(result.tradingVolumeShort)?.abs()?.dividedBy(1e18)
    }
  },
  {
    initialData: {
      liquidityVolume: new BigNumber(0),
      tradingVolumeLong: new BigNumber(0),
      tradingVolumeShort: new BigNumber(0)
    }
  })

  return totalTradingVolume
}

// ALL TradingVolumn Short eid:4
export const useStatisticalQueryTradingVolumnShort = (exchange: string | boolean = false): UseQueryResult => { 
  const [active, setActive] = React.useState<boolean>(false)
  // const timestamp_gt = 0
  // const [tradingVolumnShort, setTradingVolumnShort] = React.useState<BigNumber>(new BigNumber(0))
  const { PlatformState: { activePair } } = usePlatform()

  const tradingVolumnShort: UseQueryResult = useQuery(`useStatisticalQueryTradingVolumnShort-${(!exchange ? activePair?.addr : exchange)}`, async () => {
    if (!activePair?.addr) return
    const { statisticalQuery } = await request(SUBGRAPH_URL_STASTICS_KOVAN, STATISTICS_QUERY, {
      amm: (!exchange ? activePair.addr : exchange),
      eid: 4,
      time: 0
    })

    const result = new BigNumber(statisticalQuery.result).dividedBy(1e18)

    return result
  }, {
    enabled: active,
    refetchInterval: 10000,
    onError: (_error: any) => {
      console.group('❌ useStatisticalQueryTradingVolumnShort GraphQL error')
      console.log('error', _error)
      console.groupEnd()
    }
  })

  React.useEffect(() => {
    if (!activePair) return
    setActive(true)
  }, [activePair])

  return tradingVolumnShort
}

// All Margin eid:5
export const useStatisticalQueryMargin = (exchange: string | boolean = false): UseQueryResult => { 
  const [active, setActive] = React.useState<boolean>(false)
  const { PlatformState: { activePair, chainEnabled } } = usePlatform()

  const margin: UseQueryResult = useQuery(`useStatisticalQueryMargin-${(!exchange ? activePair?.addr : exchange)}`, async () => {
    if(!activePair?.addr) return
    const { statisticalQuery } = await request(SUBGRAPH_URL_STASTICS_KOVAN, STATISTICS_QUERY, {
      amm: (!exchange ? activePair.addr : exchange),
      eid: 5,
      time: 0
    })

    const result = new BigNumber(statisticalQuery.result).dividedBy(1e18)

    return result
  }, {
    enabled: active,
    cacheTime: 100000,
    refetchInterval: 1000000,
    onError: (_error: any) => {
      console.group('❌ useStatisticalQueryMargin GraphQL error')
      console.log('error', _error)
      console.groupEnd()
    }
  })

  React.useEffect(() => {
    if (!activePair) return
    if (!chainEnabled) {
      setActive(false)
      return
    }
    setActive(true)
  }, [activePair, chainEnabled])

  return margin
  
  
  
  // const [active, setActive] = React.useState<boolean>(true)
  // const timestamp_gt = 0 // all data
  // const [margin, setMargin] = React.useState<BigNumber>(new BigNumber(0))
  
  // const { PlatformState: { activePair } } = usePlatform()

  // const { data, isLoading, isFetching, refetch } = useQuery('useStatisticalQueryMargin', async () => {
  //   const amm = activePair?.addr
  //   if (!amm) {
  //     return
  //   }
  //   const { statisticalQuery } = await request(SUBGRAPH_URL_STASTICS_KOVAN, STATISTICS_QUERY, {
  //     amm,
  //     eid: 5,
  //     time: timestamp_gt
  //   })
  //   return statisticalQuery
  // }, {
  //   enabled: active,
  //   refetchInterval: 1000000,
  //   onSuccess: (_data: any) => {
  //       if (!_data)
  //         return
  //       const margin = new BigNumber(_data.result)
  //       setMargin(new BigNumber(margin).dividedBy(1e18))
  //   },
  //   onError: (_error: any) => {
  //     console.group('❌ useStatisticalQueryMargin GraphQL error')
  //     console.log('error', _error)
  //     console.groupEnd()
  //   }
  // })
  // return margin
}

// 24h Max Volumn eid:6
export const useStatisticalQueryMaxVolumn = (exchange: string | boolean = false): UseQueryResult => { 
  const [active, setActive] = React.useState<boolean>(false)
  const [timestamp_gt] = React.useState(moment().add(-1, 'day').format('X'))
  const { PlatformState: { activePair } } = usePlatform()

  const maxVolume: UseQueryResult = useQuery(`useStatisticalQueryMaxVolumn-${(!exchange) ? activePair?.addr : exchange}`, async () => {
    if(!activePair?.addr) return
    const { statisticalQuery } = await request(SUBGRAPH_URL_STASTICS_KOVAN, STATISTICS_QUERY, {
      amm: (!exchange) ? activePair.addr : exchange,
      eid: 6,
      time: Number(timestamp_gt)
    })

    const maxNewVolume = new BigNumber(statisticalQuery.result).dividedBy(1e18)
    
    return maxNewVolume
  }, {
    enabled: active,
    cacheTime: 100000,
    refetchInterval: 1000000,
    onError: (_error: any) => {
      console.group('❌ useStatisticalQueryMaxVolumn GraphQL error')
      console.log('error', _error)
      console.groupEnd()
    }
  })

  React.useEffect(() => {
    if (!activePair) return
    setActive(true)
  }, [activePair])

  return maxVolume
}

// 24h Min Volumn eid:7
export const useStatisticalQueryMinVolumn = (exchange: string | boolean = false): UseQueryResult => { 
  const [active, setActive] = React.useState<boolean>(false)
  const [timestamp_gt] = React.useState(moment().add(-1, 'day').format('X'))
  const { PlatformState: { activePair } } = usePlatform()

  const maxVolume: UseQueryResult = useQuery(`useStatisticalQueryMinVolumn-${(!exchange) ? activePair?.addr : exchange}`, async () => {
    if(!activePair?.addr) return
    const { statisticalQuery } = await request(SUBGRAPH_URL_STASTICS_KOVAN, STATISTICS_QUERY, {
      amm: (!exchange) ? activePair.addr : exchange,
      eid: 7,
      time: Number(timestamp_gt)
    })

    const minNewVolumen = new BigNumber(statisticalQuery.result).dividedBy(1e18)

    return minNewVolumen
  }, {
    enabled: active,
    cacheTime: 100000,
    refetchInterval: 1000000,
    onError: (_error: any) => {
      console.group('❌ useStatisticalQueryMinVolumn GraphQL error')
      console.log('error', _error)
      console.groupEnd()
    }
  })

  React.useEffect(() => {
    if (!activePair) return
    setActive(true)
  }, [activePair])

  return maxVolume
}

// export const useStatisticalQueryMinVolumn = ():any => { 
//   const [active, setActive] = React.useState<boolean>(true)
//   const timestamp_gt = moment().unix() - 60*60*24
//   const [minvolumen, setMinVolumen] = React.useState<BigNumber>(new BigNumber(0))
//   const { PlatformState: { activePair } } = usePlatform()

//   const { data, isLoading, isFetching, refetch } = useQuery('useStatisticalQueryMinVolumn', async () => {
//     const amm = activePair?.addr
//     if (!amm) {
//       return
//     }
//     const { statisticalQuery } = await request(SUBGRAPH_URL_STASTICS_KOVAN, STATISTICS_QUERY, {
//       amm,
//       eid: 7,
//       time: timestamp_gt
//     })
//     return statisticalQuery
//   }, {
//     enabled: active,
//     refetchInterval: 1000000,
//     onSuccess: (_data: any) => {
//         if (!_data)
//           return
//         const minNewVolumen = new BigNumber(_data.result)
//         setMinVolumen(new BigNumber(minNewVolumen).dividedBy(1e18))
//     },
//     onError: (_error: any) => {
//       console.group('❌ useStatisticalQueryMinVolumn GraphQL error')
//       console.log('error', _error)
//       console.groupEnd()
//     }
//   })
//   return minvolumen
// }