// read
import moment from "moment"
/**
 * @notice get input amount. returns how many base asset you will get with the input quote amount.
 * @param dir ADD_TO_AMM for long, REMOVE_FROM_AMM for short.
 * @param quoteAssetAmount quote asset amount
 * @return base asset amount
 */
export const getInputPrice = async (
  ExchangeContract: any,
  dir: any,
  quoteAssetAmount : any,
) => {
  /*
  console.group('⁉ getInputPrice data')
  console.log('ExchangeContract', ExchangeContract)
  console.log('dir', dir)
  console.log('quoteAssetAmount <- going to contract call', quoteAssetAmount)
  console.log('quoteAssetAmount.toString()', quoteAssetAmount.toString())
  console.groupEnd()
  */
  return ExchangeContract.functions.getInputPrice(
    dir,
    [ quoteAssetAmount ],
  )
}

/**
 * @notice get output price. return how many quote asset you will get with the input base amount
 * @param _dir ADD_TO_AMM for short, REMOVE_FROM_AMM for long, opposite direction from `getInput`.
 * @param _baseAssetAmount base asset amount
 * @return quote asset amount
 */
export const getOutputPrice = async (
  ExchangeContract: any,
  dir: any,
  baseAssetAmount : any,
) => {
  return ExchangeContract.functions.getOutputPrice(
    dir,
    [ baseAssetAmount ],
  )
}

/**
 * @notice get spot price based on current quote/base asset reserve.
 * @return spot price
 */
export const getSpotPrice = async (
  ExchangeContract: any,
) => {
  return ExchangeContract.functions.getSpotPrice()
}

export const fundingPeriod = async (
  ExchangeContract: any,
) => {
  return ExchangeContract.functions.fundingPeriod()
}

// getReserve
export const getReserve = async (
  ExchangeContract: any,
) => {
  return ExchangeContract.functions.getReserve()
}

// fluctuationLimitRatio
export const fluctuationLimitRatio = async (
  ExchangeContract: any,
) => {
  return ExchangeContract.functions.fluctuationLimitRatio()
}


// totalSupply
export const totalSupply = async (
  ExchangeContract: any,
) => {
  return ExchangeContract.functions.totalSupply()
}

export const balanceOf = async (
  ExchangeContract: any,
  account: string,
) => {
  return ExchangeContract.functions.balanceOf(account)
}

export const getUnderLyingTwapPrice = async (
  ExchangeContract: any,
) => {
  const durationFromSharp = moment().minutes() * 60
  return ExchangeContract.functions.getUnderlyingTwapPrice(durationFromSharp)
}

// oraclePrice
export const getUnderLyingPrice = async (
  ExchangeContract: any,
) => {
  return ExchangeContract.functions.getUnderlyingPrice()
}

// getPositionsize
export const getPositionSize = async (
  ExchangeContract: any,
) => {
  return ExchangeContract.functions.getPositionSize()
}

export const getTwapPrice = async (
  ExchangeContract: any,
) => {
  const durationFromSharp = moment().minutes() * 60
  return ExchangeContract.functions.getTwapPrice(durationFromSharp)
}

// as a parameters of getTwapPrice
export const priceFeedKey = async (
  ExchangeContract: any,
) => {
  return ExchangeContract.functions.priceFeedKey()
}

// transaction fee
export const spreadRatio = async (
  ExchangeContract: any,
) => {
  return ExchangeContract.functions.spreadRatio()
}

export const calcFee = async (
  ExchangeContract: any,
  quoteAssetAmount : any,
) => {
  return ExchangeContract.functions.calcFee(
    [ quoteAssetAmount ],
  )
}

// write

