import { useCallback, useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useQuery, UseQueryResult } from 'react-query'
import request from 'graphql-request'
import moment from 'moment'
import numeral from 'numeral'
// Hooks
import { useCfd, usePlatform } from '.'
import { useExchangeContract } from './useContract'
import { useWeb3React } from '@web3-react/core'
// Utils
import { getSpotPrice } from '../utils/exchange'

import { ENDPOINTS } from '../constants/endpoints'
import { NETWORK_CHAIN_ID } from '../connectors'
import { QUERY_CANDLES_HOMEPAGE } from '../queries'

const useGetSpotPrice = (): UseQueryResult => {
  const [active, setActive] = useState<boolean>(false)
  const { account } = useCfd()
  const { PlatformState: { activePair, chainEnabled } } = usePlatform()
  const exchangeContract = useExchangeContract(activePair?.addr)
  const [initialPrice, setInitialPrice] = useState<BigNumber | undefined>(undefined)
  
  const spotPrice: UseQueryResult = useQuery(`useGetSpotPrice-${activePair?.addr}}`, async () => {
    const balance = await getSpotPrice(exchangeContract)
    const result = new BigNumber(balance).dividedBy(1e18)

    return result
  }, {
    enabled: active,
    refetchInterval: 10000,
    cacheTime: 10000,
    initialData: initialPrice,
    onSuccess: (_data: any) => {
      setInitialPrice(_data)
    },
    onError: (_error: any) => {
      console.group('❌ useGetSpotPrice error')
      console.log('error', _error)
      console.groupEnd()
    }
  })

  useEffect(() => {
    if (!account || !exchangeContract || !activePair) return
    if (!chainEnabled) {
      setActive(false)
      return
    }
    setActive(true)
  }, [account, exchangeContract, activePair, chainEnabled])

  return spotPrice
}

export const useGetSpotPriceByExchange = (pairContract: string): any => {
  const [val, setVal] = useState(new BigNumber(0))
  const exchangeContract = useExchangeContract(pairContract)
  const { PlatformState: { chainEnabled } } = usePlatform()
  const { account } = useWeb3React()

  const fetchVal = useCallback(async () => {
    try {
      const balance = await getSpotPrice(exchangeContract)
      const newBalance = new BigNumber(balance).dividedBy(1e18)
      setVal(newBalance)
    } catch (error) {
      console.log('useGetSpotPriceByExchange error', error)      
    }
  }, [exchangeContract, setVal])

  useEffect(() => {
    if (!account || !pairContract || !chainEnabled) return
    fetchVal()
    const refreshInterval = setInterval(fetchVal, 10000)
    return () => clearInterval(refreshInterval)
  }, [account, pairContract, fetchVal, setVal, chainEnabled])

  return val
}

export const useGetLineChartData = (pairName: string, chainId: number = NETWORK_CHAIN_ID): UseQueryResult<any, any> => {
  const result: UseQueryResult<any, any> = useQuery(['line chart', pairName], async () => {
    if (!pairName) return
    const { spotPriceStatistics } = await request(ENDPOINTS[chainId].url, QUERY_CANDLES_HOMEPAGE, { type: 4, pairName })

    return spotPriceStatistics.map((candle: any) => {
      return {
        time: Number(candle.timestamp),
        value: Number(numeral(new BigNumber(candle.close).dividedBy(1e18)).value().toFixed(2))
      }
    })
  },
  {
    initialData: [],
    onError: (_error: any) => {
      console.group('❌ useGetSpotPrice error')
      console.log('error', _error)
      console.groupEnd()
    }
  })

  return result
}

export default useGetSpotPrice
