import styled, { keyframes, css } from 'styled-components'
import { Loader as FeatherLoader } from 'react-feather'

const loaderAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`
export const Loader = styled(FeatherLoader)<{ color?: string, height?: string }>`
  animation: ${loaderAnimation} 2.5s linear infinite;
  color: ${({ color }) => color ? color : '#182F47'};
  ${({ height }) => height && css`height: ${height};`}
`
