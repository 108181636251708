// Libs
import { useCallback } from 'react'
// Hooks
import { useTokenContract } from './useContract'
import { useWeb3React } from '@web3-react/core'
// Utils
import { approve } from '../utils/erc20'

const useApprove = (tokenAddress: string, exchangeAddress: string) => {
  const { account } = useWeb3React()
  const tokenContract = useTokenContract(tokenAddress)

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(tokenContract, exchangeAddress)
      return tx
    } catch (e) {
      return e
    }
  }, [account, tokenContract, exchangeAddress])

  return { onApprove: handleApprove }
}

export default useApprove
