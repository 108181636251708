import React from 'react'
import { Label as LabelRebass } from '@rebass/forms'
import { Flex } from 'rebass/styled-components'
// Hooks
import { useTheme } from '../../../../hooks'
// Interfaces
import { ButtonGroupProps } from './interfaces'

const ButtonGroup: React.FC<ButtonGroupProps> = ({
  label,
  children,
  ...restprops
}) => {
  const { darkMode, colors, borderRadius, space, fontSizes } = useTheme()
  return (
    <Flex flexDirection='column' {...restprops}>
      {label && (
        <LabelRebass
          color={colors.grey[100]}
          fontSize={fontSizes[100]}
          mb={space.sm}
        >
          {label}
        </LabelRebass>
      )}
      <Flex
        flexDirection='row'
        alignItems='center'
        width='100%'
        sx={{
          button: {
            borderRadius: borderRadius[0],
            flex: 1,
            color: darkMode ? colors.grey[100] : colors.grey[100],
          },
          '*:first-child': {
            borderTopLeftRadius: borderRadius[4],
            borderBottomLeftRadius: borderRadius[4],
          },
          '*:last-child': {
            borderTopRightRadius: borderRadius[4],
            borderBottomRightRadius: borderRadius[4],
            borderTopLeftRadius: borderRadius[0],
            borderBottomLeftRadius: borderRadius[0],
          },
        }}
      >
        {children}
      </Flex>
    </Flex>
  )
}

export default ButtonGroup
