import { useCallback } from 'react'

import BigNumber from 'bignumber.js'
import { BigNumber as bb } from '@ethersproject/bignumber'
import { useCfd } from '.'

import { removeMargin } from '../utils/cfd'

const useRemoveMargin = (exchangeAddress: string) => {
  const { cfdContract, account } = useCfd()

  const handleRemoveMargin = useCallback(
    async (removedMargin: any) => {
      console.log('handleRemoveMargin removedMargin', removedMargin)
      const removedMarginRaw = new BigNumber(removedMargin).multipliedBy(1e18)
      console.log('handleRemoveMargin removedMarginRaw', removedMarginRaw.toFixed(0))
      console.log('handleRemoveMargin cfdContract', cfdContract)
      console.log('handleRemoveMargin exchangeAddress', exchangeAddress)
      if (cfdContract && account && exchangeAddress) {
        try {
          const tx = await removeMargin(
            cfdContract,
            exchangeAddress,
            bb.from(removedMarginRaw.toFixed(0)),
          )
          return tx
        } catch (e) {
          return e
        }
      }
    },
    [cfdContract, account, exchangeAddress],
  )

  return { onRemoveMargin: handleRemoveMargin }
}

export default useRemoveMargin
