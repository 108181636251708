import { useCallback, useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useQuery, UseQueryResult } from 'react-query'
import moment from 'moment'
// Hooks
import { useCfd, usePlatform } from '.'
import { useExchangeContract, useTokenContract } from './useContract'
import { useWeb3React } from '@web3-react/core'
// Utils
import { getReserve, fluctuationLimitRatio } from '../utils/exchange'

export const useGetReserve = (): { data: any, isLoading: any } => {
  const [active, setActive] = useState<boolean>(false)
  const { account } = useCfd()
  const { PlatformState: { activePair, chainEnabled } } = usePlatform()
  const exchangeContract = useExchangeContract(activePair?.addr)
  const [initialPrice, setInitialPrice] = useState<any>({
    quoteAssetReserve: new BigNumber(0),
    baseAssetReserve: new BigNumber(0),
  })
  
  const spotPrice: UseQueryResult = useQuery(`useGetReserve-${activePair?.addr}}`, async () => {
    const result = await getReserve(exchangeContract)
    //const result = new BigNumber(balance).dividedBy(1e18)
    const resultBig = {
      quoteAssetReserve: new BigNumber(result[0]),
      baseAssetReserve: new BigNumber(result[1]),
    }
    //console.log('result quoteAssetReserve', resultBig.quoteAssetReserve.toFixed(0))
    //console.log('result baseAssetReserve', resultBig.baseAssetReserve.toFixed(0))
    return resultBig
  }, {
    enabled: active,
    refetchInterval: 10000,
    cacheTime: 10000,
    initialData: initialPrice,
    onSuccess: (_data: any) => {
      setInitialPrice(_data)
    },
    onError: (_error: any) => {
      console.group('❌ useGetReserve error')
      console.log('error', _error)
      console.groupEnd()
    }
  })

  useEffect(() => {
    if (!account || !exchangeContract || !activePair) return
    if (!chainEnabled) {
      setActive(false)
      return
    }
    setActive(true)
  }, [account, exchangeContract, activePair, chainEnabled])

  return spotPrice
}


export const useGetFluctuationLimitRatio = (): { data: any, isLoading: any } => {
  const [active, setActive] = useState<boolean>(false)
  const { account } = useCfd()
  const { PlatformState: { activePair, chainEnabled } } = usePlatform()
  const exchangeContract = useExchangeContract(activePair?.addr)
  const [initialPrice, setInitialPrice] = useState<any>(new BigNumber(0))
  
  const spotPrice: UseQueryResult = useQuery(`useGetFluctuationLimitRatio-${activePair?.addr}}`, async () => {
    const result = await fluctuationLimitRatio(exchangeContract)
    return new BigNumber(result)
  }, {
    enabled: active,
    cacheTime: 10000,
    initialData: initialPrice,
    onSuccess: (_data: any) => {
      setInitialPrice(_data)
    },
    onError: (_error: any) => {
      console.group('❌ useGetFlactuationLimitRatio error')
      console.log('error', _error)
      console.groupEnd()
    }
  })

  useEffect(() => {
    if (!account || !exchangeContract || !activePair) return
    if (!chainEnabled) {
      setActive(false)
      return
    }
    setActive(true)
  }, [account, exchangeContract, activePair, chainEnabled])

  return spotPrice
}
