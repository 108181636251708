import React from 'react'
import { useMutation, UseMutationResult } from 'react-query'
import { GraphQLClient, gql } from 'graphql-request'
import { ColorContext } from '../contexts'
import { ColorProps, LightTheme as InitialLightTheme, DarkTheme as InitialDarkTheme } from '../theme/colors'
import { FRONTEND_API_URL } from '../constants'
import { useCfd, useTheme } from '.'

const client = new GraphQLClient(FRONTEND_API_URL, { headers: {} })

const useColor = () => {
  const { darkMode } = useTheme()
  const { id, DarkTheme, LightTheme, jwt, refetch } = React.useContext(ColorContext)
  const { account } = useCfd()

  const update: UseMutationResult<any, any, any> = useMutation(
    async ({
      themeColors
    }: {
      themeColors: ColorProps, 
    }) => {
      if (darkMode) {
        if (!id) {
          return await client.request(
            gql`
              mutation ($themeColors: JSON, $lightColors: JSON, $account: String) {
                createUserConfig (
                  input: {
                    data: {
                      colors_dark: $themeColors
                      colors_light: $lightColors
                      wallet: $account
                    }
                  },
                ) {
                  userConfig {
                    id
                    colors_dark
                  }
                }
              }
            `,
            {
              themeColors,
              lightColors: InitialLightTheme,
              account
            },
            {
              authorization: `Bearer ${jwt}`
            }
          )
        }

        return await client.request(
          gql`
            mutation ($themeColors: JSON, $id: ID!) {
              updateUserConfig (
                input: {
                  data: {
                    colors_dark: $themeColors
                  }
                  where: {
                    id: $id
                  }
                },
              ) {
                userConfig {
                  id
                  colors_dark
                }
              }
            }
          `,
          {
            themeColors,
            id
          },
          {
            authorization: `Bearer ${jwt}`
          }
        )
      }

      if (!id) {
        return await client.request(
          gql`
            mutation ($themeColors: JSON, $darkColors: JSON, $account: String) {
              createUserConfig (
                input: {
                  data: {
                    colors_light: $themeColors
                    colors_dark: $darkColors
                    wallet: $account
                  }
                },
              ) {
                userConfig {
                  id
                  colors_dark
                }
              }
            }
          `,
          {
            themeColors,
            darkColors: InitialDarkTheme,
            account
          },
          {
            authorization: `Bearer ${jwt}`
          }
        )
      }

      return await client.request(
        gql`
          mutation ($themeColors: JSON, $id: ID!) {
            updateUserConfig (
              input: {
                data: {
                  colors_light: $themeColors
                }
                where: {
                  id: $id
                }
              },
            ) {
              userConfig {
                id
                colors_light
              }
            }
          }
        `,
        {
          themeColors,
          id
        },
        {
          authorization: `Bearer ${jwt}`
        }
      )
    }
  )

  const reset: UseMutationResult<any, any, any> = useMutation(
    async () => await client.request(
      gql`
        mutation ($id: ID!) {
          deleteUserConfig (input: { where: { id: $id } }, ) {
            userConfig {
              id
            }
          }
        }
      `,
      {
        id
      },
      {
        authorization: `Bearer ${jwt}`
      }
    )
  )

  return {
    id,
    DarkTheme,
    LightTheme,
    jwt,
    refetch,
    update,
    reset
  }
}

export default useColor