import React from 'react'
import { Label as LabelRebass } from '@rebass/forms'
import { default as RCSlider, createSliderWithTooltip } from 'rc-slider'
import { Flex, Box } from 'rebass/styled-components'
import { rem } from 'polished'
// Hooks
import { useTheme } from '../../../../hooks'
// Interfaces
import { SliderProps } from './interfaces'

const SliderWithTooltip = createSliderWithTooltip(RCSlider)

const Slider: React.FC<SliderProps> = ({
  label,
  name,
  min = 1,
  max = 10,
  ...restprops
}) => {
  const { darkMode, colors, space, fontSizes } = useTheme()
  return (
    <Flex flexDirection='column'>
      {label && (
        <Flex justifyContent='space-between' alignItems='center'>
          <LabelRebass
            color={colors.grey[100]}
            fontSize={fontSizes[100]}
            mb={space.sm}
            htmlFor={name}
            justifyContent='flex-start'
          >
            {label}
          </LabelRebass>
          <LabelRebass
            color={colors.grey[100]}
            fontSize={fontSizes[100]}
            mb={space.sm}
            htmlFor={name}
            justifyContent='flex-end'
          >
            {max}x
          </LabelRebass>
        </Flex>
      )}
      <SliderWithTooltip
        min={min}
        max={max}
        tipFormatter={(value) => `${value}x`}
        tipProps={{placement: 'bottom'}}
        railStyle={{background: darkMode ? colors.blue[200] : colors.grey[300]}}
        trackStyle={{background: darkMode ? colors.blue[200] : colors.blue[200]}}
        handleStyle={{
          background: darkMode ? colors.blue[200] : colors.blue[200],
          borderColor: darkMode ? colors.blue[200] : colors.grey[300],
          borderWidth: rem('5px'),
          height: rem('22px'),
          width: rem('22px'),
          marginTop: rem('-9px'),
        }}
        {...restprops}
      />
    </Flex>
  )
}

export default Slider
