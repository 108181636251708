import React from 'react'
import { useQuery } from 'react-query'
import { request } from 'graphql-request'
import moment from 'moment'
import BigNumber from 'bignumber.js'
import numeral from 'numeral'
// Contants
import { QUERY_USER_HISTORY_POSITIONS } from '../queries'
import { SUBGRAPH_URL_KOVAN, DATE_FORMAT, NUMBER_FORMAT } from '../constants'
// Contexts
import { PlatformContext } from '../contexts'
// Utils
import { bnToDec } from '../utils'
import { useCfd } from '.'

interface MyTxEventResponse {
  eventType: number
  id: string
  exchangeHex: string
  traderHex: string
  lpFund: string
  timestamp: string,
  exchangedPositionSize: string,
  fee: string,
  margin: string,
  positionNotional: string,
  realizedPnl: string,
  spotPrice: string,
  unrealizedPnlAfter: string,
  amm: string,
  positionSizeAfter: string,
  overnightFee: string,
  fundingPayment: string,
}

const useGetMyTransactions = (): {
  myPositions: any
  myPositionsIsLoading: boolean,
  myPositionsIsFetching: boolean,
  myPositionsRefetch: any
} => { 
  const { PlatformState } = React.useContext(PlatformContext)
  const { activePair, contractLists } = PlatformState
  const { account } = useCfd()
  const [active, setActive] = React.useState<boolean>(false)

  const { data, isLoading, isFetching, refetch } = useQuery('useGetMyTransactions', async () => {
    const { positionChangedEvents } = await request(SUBGRAPH_URL_KOVAN, QUERY_USER_HISTORY_POSITIONS, {
      first: 100, 
      orderBy: 'timestamp', 
      orderDirection: 'desc',
      timestamp_gt: 614265867,
      trader: account
    })
    return positionChangedEvents
  }, {
    enabled: active,
    refetchInterval: 100000,
    cacheTime: 100000,
    onSuccess: (_data: any) => {
      // console.group('🔎 GetMyHistoryPositions GraphQL result')
      // console.log(_data)
      // console.groupEnd()
    },
    onError: (_error: any) => {
      console.group('❌ GetMyHistoryPositions GraphQL error')
      console.log(_error)
      console.groupEnd()
    }
  })

  React.useEffect(() => {
    if (!activePair) return
    setActive(true)
    refetch()
  }, [activePair, refetch])

  return {
    myPositions: data?.map((position: MyTxEventResponse) => ({
      pair: contractLists?.data?.find(a => a.addr?.toLowerCase() === position.amm),
      key: position.id,
      lpFund: numeral(bnToDec(new BigNumber(position.lpFund))).format(NUMBER_FORMAT[3]),
      exchangeAddress: position.exchangeHex,
      eventType: position.eventType,
      traderAddress: position.traderHex,
      time: moment.unix(Number(position.timestamp)).format(DATE_FORMAT),
      exchangedPositionSize: numeral(bnToDec(new BigNumber(position.exchangedPositionSize))).format(NUMBER_FORMAT[3]),
      fee: numeral(bnToDec(new BigNumber(position.fee))).format(NUMBER_FORMAT[3]),
      margin: numeral(bnToDec(new BigNumber(position.margin))).format(NUMBER_FORMAT[3]),
      positionNotional: numeral(bnToDec(new BigNumber(position.positionNotional))).format(NUMBER_FORMAT[3]),
      realizedPnl: numeral(bnToDec(new BigNumber(position.realizedPnl))).format(NUMBER_FORMAT[3]),
      spotPrice: numeral((new BigNumber(position.positionNotional).dividedBy(1e18).abs()).dividedBy(new BigNumber(position.exchangedPositionSize).dividedBy(1e18).abs())).format(NUMBER_FORMAT[3]),
      unrealizedPnlAfter: numeral(bnToDec(new BigNumber(position.unrealizedPnlAfter))).format(NUMBER_FORMAT[3]),
      plwithfee: numeral(bnToDec(new BigNumber(position.realizedPnl))).format(NUMBER_FORMAT[3]),
      positionSizeAfter: numeral(bnToDec(new BigNumber(position.positionSizeAfter))).format(NUMBER_FORMAT[3]),
      overnightFee: numeral(bnToDec(new BigNumber(position.overnightFee))).format(NUMBER_FORMAT[3]),
      fundingPayment: numeral(bnToDec(new BigNumber(position.fundingPayment))).format(NUMBER_FORMAT[3]),
    })),
    myPositionsIsLoading: isLoading,
    myPositionsIsFetching: isFetching,
    myPositionsRefetch: refetch
  }
}

export default useGetMyTransactions