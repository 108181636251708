import React from 'react'
import { useQuery } from 'react-query'
import { request } from 'graphql-request'
import moment from 'moment'
import BigNumber from 'bignumber.js'
import numeral from 'numeral'
// Contants
import { QUERY_LIQUIDITY_HISTORY } from '../queries'
import { SUBGRAPH_URL_KOVAN, DATE_FORMAT, NUMBER_FORMAT } from '../constants'
// Contexts
import { PlatformContext } from '../contexts'
// Utils
import { bnToDec } from '../utils'

interface LiquidityChangedEventResponse {
  eventType: number
  id: string
  exchangeHex: string
  traderHex: string
  lpFund: string
  lpToken: string
  timestamp: string
}

const useGetAllLiquidity = (): {
  allLiquidity: any
  allLiquidityIsLoading: boolean,
  allLiquidityIsFetching: boolean,
  allLiquidityRefetch: any
} => { 
  const { PlatformState } = React.useContext(PlatformContext)
  const { activePair, chainEnabled } = PlatformState
  const [active, setActive] = React.useState<boolean>(false)

  const { data, isLoading, isFetching, refetch } = useQuery('QUERY_LIQUIDITY_HISTORY', async () => {
    if (!activePair?.addr) return
    const { liquidityChangedEvents } = await request(SUBGRAPH_URL_KOVAN, QUERY_LIQUIDITY_HISTORY, {
      first: 100, 
      orderBy: 'timestamp', 
      orderDirection: 'desc',
      exchange: activePair?.addr
    })
    return liquidityChangedEvents
  }, {
    enabled: active,
    refetchInterval: 100000,
    cacheTime: 100000,
    onError: (_error: any) => {
      console.group('❌ GetAllLiquidity GraphQL error')
      console.log(_error)
      console.groupEnd()
    }
  })

  React.useEffect(() => {
    if (!activePair) return
    // if (!chainEnabled) {
    //   setActive(false)
    //   return
    // }
    setActive(true)
    refetch()
  }, [activePair, refetch, chainEnabled])

  return {
    allLiquidity: data?.map((position: LiquidityChangedEventResponse) => ({
      key: position.id,
      lpFund: numeral(bnToDec(new BigNumber(position.lpFund))).format(NUMBER_FORMAT[0]),
      lpToken: numeral(bnToDec(new BigNumber(position.lpToken))).format(NUMBER_FORMAT[0]),
      exchangeAddress: position.exchangeHex,
      eventType: position.eventType,
      traderAddress: position.traderHex,
      time: moment.unix(Number(position.timestamp)).format(DATE_FORMAT),
    })),
    allLiquidityIsLoading: isLoading,
    allLiquidityIsFetching: isFetching,
    allLiquidityRefetch: refetch
  }
}

export default useGetAllLiquidity