import { useContext } from 'react'

import { Context } from '../contexts/cfd'

const useCfd = (): any => {
  const cfd = useContext(Context)
  return cfd
}

export default useCfd
