import React from 'react'
import { buttons, rem, lighten } from 'polished'
import { createGlobalStyle } from 'styled-components'
// import { DarkTheme, LightTheme } from './colors'
import BackgroundHomeBanner from '../assets/images/bg-home.jpg'
import BackgroundCompetitionBanner from '../assets/images/bg-competition1-bsc.jpg'
import { ColorContext } from '../contexts'


const useDefaultTheme = () => {
  const { DarkTheme, LightTheme } = React.useContext(ColorContext)
  const darkMode = React.useMemo(() => false, [])

  const breakpoints: any = React.useMemo(() => ['860px', '1440px', '1600px', '1920px'], [])

  const borderRadius = React.useMemo(() => ({
    '0': 0,
    '4': rem('4px'),
  }), [])

  const fonts = React.useMemo(() => ({
    body: "'Lato', sans-serif",
    title: "'Gotham', sans-serif",
  }), [])

  const fontSizes = React.useMemo(() => ({
    body: '16px',
    xs: rem('9px'),
    sm: rem('11px'),
    md: rem('13px'),
    lg: rem('17px'),
    xl: rem('21px'),
    100: rem('14px'),
    200: rem('10px'),
  }), [])

  const lineHeights = React.useMemo(() => ({
    body: 1.45,
  }), [])

  const space = React.useMemo(() => ({
    xxs: rem('1px'),
    xs: rem('3px'),
    sm: rem('8px'),
    md: rem('13px'),
    lg: rem('18px'),
    xl: rem('23px'),
    xxl: rem('28px'),
    xxxl: rem('33px'),
  }), [])

  const buttonSize = React.useMemo(() => ({
    xs: rem('3px'),
    sm: rem('5px'),
    md: rem('10px'),
    lg: rem('15px'),
    xl: rem('20px'),
  }), [])

  const buttonCommonStyles = React.useMemo(() => ({
    cursor: 'pointer',
    fontSize: fontSizes.md,
    paddingTop: buttonSize.xs,
    paddingRight: buttonSize.lg,
    paddingBottom: buttonSize.xs,
    paddingLeft: buttonSize.lg,
    fontFamily: fonts.title,
    width: 'auto',
    height: 'inherit',
    transition: 'all ease-in-out 0.2s',
    '&:active': {
      transform: 'scale(0.95)',
    },
    '&.light': {
      fontFamily: fonts.body,
    },
    '&.font-small': {
      fontSize: rem('10px'),
    },
  }), [buttonSize.lg, buttonSize.xs, fontSizes.md, fonts.body, fonts.title])

  const commonCardStyles = React.useMemo(() => ({
    lineHeight: lineHeights.body,
  }), [lineHeights.body])

  const modal = React.useMemo(() => ({
    backdrop: darkMode ? DarkTheme.backdrop : LightTheme.backdrop,
  }), [DarkTheme.backdrop, LightTheme.backdrop, darkMode])

  const tableRow = React.useMemo(() => ({
    fontSize: fontSizes.md,
    padding: `${space.sm} ${space.xxl}`,
    width: '100%',
  }), [fontSizes.md, space.sm, space.xxl])

  return {
    darkMode,
    breakpoints,
    colors: darkMode ? DarkTheme : LightTheme,
    bodyBg: darkMode ? DarkTheme.grey[300] : LightTheme.grey[300],
    fonts,
    borderRadius,
    spacer: {
      xs: rem('2px'),
      sm: rem('5px'),
      md: rem('9px'),
      lg: rem('13px'),
      xl: rem('17px'),
      xxl: rem('25px'),
    },
    headings: {
      title: darkMode ? DarkTheme.blue : LightTheme.blue[200],
    },
    card: {
      title: darkMode ? DarkTheme.blue : LightTheme.white,
      titleBackground: darkMode ? DarkTheme.grey[500] : LightTheme.blue[200],
      background: darkMode ? DarkTheme.grey[300] : LightTheme.white,
      titleInverted: darkMode ? DarkTheme.blue : LightTheme.white,
      titleBackgroundInverted: darkMode
        ? DarkTheme.grey[500]
        : LightTheme.grey[100],
      backgroundInverted: darkMode ? DarkTheme.grey[300] : LightTheme.grey[100],
      colorInverted: darkMode ? DarkTheme.grey[300] : LightTheme.white,
    },
    fontSizes,
    lineHeights,
    space,
    modal,
    cards: {},
    variants: {
      'card-primary': {
        ...commonCardStyles,
        borderRadius: borderRadius[4],
        background: darkMode ? DarkTheme.grey[300] : LightTheme.white,
      },
      'card-secondary': {
        ...commonCardStyles,
        borderRadius: borderRadius[4],
        background: darkMode ? DarkTheme.grey[500] : LightTheme.grey[800],
        color: darkMode ? DarkTheme.grey[300] : LightTheme.white,
      },
      'card-thertiary': {
        ...commonCardStyles,
        borderRadius: borderRadius[4],
        background: darkMode ? DarkTheme.blue[200] : LightTheme.blue[200],
        color: darkMode ? DarkTheme.grey[300] : LightTheme.white,
      },
      'card-dark': {
        ...commonCardStyles,
        borderRadius: borderRadius[4],
        background: darkMode ? DarkTheme.blue[200] : LightTheme.blue[200],
        color: darkMode ? DarkTheme.white : LightTheme.white,
      },
      'card-percentage-green': {
        ...commonCardStyles,
        borderRadius: borderRadius[4],
        background: darkMode ? DarkTheme.green[100] : LightTheme.green[100],
        height: rem('4px'),
      },
      'card-percentage-red': {
        ...commonCardStyles,
        borderRadius: borderRadius[4],
        background: darkMode ? DarkTheme.red[100] : LightTheme.red[100],
        height: rem('4px'),
      },
      'card-percentage-skeleton': {
        ...commonCardStyles,
        borderRadius: borderRadius[4],
        // backgroundColor: darkMode ? DarkTheme.grey[800] : LightTheme.grey[800],
        background: `linear-gradient(90deg, ${darkMode ? DarkTheme.grey[800] : LightTheme.grey[800]} 0px, ${darkMode ? DarkTheme.blue[100] : LightTheme.blue[200]} 40px, ${darkMode ? DarkTheme.grey[800] : LightTheme.grey[800]} 80px)`,
        backgroundSize: rem('600px'),
        animation: 'skeletonAnimation 1.6s infinite linear',
        height: rem('4px'),
      },
      'card-home-banner': {
        ...commonCardStyles,
        borderRadius: borderRadius[4],
        background: '#13a6cb url('+BackgroundHomeBanner+') no-repeat center center',
        backgroundSize: '100% auto',
        color: darkMode ? DarkTheme.white : LightTheme.white,
      },
      'card-competition-banner': {
        ...commonCardStyles,
        borderRadius: borderRadius[4],
        background: '#13a6cb url('+BackgroundCompetitionBanner+') no-repeat center center',
        backgroundSize: '100% auto',
        color: darkMode ? DarkTheme.white : LightTheme.white,
      },
      'card-menu': {
        ...commonCardStyles,
        textDecoration: 'none',
        borderRadius: borderRadius[0],
        background: darkMode ? DarkTheme.blue[200] : LightTheme.blue[200],
        color: darkMode ? DarkTheme.grey[800] : LightTheme.grey[800],
        borderBottom: darkMode ? '1px solid'+DarkTheme.grey[1100] : '1px solid'+DarkTheme.grey[1100],
        span: {
          fontFamily: fonts.title,
          fontSize: rem('14px'),
        },
        svg: {
          height: rem('22px'),
          marginLeft: rem('12px'),
          marginRight: rem('28px'),
          opacity: 0.5
        },
        '&:hover': {
          opacity: 0.5,
          background: darkMode ? DarkTheme.grey[1100] : LightTheme.grey[1100],
          color: darkMode ? DarkTheme.white : LightTheme.white,
          svg: { opacity: 1 }
        },
        '&.active': {
          background: darkMode ? DarkTheme.grey[1100] : LightTheme.grey[1100],
          color: darkMode ? DarkTheme.white : LightTheme.white,
          svg: { opacity: 1 },
          '&:hover': {
            opacity: 1,
          },
        },
        '&:first-child': {
          borderTop: darkMode ? '1px solid'+DarkTheme.grey[1100] : '1px solid'+DarkTheme.grey[1100]
        }
      },
      'card-market-indicator': {
        height:rem('16px'), 
        width:rem('16px'), 
        display:'block', 
        position:'absolute',
        right:rem('8px'), 
        top:rem('8px'),
        overflow:'hidden'  
      },
      'link-external': {
        fontFamily: fonts.title,
        fontSize: rem('13px'),
        lineHeight: rem('24px'),
        color: darkMode ? DarkTheme.grey[800] : LightTheme.grey[800],
        display: 'block',
        marginLeft: rem('12px'),
        '&:hover': { 
          color: darkMode ? DarkTheme.grey[300] : LightTheme.white,
          cursor: 'pointer'
        }
      },
      'spacer-dash-dark': {
        ...commonCardStyles,
        borderRadius: borderRadius[0],
        background: darkMode ? DarkTheme.grey[900] : LightTheme.grey[900],
        marginTop: rem('10px'),
        marginBottom: rem('10px'),
      },
      'spacer-dash-light': {
        ...commonCardStyles,
        borderRadius: borderRadius[0],
        background: darkMode ? DarkTheme.grey[1000] : LightTheme.grey[1000],
        marginTop: rem('8px'),
        marginBottom: rem('8px'),
      },
      'card-title': {
        fontFamily: fonts.title,
        fontSize: fontSizes[100],
        borderTopLeftRadius: borderRadius[4],
        borderTopRightRadius: borderRadius[4],
        // padding: `${space.lg} ${space.xxl}`,
        paddingY: [space.md, space.md, space.lg, space.lg],
        paddingX: [space.lg, space.md, space.xl, space.xxl],
        background: darkMode ? DarkTheme.grey[500] : LightTheme.blue[200],
        color: darkMode ? DarkTheme.white : LightTheme.white,
      },
      'card-title-secondary': {
        fontFamily: fonts.title,
        fontsize: fontSizes[100],
        borderTopLeftRadius: borderRadius[4],
        borderTopRightRadius: borderRadius[4],
        // padding: `${space.lg} ${space.xxl}`,
        paddingY: [space.md, space.md, space.lg, space.lg],
        paddingX: [space.lg, space.md, space.xl, space.xxl],
        background: darkMode ? DarkTheme.grey[500] : LightTheme.grey[800],
        color: darkMode ? DarkTheme.white : LightTheme.white,
      },
      'card-body': {
        fontSize: fontSizes.md,
        // padding: `${rem('23px')} ${rem('30px')}`,
        paddingY: [space.md, space.md, space.lg, space.lg],
        paddingX: [space.lg, space.md, space.xl, space.xxl],
      },
      'font-title': {
        fontFamily: fonts.title,
      },
      drawer: {
        position: 'absolute',
        top: 0,
        left: '-360px',
        width: '360px',
        height: '100%',
        padding: space.xl,
        color: darkMode ? DarkTheme.grey[100] : LightTheme.white,
        bg: darkMode ? DarkTheme.black : LightTheme.blue[200],
        transition: 'transform 0.3s ease-in-out',
        zIndex: 998,
      },
      'wizard-title': {
        transition: 'all ease-in-out 0.2s',
        color: darkMode ? DarkTheme.grey[100] : LightTheme.grey[100],
        '&.active': {
          color: darkMode ? DarkTheme.white : LightTheme.black,
        },
      },
      'wizard-number': {
        transition: 'all ease-in-out 0.2s',
        bg: darkMode ? DarkTheme.blue[200] : LightTheme.grey[300],
        color: darkMode ? DarkTheme.white : LightTheme.grey[100],
        paddingX: rem('6px'),
        paddingY: rem('3px'),
        zIndex: 1,
        '&.active': {
          bg: darkMode ? DarkTheme.blue[200] : LightTheme.blue[200],
          color: darkMode ? DarkTheme.white : LightTheme.white,
        },
      },
      'wizard-line': {
        transition: 'all ease-in-out 0.2s',
        color: darkMode ? DarkTheme.blue[200] : LightTheme.grey[300],
        width: '100%',
        borderWidth: '1px',
        borderStyle: 'solid',
        zIndex: 0,
        marginTop: rem('-12px'),
        '&.active': {
          color: darkMode ? DarkTheme.blue[200] : LightTheme.blue[200],
        },
      },
      table: {
        flexDirection: 'column',
        width: '100%',
        bg: darkMode ? DarkTheme.grey[100] : LightTheme.white,
        borderBottomLeftRadius: borderRadius[4],
        borderBottomRightRadius: borderRadius[4],
      },
      'table-header': {
        borderTopLeftRadius: borderRadius[4],
        borderTopRightRadius: borderRadius[4],
        flexDirection: 'row',
        width: '100%',
        bg: darkMode ? DarkTheme.blue[200] : LightTheme.grey[800],
        color: darkMode ? DarkTheme.white : LightTheme.white,
        fontSize: fontSizes[100],
        padding: `${space.lg} ${space.xxl}`,
      },
      'table-header-noradius': {
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0',
        flexDirection: 'row',
        width: '100%',
        bg: darkMode ? DarkTheme.blue[200] : LightTheme.grey[800],
        color: darkMode ? DarkTheme.white : LightTheme.white,
        fontSize: fontSizes[100],
        padding: `${space.lg} ${space.xxl}`,
      },    
      'table-row': tableRow,
      'table-row-even': {
        ...tableRow,
        bg: darkMode ? DarkTheme.grey[700] : LightTheme.grey[700],
      },
      'table-row-odd': {
        ...tableRow,
      },
      'scrollbar-thumb': {
        borderRadius: borderRadius[4],
        backgroundColor: darkMode ? DarkTheme.blue[200] : LightTheme.blue[200],
      },
      'scrollbar-thumb-grey': {
        borderRadius: borderRadius[4],
        backgroundColor: darkMode ? DarkTheme.grey[800] : LightTheme.grey[800],
      },
      'scrollbar-track': {
        borderRadius: borderRadius[4],
        backgroundColor: darkMode ? DarkTheme.grey[300] : LightTheme.grey[300],
      },
      'top-bar': {
        bg: darkMode ? DarkTheme.grey[100] : LightTheme.blue[200],
        color: darkMode ? DarkTheme.white : LightTheme.white,
        fontSize: fontSizes[100],
        fontWeight: 600,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: space.sm,
        paddingRight: space.xl,
        borderRadius: borderRadius[4],
      },
      'tab-btn': {
        cursor: 'pointer',
        color: darkMode ? DarkTheme.grey[100] : LightTheme.grey[100],
        borderBottomStyle: 'solid',
        borderBottomColor: 'transparent',
        borderBottomWidth: '2px',
        py: space.xl,
        px: space.md,
        fontSize: fontSizes['md'],
        fontFamily: fonts.title,
        transition: 'all ease-in-out 0.2s',
        '&:hover': {
          color: darkMode ? DarkTheme.white : LightTheme.black,
          borderBottomColor: darkMode
            ? DarkTheme.blue[200]
            : LightTheme.blue[200],
        },
        '&.active': {
          color: darkMode ? DarkTheme.white : LightTheme.black,
          borderBottomColor: darkMode
            ? DarkTheme.blue[200]
            : LightTheme.blue[200],
        },
      },
      'tab-btn-grey': {
        cursor: 'pointer',
        color: darkMode ? DarkTheme.grey[100] : LightTheme.grey[100],
        borderBottomStyle: 'solid',
        borderBottomColor: 'transparent',
        borderBottomWidth: '2px',
        py: space.xl,
        px: space.md,
        fontSize: fontSizes['md'],
        fontFamily: fonts.title,
        transition: 'all ease-in-out 0.2s',
        '&:hover': {
          color: darkMode ? DarkTheme.white : LightTheme.black,
          borderBottomColor: darkMode
            ? DarkTheme.grey[200]
            : LightTheme.grey[100],
        },
        '&.active': {
          color: darkMode ? DarkTheme.white : LightTheme.black,
          borderBottomColor: darkMode
            ? DarkTheme.grey[200]
            : LightTheme.grey[100],
        },
      },
      ruler: {
        borderWidth: 0,
        height: space.xs,
        color: darkMode ? DarkTheme.blue[200] : LightTheme.grey[300],
        backgroundColor: darkMode ? DarkTheme.blue[200] : LightTheme.grey[300],
      },
      'loading-wrapper': { 
        position: 'absolute', 
        width: '100%',
        height: '100%',
        top: 0, 
        right: 0, 
        bottom: 0, 
        left: 0, 
        bg: darkMode ? DarkTheme.backdrop : LightTheme.lightBackdrop,
        zIndex: 990
      },
      'pair-menu': {
        ...commonCardStyles,
        textDecoration: 'none',
        borderRadius: borderRadius[0],
        background: darkMode ? DarkTheme.blue[200] : LightTheme.blue[200],
        color: darkMode ? DarkTheme.grey[800] : LightTheme.grey[800],
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: darkMode ? DarkTheme.grey[1100] : LightTheme.grey[1100],
        span: {
          fontFamily: fonts.title,
          fontSize: rem('14px'),
        },
        '&:hover': {
          background: darkMode ? DarkTheme.grey[1100] : LightTheme.grey[1100],
        },
        '&:hover .contract-name': {
          color: darkMode ? DarkTheme.white : LightTheme.white,
        }
      },
      'drop-down': {
        position: 'absolute',
        zIndex: 100,
        display: 'none',
        '&.hover': {
          display: 'block'
        }
      }
    },
    buttonSize,
    buttons: {
      primary: {
        ...buttonCommonStyles,
        background: 'transparent',
        border: '1px solid transparent',
        color: darkMode ? DarkTheme.black : LightTheme.grey[500],
        '&:hover': {
          background: darkMode ? DarkTheme.blue : LightTheme.blue[200],
          border: darkMode
            ? `1px solid ${DarkTheme.blue}`
            : `1px solid ${LightTheme.blue[200]}`,
          color: darkMode ? DarkTheme.white : LightTheme.white,
        },
        '&:disabled': {
          background: lighten(
            0.05,
            darkMode ? DarkTheme.blue[200] : LightTheme.grey[300],
          ),
          color: lighten(0.82, darkMode ? DarkTheme.white : LightTheme.black),
          border: `${rem('1px')} solid ${lighten(
            0.05,
            darkMode ? DarkTheme.blue[200] : LightTheme.grey[300],
          )}}`,
          
        },
      },
      nav: {
        ...buttonCommonStyles,
        background: 'transparent',
        border: '1px solid transparent',
        color: darkMode ? DarkTheme.black : LightTheme.white,
        '&:hover': {
          opacity: 0.8,
        },
        '&:disabled': {
          opacity: 0.8,
        },
      },
      'primary-outlined': {
        ...buttonCommonStyles,
        background: 'transparent',
        border: darkMode
          ? `1px solid ${DarkTheme.blue}`
          : `1px solid ${LightTheme.grey[100]}`,
        color: darkMode ? DarkTheme.white : LightTheme.grey[100],
        '&.active': {
          background: darkMode ? DarkTheme.blue : LightTheme.blue[200],
          border: darkMode
            ? `1px solid ${DarkTheme.blue}`
            : `1px solid ${LightTheme.blue[200]}`,
          color: darkMode ? DarkTheme.white : LightTheme.white,
        },
        '&:hover': {
          background: darkMode ? DarkTheme.blue : LightTheme.blue[200],
          border: darkMode
            ? `1px solid ${DarkTheme.blue}`
            : `1px solid ${LightTheme.blue[200]}`,
          color: darkMode ? DarkTheme.white : LightTheme.white,
        },
        '&:disabled': {
          background: lighten(
            0.05,
            darkMode ? DarkTheme.blue[200] : LightTheme.grey[300],
          ),
          color: lighten(0.82, darkMode ? DarkTheme.white : LightTheme.black),
          border: `${rem('1px')} solid ${lighten(
            0.05,
            darkMode ? DarkTheme.blue[200] : LightTheme.grey[300],
          )}}`,
          
        },
      },
      green: {
        ...buttonCommonStyles,
        background: darkMode ? DarkTheme.green[100] : LightTheme.green[100],
        border: darkMode
          ? `1px solid ${DarkTheme.green[100]}`
          : `1px solid ${LightTheme.green[100]}`,
        color: darkMode ? DarkTheme.white : LightTheme.white,
        '&:hover': {
          background: darkMode
            ? lighten(0.05, DarkTheme.green[100])
            : lighten(0.05, LightTheme.green[100]),
          border: darkMode
            ? `1px solid ${lighten(0.05, DarkTheme.green[100])}`
            : `1px solid ${lighten(0.05, LightTheme.green[100])}`,
        },
        '&:disabled': {
          background: darkMode
            ? lighten(0.05, DarkTheme.green[100])
            : lighten(0.05, LightTheme.green[100]),
          border: darkMode
            ? `1px solid ${lighten(0.05, DarkTheme.green[100])}`
            : `1px solid ${lighten(0.05, LightTheme.green[100])}`,
          color: darkMode
            ? lighten(0.5, DarkTheme.green[100])
            : lighten(0.5, LightTheme.green[100]),
          
        },
      },
      'green-outlined': {
        ...buttonCommonStyles,
        background: 'transparent',
        border: darkMode
          ? `1px solid ${DarkTheme.green[100]}`
          : `1px solid ${LightTheme.green[100]}`,
        color: darkMode ? DarkTheme.green[100] : LightTheme.green[100],
        '&:hover': {
          background: darkMode ? DarkTheme.green[100] : LightTheme.green[100],
          border: darkMode
            ? `1px solid ${DarkTheme.green[100]}`
            : `1px solid ${LightTheme.green[100]}`,
          color: darkMode ? DarkTheme.white : LightTheme.white,
        },
        '&:disabled': {
          background: darkMode
            ? lighten(0.05, DarkTheme.green[100])
            : lighten(0.05, LightTheme.green[100]),
          border: darkMode
            ? `1px solid ${lighten(0.05, DarkTheme.green[100])}`
            : `1px solid ${lighten(0.05, LightTheme.green[100])}`,
          color: darkMode
            ? lighten(0.5, DarkTheme.green[100])
            : lighten(0.5, LightTheme.green[100]),
          
        },
      },
      red: {
        ...buttonCommonStyles,
        background: darkMode ? DarkTheme.red[200] : LightTheme.red[200],
        border: darkMode
          ? `1px solid ${DarkTheme.red[200]}`
          : `1px solid ${LightTheme.red[200]}`,
        color: darkMode ? DarkTheme.white : LightTheme.white,
        '&:hover': {
          background: darkMode
            ? lighten(0.1, DarkTheme.red[200])
            : lighten(0.1, LightTheme.red[200]),
          border: darkMode
            ? `1px solid ${lighten(0.1, DarkTheme.red[200])}`
            : `1px solid ${lighten(0.1, LightTheme.red[200])}`,
        },
        '&:disabled': {
          background: darkMode
            ? lighten(0.05, DarkTheme.red[200])
            : lighten(0.05, LightTheme.red[200]),
          border: darkMode
            ? `1px solid ${lighten(0.05, DarkTheme.red[200])}`
            : `1px solid ${lighten(0.05, LightTheme.red[200])}`,
          color: darkMode
            ? lighten(0.3, DarkTheme.red[200])
            : lighten(0.3, LightTheme.red[200]),
          
        },
      },
      'red-outlined': {
        ...buttonCommonStyles,
        background: 'transparent',
        border: darkMode
          ? `1px solid ${DarkTheme.red[200]}`
          : `1px solid ${LightTheme.red[200]}`,
        color: darkMode ? DarkTheme.red[200] : LightTheme.red[200],
        '&:hover': {
          background: darkMode ? DarkTheme.red[200] : LightTheme.red[200],
          border: darkMode
            ? `1px solid ${DarkTheme.red[200]}`
            : `1px solid ${LightTheme.red[200]}`,
          color: darkMode ? DarkTheme.white : LightTheme.white,
        },
        '&:disabled': {
          background: darkMode
            ? lighten(0.05, DarkTheme.red[200])
            : lighten(0.05, LightTheme.red[200]),
          border: darkMode
            ? `1px solid ${lighten(0.05, DarkTheme.red[200])}`
            : `1px solid ${lighten(0.05, LightTheme.red[200])}`,
          color: darkMode
            ? lighten(0.3, DarkTheme.red[200])
            : lighten(0.3, LightTheme.red[200]),
          
        },
      },
      blue: {
        ...buttonCommonStyles,
        background: darkMode ? DarkTheme.blue[200] : LightTheme.blue[200],
        border: darkMode
          ? `1px solid ${DarkTheme.blue[200]}`
          : `1px solid ${LightTheme.blue[200]}`,
        color: darkMode ? DarkTheme.white : LightTheme.white,
        '&:hover': {
          background: darkMode
            ? lighten(0.1, DarkTheme.blue[200])
            : lighten(0.1, LightTheme.blue[200]),
          border: darkMode
            ? `1px solid ${lighten(0.1, DarkTheme.blue[200])}`
            : `1px solid ${lighten(0.1, LightTheme.blue[200])}`,
        },
        '&:disabled': {
          background: darkMode
            ? lighten(0.05, DarkTheme.blue[200])
            : lighten(0.05, LightTheme.blue[200]),
          border: darkMode
            ? `1px solid ${lighten(0.05, DarkTheme.blue[200])}`
            : `1px solid ${lighten(0.05, LightTheme.blue[200])}`,
          color: darkMode
            ? lighten(0.3, DarkTheme.blue[200])
            : lighten(0.3, LightTheme.blue[200]),
          
        },
      },
      'blue-max': {
        ...buttonCommonStyles,
        fontSize: fontSizes[200],
        background: darkMode ? DarkTheme.blue[200] : LightTheme.blue[200],
        border: darkMode
          ? `1px solid ${DarkTheme.blue[200]}`
          : `1px solid ${LightTheme.blue[200]}`,
        color: darkMode ? DarkTheme.white : LightTheme.white,
        '&:hover': {
          background: darkMode
            ? lighten(0.1, DarkTheme.blue[200])
            : lighten(0.1, LightTheme.blue[200]),
          border: darkMode
            ? `1px solid ${lighten(0.1, DarkTheme.blue[200])}`
            : `1px solid ${lighten(0.1, LightTheme.blue[200])}`,
        },
        '&:disabled': {
          background: darkMode
            ? lighten(0.05, DarkTheme.blue[200])
            : lighten(0.05, LightTheme.blue[200]),
          border: darkMode
            ? `1px solid ${lighten(0.05, DarkTheme.blue[200])}`
            : `1px solid ${lighten(0.05, LightTheme.blue[200])}`,
          color: darkMode
            ? lighten(0.3, DarkTheme.blue[200])
            : lighten(0.3, LightTheme.blue[200]),
            
        },
      },
      'blue-outlined': {
        ...buttonCommonStyles,
        background: 'transparent',
        border: darkMode
          ? `1px solid ${DarkTheme.blue[200]}`
          : `1px solid ${LightTheme.blue[200]}`,
        color: darkMode ? DarkTheme.blue[200] : LightTheme.blue[200],
        '&:hover': {
          background: darkMode ? DarkTheme.blue[200] : LightTheme.blue[200],
          border: darkMode
            ? `1px solid ${DarkTheme.blue[200]}`
            : `1px solid ${LightTheme.blue[200]}`,
          color: darkMode ? DarkTheme.white : LightTheme.white,
        },
        '&:disabled': {
          background: darkMode
            ? lighten(0.05, DarkTheme.blue[200])
            : lighten(0.05, LightTheme.blue[200]),
          border: darkMode
            ? `1px solid ${lighten(0.05, DarkTheme.blue[200])}`
            : `1px solid ${lighten(0.05, LightTheme.blue[200])}`,
          color: darkMode
            ? lighten(0.3, DarkTheme.blue[200])
            : lighten(0.3, LightTheme.blue[200]),
          
        },
      },
      'white-outlined': {
        ...buttonCommonStyles,
        background: 'transparent',
        border: darkMode
          ? `1px solid ${DarkTheme.white}`
          : `1px solid ${LightTheme.white}`,
        color: darkMode ? DarkTheme.white : LightTheme.white,
        '&:hover': {
          opacity: 0.8,
        },
        '&:disabled': {
          opacity: 0.8,
          
        },
      },
      'grey-outlined': {
        ...buttonCommonStyles,
        background: 'transparent',
        border: darkMode
          ? `1px solid ${DarkTheme.blue}`
          : `1px solid ${LightTheme.grey[600]}`,
        color: darkMode ? DarkTheme.white : LightTheme.grey[600],
        '&.active': {
          background: darkMode ? DarkTheme.blue : LightTheme.blue[200],
          border: darkMode
            ? `1px solid ${DarkTheme.blue}`
            : `1px solid ${LightTheme.blue[200]}`,
          color: darkMode ? DarkTheme.white : LightTheme.white,
        },
        '&:hover': {
          background: darkMode ? DarkTheme.blue : LightTheme.blue[200],
          border: darkMode
            ? `1px solid ${DarkTheme.blue}`
            : `1px solid ${LightTheme.blue[200]}`,
          color: darkMode ? DarkTheme.white : LightTheme.white,
        },
        '&:disabled': {
          background: lighten(
            0.05,
            darkMode ? DarkTheme.blue[200] : LightTheme.grey[300],
          ),
          color: lighten(0.82, darkMode ? DarkTheme.white : LightTheme.black),
          border: `${rem('1px')} solid ${lighten(
            0.05,
            darkMode ? DarkTheme.blue[200] : LightTheme.grey[300],
          )}}`,
          
        },
      },
      'trade-long': {
        ...buttonCommonStyles,
        background: 'transparent',
        border: darkMode
          ? `1px solid ${DarkTheme.blue}`
          : `1px solid ${LightTheme.grey[100]}`,
        color: darkMode ? DarkTheme.white : LightTheme.grey[100],
        '&.active': {
          background: darkMode ? DarkTheme.green[100] : LightTheme.green[100],
          border: darkMode
            ? `1px solid ${DarkTheme.green[100]}`
            : `1px solid ${LightTheme.green[100]}`,
          color: darkMode ? DarkTheme.white : LightTheme.white,
        },
        '&:hover': {
          background: darkMode ? DarkTheme.green[100] : LightTheme.green[100],
          border: darkMode
            ? `1px solid ${DarkTheme.green[100]}`
            : `1px solid ${LightTheme.green[100]}`,
          color: darkMode ? DarkTheme.white : LightTheme.white,
        },
        '&:disabled': {
          background: lighten(
            0.05,
            darkMode ? DarkTheme.blue[200] : LightTheme.grey[300],
          ),
          color: lighten(0.82, darkMode ? DarkTheme.white : LightTheme.black),
          border: `${rem('1px')} solid ${lighten(
            0.05,
            darkMode ? DarkTheme.blue[200] : LightTheme.grey[300],
          )}}`,
          
        },
      },
      'trade-short': {
        ...buttonCommonStyles,
        background: 'transparent',
        border: darkMode
          ? `1px solid ${DarkTheme.blue}`
          : `1px solid ${LightTheme.grey[100]}`,
        color: darkMode ? DarkTheme.white : LightTheme.grey[100],
        '&.active': {
          color: darkMode ? DarkTheme.white : LightTheme.white,
          background: darkMode
            ? lighten(0.1, DarkTheme.red[200])
            : lighten(0.1, LightTheme.red[200]),
          border: darkMode
            ? `1px solid ${lighten(0.1, DarkTheme.red[200])}`
            : `1px solid ${lighten(0.1, LightTheme.red[200])}`,
        },
        '&:hover': {
          color: darkMode ? DarkTheme.white : LightTheme.white,
          background: darkMode
            ? lighten(0.1, DarkTheme.red[200])
            : lighten(0.1, LightTheme.red[200]),
          border: darkMode
            ? `1px solid ${lighten(0.1, DarkTheme.red[200])}`
            : `1px solid ${lighten(0.1, LightTheme.red[200])}`,
        },
        '&:disabled': {
          background: lighten(
            0.05,
            darkMode ? DarkTheme.blue[200] : LightTheme.grey[300],
          ),
          color: lighten(0.82, darkMode ? DarkTheme.white : LightTheme.black),
          border: `${rem('1px')} solid ${lighten(
            0.05,
            darkMode ? DarkTheme.blue[200] : LightTheme.grey[300],
          )}}`,
          
        },
      },
    },
  }
}

export const GlobalStyle = createGlobalStyle<any>`
  /* Font import */
  @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
  /* Local custom font - Gotham */
  @font-face {
    font-family: 'Gotham';
    src: url('fonts/Gotham-Medium.eot');
    src: url('fonts/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
        url('fonts/Gotham-Medium.woff2') format('woff2'),
        url('fonts/Gotham-Medium.woff') format('woff'),
        url('fonts/Gotham-Medium.ttf') format('truetype'),
        url('fonts/Gotham-Medium.svg#Gotham-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  /* Global styles bellow */

  html {
    font-family: ${({ fonts }) => fonts.body};
    font-size: 15px;
  }

  @media (min-width: ${({ breakpoints }) => breakpoints[1]}) {
    html {
      font-size: 15px;
    }
  }

  @media (min-width: ${({ breakpoints }) => breakpoints[2]}) {
    html {
      font-size: 16px;
    }
  }

  @media (min-width: ${({ breakpoints }) => breakpoints[3]}) {
    html {
      font-size: 16px;
    }
  }

  body {
    padding: 0;
    margin: 0;
    background: ${({ bodyBg }) => bodyBg};
    color: ${({ darkMode, colors }) => darkMode ? colors.white : colors.black};
  }

  #root {
    display: flex;
    flex-direction: column;
    line-height: ${({ lineHeights }) => lineHeights.body};
    margin: 0;
  }

  a {
    cursor: pointer;
    color: ${({ darkMode, colors }) => darkMode ? colors.white : colors.black};
    text-decoration: none;

    &:hover {
      color: ${
        ({ darkMode, colors }) => darkMode ? colors.blue[200] : colors.blue[200]
      };
    }
  }

  h1 {
    font-family: 'Gotham', sans-serif;
    font-weight: 'normal';
  }

  ${buttons('focus')} {
    outline: none;
  }

  .Toastify__toast-body {
    width: 100%;
  }

  .rc-slider-tooltip-inner {
    background-color: ${
      ({ darkMode, colors }) => darkMode ? colors.blue[200] : colors.blue[200]
    };
    border-radius: ${({ borderRadius }) => borderRadius};
  }

  .simplebar-scrollbar.simplebar-visible:before {
    background-color: ${({ colors }) => colors.blue[200]};
    opacity: 1;
  }

  @keyframes skeletonAnimation {
    0% { background-position: ${rem('-150px')}; }
    40%, 100% { background-position: ${rem('150px')}; } 
  }


  // rc-tooltip
  .rc-tooltip.rc-tooltip-zoom-appear,
  .rc-tooltip.rc-tooltip-zoom-enter {
    opacity: 0;
  }
  .rc-tooltip.rc-tooltip-zoom-enter,
  .rc-tooltip.rc-tooltip-zoom-leave {
    display: block;
  }
  .rc-tooltip-zoom-enter,
  .rc-tooltip-zoom-appear {
    opacity: 0;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
    animation-play-state: paused;
  }
  .rc-tooltip-zoom-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
    animation-play-state: paused;
  }
  .rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
  .rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
    animation-name: rcToolTipZoomIn;
    animation-play-state: running;
  }
  .rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
    animation-name: rcToolTipZoomOut;
    animation-play-state: running;
  }
  @keyframes rcToolTipZoomIn {
    0% {
      opacity: 0;
      transform-origin: 50% 50%;
      transform: scale(0, 0);
    }
    100% {
      opacity: 1;
      transform-origin: 50% 50%;
      transform: scale(1, 1);
    }
  }
  @keyframes rcToolTipZoomOut {
    0% {
      opacity: 1;
      transform-origin: 50% 50%;
      transform: scale(1, 1);
    }
    100% {
      opacity: 0;
      transform-origin: 50% 50%;
      transform: scale(0, 0);
    }
  }
  .rc-tooltip {
    position: absolute;
    z-index: 9999950;
    display: block;
    visibility: visible;
    font-size: ${({ fontSizes }) => fontSizes.sm};
  }
  .rc-tooltip-hidden {
    display: none;
  }
  .rc-tooltip-inner {
    padding: ${({ space }) => space.xs};
    color: ${({ colors }) => colors.blue[200]};
    text-align: left;
    line-height: 0.9125rem;
    text-decoration: none;
    background-color: ${({ colors }) => colors.grey[1000]};
    border-radius: ${({ borderRadius }) => borderRadius[4]};
    /* min-height: 34px; */
    border: 1px solid ${({ colors }) => colors.grey[1000]}};
  }
  .rc-tooltip-arrow,
  .rc-tooltip-arrow-inner {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  .rc-tooltip-placement-top .rc-tooltip-arrow,
  .rc-tooltip-placement-topLeft .rc-tooltip-arrow,
  .rc-tooltip-placement-topRight .rc-tooltip-arrow {
    bottom: -5px;
    margin-left: -6px;
    border-width: 6px 6px 0;
    border-top-color: ${({ colors }) => colors.grey[1000]};
  }
  .rc-tooltip-placement-top .rc-tooltip-arrow-inner,
  .rc-tooltip-placement-topLeft .rc-tooltip-arrow-inner,
  .rc-tooltip-placement-topRight .rc-tooltip-arrow-inner {
    bottom: 1px;
    margin-left: -6px;
    border-width: 6px 6px 0;
    border-top-color: #ffffff;
  }
  .rc-tooltip-placement-top .rc-tooltip-arrow {
    left: 50%;
  }
  .rc-tooltip-placement-topLeft .rc-tooltip-arrow {
    left: 15%;
  }
  .rc-tooltip-placement-topRight .rc-tooltip-arrow {
    right: 15%;
  }
  .rc-tooltip-placement-right .rc-tooltip-arrow,
  .rc-tooltip-placement-rightTop .rc-tooltip-arrow,
  .rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    left: -5px;
    margin-top: -6px;
    border-width: 6px 6px 6px 0;
    border-right-color: #b1b1b1;
  }
  .rc-tooltip-placement-right .rc-tooltip-arrow-inner,
  .rc-tooltip-placement-rightTop .rc-tooltip-arrow-inner,
  .rc-tooltip-placement-rightBottom .rc-tooltip-arrow-inner {
    left: 1px;
    margin-top: -6px;
    border-width: 6px 6px 6px 0;
    border-right-color: #ffffff;
  }
  .rc-tooltip-placement-right .rc-tooltip-arrow {
    top: 50%;
  }
  .rc-tooltip-placement-rightTop .rc-tooltip-arrow {
    top: 15%;
    margin-top: 0;
  }
  .rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    bottom: 15%;
  }
  .rc-tooltip-placement-left .rc-tooltip-arrow,
  .rc-tooltip-placement-leftTop .rc-tooltip-arrow,
  .rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    right: -5px;
    margin-top: -6px;
    border-width: 6px 0 6px 6px;
    border-left-color: #b1b1b1;
  }
  .rc-tooltip-placement-left .rc-tooltip-arrow-inner,
  .rc-tooltip-placement-leftTop .rc-tooltip-arrow-inner,
  .rc-tooltip-placement-leftBottom .rc-tooltip-arrow-inner {
    right: 1px;
    margin-top: -6px;
    border-width: 6px 0 6px 6px;
    border-left-color: #ffffff;
  }
  .rc-tooltip-placement-left .rc-tooltip-arrow {
    top: 50%;
  }
  .rc-tooltip-placement-leftTop .rc-tooltip-arrow {
    top: 15%;
    margin-top: 0;
  }
  .rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    bottom: 15%;
  }
  .rc-tooltip-placement-bottom .rc-tooltip-arrow,
  .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
  .rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    top: -5px;
    margin-left: -6px;
    border-width: 0 6px 6px;
    border-bottom-color: #b1b1b1;
  }
  .rc-tooltip-placement-bottom .rc-tooltip-arrow-inner,
  .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow-inner,
  .rc-tooltip-placement-bottomRight .rc-tooltip-arrow-inner {
    top: 1px;
    margin-left: -6px;
    border-width: 0 6px 6px;
    border-bottom-color: #ffffff;
  }
  .rc-tooltip-placement-bottom .rc-tooltip-arrow {
    left: 50%;
  }
  .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
    left: 15%;
  }
  .rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    right: 15%;
  }




  /* RWD with ZOOM hack */

  /* @media only screen and (orientation: landscape) and (min-width: 1601px) and (max-width: 99999999px)  {
    
    html {
      font-size: 16px;
    }
    #root {
      height: 100vh;
      zoom: 100%;
      -ms-zoom: 100%;
      -webkit-zoom: 100%;
    }
    #root-main-wrapper {
      height: 100vh;
    }
    #trade-bottom-wrapper {
      //height: 100vh;
    }
    #trade-bottom-main {
      //height: 100vh;
    }
    #trade-lower-right, #trade-upper-right {
      width: 720px;
    }
    [id^="tradingview_"] {
      zoom: 1.00;
      -ms-zoom: 1.00;
      -webkit-zoom: 1.00;
      transform: scale(1.00);
      -moz-transform: scale(1.00);
      transform-origin: 0 0;
      -moz-transform-origin: 0 0;
      -o-transform: scale(1.00);
      -o-transform-origin: 0 0;
      -webkit-transform: scale(1.00);
      -webkit-transform-origin: 0 0;
      width: 100%;
      height: 100%;
    } 
    .rc-tooltip {
      zoom: 100%;
      -ms-zoom: 100%;
      -webkit-zoom: 100%;
    }

  }

  @media only screen and (orientation: landscape) and (min-width: 1441px) and (max-width: 1600px)   {
    
    html {
      font-size: 16px;
    }
    #root {
      height: 111.11vh;
      zoom: 90%;
      -ms-zoom: 90%;
      -webkit-zoom: 90%;
    }
    #root-main-wrapper {
      height: 111.11vh;
    }
    #trade-bottom-wrapper {
      //height: 100vh;
    }
    #trade-bottom-main {
      //height: 60vh;
    }
    #trade-lower-right, #trade-upper-right {
      width: 660px;
    }
    [id^="tradingview_"] {
      zoom: 0.90;
      -ms-zoom: 0.90;
      -webkit-zoom: 0.90;
      transform: scale(0.90);
      -moz-transform: scale(0.90);
      transform-origin: 0 0;
      -moz-transform-origin: 0 0;
      -o-transform: scale(0.90);
      -o-transform-origin: 0 0;
      -webkit-transform: scale(0.90);
      -webkit-transform-origin: 0 0;
      width: 111.11% !important;
      height: 111.11% !important;
    } 
    .rc-tooltip {
      zoom: 90%;
      -ms-zoom: 90%;
      -webkit-zoom: 90%;
    }
    .tv-lightweight-charts canvas:nth-child(2) {
      //transform: scaleX(1.11112);
    }

  }
  
  @media only screen and (orientation: landscape) and (min-width: 1281px) and (max-width: 1440px)  {
    
    html {
      font-size: 18px;
      line-height: 1;
    }
    #root {
      height: 125vh;
      zoom: 80%;
      -ms-zoom: 80%;
      -webkit-zoom: 80%;
    }
    #root-main-wrapper {
      height: 125vh;    
    }
    #trade-bottom-wrapper {
      // height: 100vh;
    }
    #trade-bottom-main {
      max-height: 100%;
    }
    #trade-lower-right, #trade-upper-right {
      width: 680px;
    }
    [id^="tradingview_"] {
      zoom: 0.80;
      -ms-zoom: 0.80;
      -webkit-zoom: 0.80;
      transform: scale(0.80);
      -moz-transform: scale(0.80);
      transform-origin: 0 0;
      -moz-transform-origin: 0 0;
      -o-transform: scale(0.80);
      -o-transform-origin: 0 0;
      -webkit-transform: scale(0.80);
      -webkit-transform-origin: 0 0;
      width: 125.00% !important;
      height: 125.00% !important;
    } 
    .rc-tooltip {
      zoom: 80%;
      -ms-zoom: 80%;
      -webkit-zoom: 80%;
    }
    .tv-lightweight-charts canvas:nth-child(2) {
      //transform: scaleX(0.8);
    }

  }

  @media only screen and (orientation: landscape) and (min-width: 1025px) and (max-width: 1280px)  {
    
    html {
      font-size: 18px;
    }
    #root {
      height: 142.85vh;
      zoom: 70%;
      -ms-zoom: 70%;
      -webkit-zoom: 70%;
    }
    #root-main-wrapper {
      height: 142.85vh;
    }
    #trade-bottom-wrapper {
      // height: 100vh;
    }
    #trade-bottom-main {
      max-height: 100%;
    }
    #trade-lower-right, #trade-upper-right {
      width: 680px;
    }
    [id^="tradingview_"] {
      zoom: 0.70;
      -ms-zoom: 0.70;
      -webkit-zoom: 0.70;
      transform: scale(0.70);
      -moz-transform: scale(0.70);
      transform-origin: 0 0;
      -moz-transform-origin: 0 0;
      -o-transform: scale(0.70);
      -o-transform-origin: 0 0;
      -webkit-transform: scale(0.70);
      -webkit-transform-origin: 0 0;
      width: 142.85% !important;
      height: 142.85% !important;
    } 
    .rc-tooltip {
      zoom: 70%;
      -ms-zoom: 70%;
      -webkit-zoom: 70%;
    }

  }

  @media only screen and (orientation: landscape) and (min-width: 801px) and (max-width: 1024px)  {
    
    html {
      font-size: 18px;
    }
    #root {
      height: 166.66vh;
      zoom: 60%;
      -ms-zoom: 60%;
      -webkit-zoom: 60%;
    }
    #root-main-wrapper {
      height: 166.66vh;
    }
    #trade-bottom-wrapper {
      // height: 100vh;
    }
    #trade-bottom-main {
      max-height: 100%;
    }
    #trade-lower-right, #trade-upper-right {
      width: 700px;
    }
    [id^="tradingview_"] {
      zoom: 0.60;
      -ms-zoom: 0.60;
      -webkit-zoom: 0.60;
      transform: scale(0.60);
      -moz-transform: scale(0.60);
      transform-origin: 0 0;
      -moz-transform-origin: 0 0;
      -o-transform: scale(0.60);
      -o-transform-origin: 0 0;
      -webkit-transform: scale(0.60);
      -webkit-transform-origin: 0 0;
      width: 166.66% !important;
      height: 166.66% !important;
    } 
    .rc-tooltip {
      zoom: 60%;
      -ms-zoom: 60%;
      -webkit-zoom: 60%;
    }

  } */

`

export default useDefaultTheme
