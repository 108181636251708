import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
//import BigNumber from 'bignumber.js'
import { request } from 'graphql-request'
// Hooks
// Utils
import { QUERY_SUMMARY_STAT_INFOS } from '../queries'
import { SUBGRAPH_URL_KOVAN, DATE_FORMAT, NUMBER_FORMAT } from '../constants'

export const useSummaryStatInfos = (exchangeAddress: string, statType: number, statRange: number) => {
  const [active, setActive] = useState<boolean>(false)

  const { data, isLoading, refetch } = useQuery(`summaryStatInfos-${statType}-${statRange}-${exchangeAddress}`, async () => {
    //console.log('input 1 data exchangeAddress, statType, statRange', exchangeAddress, statType, statRange)
    const { summaryStatInfos } = await request(SUBGRAPH_URL_KOVAN, QUERY_SUMMARY_STAT_INFOS, {
      statType: statType, 
      statRange: statRange,
      lpAddr: exchangeAddress
    })
    //console.log('summaryStatInfos',summaryStatInfos)
    summaryStatInfos.reverse()

    return summaryStatInfos
  }, {
    enabled: active,
    //refetchInterval: 10000,
    initialData: () => {
      return []
    },
    onSuccess: (_data: any) => {
      /*
      console.group('🔎 useSummaryStatInfos GraphQL result')
      console.log('input data exchangeAddress, statType, statRange', exchangeAddress, statType, statRange)
      console.log(_data)
      console.groupEnd()
      */
    },
    onError: (_error: any) => {
      console.group('❌ useSummaryStatInfos GraphQL error')
      console.log(_error)
      console.groupEnd()
    }
  })

  useEffect(() => {
    if (!exchangeAddress) return
    setActive(true)
  }, [exchangeAddress])

  return {
    data,
    isLoading,
    refetch
  }
}

