import React from 'react'
import { Box, Text } from 'rebass/styled-components'
// Interfaces
import { CardProps } from './interfaces'

const Card: React.FC<CardProps> = ({ title, children, ...restprops }) => {
  return (
    <Box variant='card-primary'>
      {title && (
        <Box variant='card-title'>
          <Text fontSize='lg'>{title}</Text>
        </Box>
      )}
      <Box variant='card-body' {...restprops}>
        {children}
      </Box>
    </Box>
  )
}

export default Card
