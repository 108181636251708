import React from 'react'
import { Box, Text } from 'rebass/styled-components'
// Interfaces
import { ModalProps } from './interfaces'
// Styles
import { CloseIcon } from './index.css'

const Modal: React.FC<ModalProps> = ({
  title,
  width = 360,
  children,
  onDismiss,
  hideClose,
  ...restprops
}) => {
  return (
    <Box width={width} variant='card-primary' sx={{ zIndex: 1000 }}>
      {title && (
        <Box variant='card-title' sx={{ position: 'relative' }}>
          <Text textAlign='center' fontSize='lg' as='h1'>
            {title}
          </Text>
          {!hideClose && <CloseIcon onClick={onDismiss} cursor='pointer' />}
        </Box>
      )}
      <Box variant='card-body' {...restprops}>
        {React.isValidElement(children) &&
          React.cloneElement(children, {
            onDismiss
          })}
      </Box>
    </Box>
  )
}

export default Modal

export { Context as ModalContext } from './context'
