// read

/**
 * @notice get init margin ratio
 * @return init margin ratio in 18 digits
 */
export const initMarginRatio = async (
  SystemSettingsContract: any,
) => {
  return SystemSettingsContract.functions.initMarginRatio()
}

/**
 * @notice get maintenance margin ratio
 * @return maintenance margin ratio in 18 digits
 */
export const maintenanceMarginRatio = async (
  SystemSettingsContract: any,
) => {
  return SystemSettingsContract.functions.maintenanceMarginRatio()
}

/**
 * @notice get spread ratio
 * @return spread margin ratio in 18 digits
 */
export const spreadRatio = async (
  SystemSettingsContract: any,
) => {
  return SystemSettingsContract.functions.spreadRatio()
}

// interest rate
export const overnightFeeRatio = async (
  SystemSettingsContract: any,
) => {
  return SystemSettingsContract.functions.overnightFeeRatio()
}
