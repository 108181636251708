import React from 'react'
import { Label as LabelRebass, Input as InputRebass } from '@rebass/forms'
import { Flex } from 'rebass/styled-components'
import { rem } from 'polished'
// Hooks
import { useTheme } from '../../../../hooks'
// Interfaces
import { InputProps } from './interfaces'

const Input: React.FC<InputProps> = ({
  label,
  name,
  defaultValue,
  endAdornment,
  ...restprops
}) => {
  const { darkMode, colors, borderRadius, space, fontSizes } = useTheme()
  return (
    <Flex flexDirection='column'>
      {label && (
        <LabelRebass
          color={colors.grey[100]}
          fontSize={fontSizes[100]}
          mb={space.sm}
          htmlFor={name}
        >
          {label}
        </LabelRebass>
      )}
      <Flex
        flexDirection='row'
        alignItems='center'
        sx={{
          borderColor: darkMode ? colors.grey[600] : colors.grey[600],
          borderWidth: '1px',
          borderStyle: 'solid',
          borderRadius: borderRadius[4],
          transition: 'all ease-in-out 0.2s',
          '&:focus-within': {
            borderColor: darkMode ? colors.white : colors.black,
          },
        }}
      >
        <InputRebass
          flex={1}
          name={name}
          defaultValue={defaultValue}
          {...restprops}
          sx={{
            borderColor: 'transparent',
            borderRadius: borderRadius[4],
            borderWidth: '1px',
            paddingY: space.sm,
            paddingX: space.lg,
            fontSize: rem('20px'),
            '&:focus': {
              outlineStyle: 'none',
            },
          }}
        />
        {endAdornment && endAdornment}
      </Flex>
    </Flex>
  )
}

export const InputFormGroup: React.FC<InputProps> = ({
  label,
  name,
  defaultValue,
  endAdornment,
  ...restprops
}) => {
  const { darkMode, colors, borderRadius, space, fontSizes } = useTheme()
  return (
    <Flex flexDirection='column'>
      {label && (
        <LabelRebass
          color={colors.grey[100]}
          fontSize={fontSizes[100]}
          mb={space.sm}
          htmlFor={name}
        >
          {label}
        </LabelRebass>
      )}
      <Flex
        flexDirection='row'
        alignItems='center'
        sx={{
          borderColor: darkMode ? colors.grey[600] : colors.grey[600],
          borderWidth: '1px',
          borderStyle: 'solid',
          borderRadius: borderRadius[4],
        }}
      >
        <InputRebass
          name={name}
          defaultValue={defaultValue}
          {...restprops}
          sx={{
            borderColor: 'transparent',
            borderRadius: borderRadius[4],
            borderWidth: '1px',
            paddingY: '4px',
            paddingX: '8px',
            fontSize: rem('13px'),
            textAlign: 'center',
            color: darkMode ? colors.grey[100] : colors.grey[100],
            '&:focus': {
              outlineStyle: 'none',
            },
            '&::-webkit-input-placeholder': {
              color: darkMode ? colors.black : colors.grey[500],
              opacity: 1,
            },
            '&::-moz-placeholder': {
              color: darkMode ? colors.black : colors.grey[500],
              opacity: 1,
            },
            '&:-ms-input-placeholder': {
              color: darkMode ? colors.black : colors.grey[500],
              opacity: 1,
            },
            '&:-moz-placeholder': {
              color: darkMode ? colors.black : colors.grey[500],
              opacity: 1,
            },
          }}
        />
        {endAdornment && endAdornment}
      </Flex>
    </Flex>
  )
}

export default Input
