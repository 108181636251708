import { useCallback, useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
// Hooks
import { usePlatform } from '.'
import { useTokenContract } from './useContract'
import { useWeb3React } from '@web3-react/core'
// Utils
import { balanceOf } from '../utils/erc20'

const useBalanceOf = (tokenAddress: string): any => {
  const [balance, setBalance] = useState(new BigNumber(0))
  const tokenContract = useTokenContract(tokenAddress)  

  const { account } = useWeb3React()

  const fetchBalance = useCallback(async () => {
    try {
      const balance = await balanceOf(tokenContract, account)
      const newBalance = new BigNumber(balance).dividedBy(1e18)
      setBalance(newBalance)
    } catch (e) {
      console.log('useBalanceOf error', e)
    }
  }, [tokenContract, account, setBalance])

  useEffect(() => {
    if (!!account && tokenContract) {
      fetchBalance()
      const refreshInterval = setInterval(fetchBalance, 10000)
      return () => clearInterval(refreshInterval)
    }
  }, [account, tokenContract, fetchBalance, setBalance, tokenAddress])

  return balance
}

export default useBalanceOf
