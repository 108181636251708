import React from 'react'
import { Flex, Box, Text } from 'rebass/styled-components'
import { rem } from 'polished'
import { CheckCircle } from 'react-feather'
// Interfaces
import { WizardProps } from './interfaces'

const Wizard: React.FC<WizardProps> = ({ tabs, active, checked }) => {
  return (
    <Flex flexDirection='column'>
      <Box>
        <Flex alignItems='center'>
          {tabs?.map((tab, i) => (
            <Flex
              key={tab.id}
              flex={1}
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
            >
              <Box
                marginBottom='md'
                variant='wizard-title'
                className={
                  tab.id === active || checked.includes(tab.id) ? 'active' : ''
                }
              >
                <Text>{tab.title}</Text>
              </Box>
              <Box
                variant='wizard-number'
                className={
                  tab.id === active || checked.includes(tab.id) ? 'active' : ''
                }
              >
                {checked.includes(tab.id) ? (
                  <CheckCircle
                    size={rem('12px')}
                    style={{ marginLeft: '-1px' }}
                  />
                ) : (
                  <Text width='12px' textAlign='center'>
                    {i + 1}
                  </Text>
                )}
              </Box>
              <Box
                as='hr'
                variant='wizard-line'
                className={
                  tab.id === active || checked.includes(tab.id) ? 'active' : ''
                }
              />
            </Flex>
          ))}
        </Flex>
      </Box>
      {tabs?.map(
        tab =>
          tab.id === active && (
            <Flex
              key={tab.id}
              py='xxl'
              mt={rem('24px')}
              minHeight={rem('155px')}
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
            >
              {tab.content}
            </Flex>
          ),
      )}
      <Flex flexDirection='column'>
        {tabs?.map(tab => tab.id === active && tab.buttons)}
      </Flex>
    </Flex>
  )
}

export default Wizard
