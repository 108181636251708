import React, { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { request } from 'graphql-request'
import numeral from 'numeral'
import BigNumber from 'bignumber.js'
import moment from 'moment'
import _ from 'lodash'
// Hooks
import { useActiveWeb3React, useBalanceOf, usePlatform, useToastify } from '../hooks'
// Interfaces
import {
  PlatformContextProps,
  PlatformStateProps,
  ReducerActionProps,
  ContractListDataProps,
} from './interfaces'
// Constants
import { SUBGRAPH_URL_KOVAN, NUMBER_FORMAT } from '../constants'
import { NETWORK_CHAIN_ID } from '../connectors'
import { CONTRACT_LISTS, CONTRACT_DATA } from '../queries'
import { useWeb3React } from '@web3-react/core'
import { useTranslation } from 'react-i18next'

const initialState: PlatformStateProps = {
  walletModalOpen : false,
  contractLists: {
    isLoading: true,
    data: []
  },
  platformContractLists: {
    isLoading: true,
    data: {}
  },
  activeNav: 'trade',
  chainEnabled: false,
  activePair: {
    addr: undefined,
    id: null,
    name: null,
    poolId: null,
    type: null,
    contract: null,
    cfdContract: null,
    cfdVaultContract: null,
    quoteAssetAddr: null,
    quoteAssetName: null,
    liquidityVolume: null,
    tradingVolume: {
      long: null,
      short: null,
      total: null,
      last24hQuoteAsset: null,
      last24h: null
    },
    ammPrice: {
      decimal: null,
      bignumber: null
    },
    oraclePrice: {
      decimal: null,
      bignumber: null
    },
    oraclePriceTimestamp: null,
    sentiment: {
      long: null,
      short: null
    },
    volumeChange: null,
    priceHigh: null,
    priceLow: null,
    MMliquidityVolume: null,
    exchangeTotalSupply: null,
    lpTokenPrice: null,
    lpTokenPriceNoFee: null,
    totalLpLiquidity: null,
    totalLpUnrealizedPNL: null,
    totalLpFees: null
  },
  transactions: [],
  lastBlockNumber: 0,
  waitTxConfirm: false
}

const reducer = (
  state: PlatformStateProps,
  { type, payload }: ReducerActionProps,
) => {
  switch (type) {
    case 'UPDATE':
      return { ...state, ...payload }
    case 'UPDATE_BALANCE':
      return { ...state, balance: payload }
    case 'UPDATE_CONTRACT_LISTS':
      return { ...state, contractLists: payload }
    case 'UPDATE_PLATFORM_CONTRACT_LISTS':
      return { ...state, platformContractLists: payload }
    case 'UPDATE_ACTIVE_PAIR': 
      return { ...state, activePair: payload }
    case 'UPDATE_PLATFORM_EXCHANGES': {
      const newContractLists: ContractListDataProps[] = []
      for (let i = 0; i < payload.length; i+=1) {
        if (typeof state?.contractLists?.data?.find === 'function') {
          const exchangeState: any = state?.contractLists?.data?.find((contract: ContractListDataProps) => contract.addr === payload[i]?.address)
          newContractLists.push({
            ...exchangeState,
            contract: payload[i]?.exchangeContract,
            cfdContract: payload[i]?.cfdContract,
            cfdVaultContract: payload[i]?.cfdVaultContract,
            liquidityVolume: payload[i]?.liquidityVolume,
            tradingVolume: payload[i]?.tradingVolume,
            ammPrice: {
              decimal: numeral(payload[i]?.ammPrice?.toString()).format(NUMBER_FORMAT[2]),
              bignumber: payload[i]?.ammPrice
            },
            oraclePrice: {
              decimal: numeral(payload[i]?.oraclePrice?.toString()).format(NUMBER_FORMAT[2]),
              bignumber: payload[i]?.oraclePrice
            },
            sentiment: payload[i]?.sentiment,
            volumeChange: payload[i]?.volumeChange,
            priceHigh: payload[i]?.priceHigh,
            priceLow: payload[i]?.priceLow,
            MMliquidityVolume: payload[i]?.MMliquidityVolume,
            exchangeTotalSupply: payload[i]?.exchangeTotalSupply,
            lpTokenPrice: payload[i]?.lpTokenPrice,
            lpTokenPriceNoFee: payload[i]?.lpTokenPriceNoFee,
            totalLpLiquidity: payload[i]?.totalLpLiquidity,
            totalLpUnrealizedPNL: payload[i]?.totalLpUnrealizedPNL,
            totalLpFees: payload[i]?.totalLpFees
          })
        }
      }

      const { activePair }: any = state
      const newActivePair = newContractLists.find((contract: ContractListDataProps) => contract.addr === activePair.addr) ?? {}

      return {
        ...state,
        activePair: newActivePair,
        contractLists: {
          isLoading: false,
          data: newContractLists
        }
      }
    }
    default:
      return state
  }
}

export const PlatformContext = React.createContext<PlatformContextProps>({
  PlatformState: initialState,
})

const useGetContractData = () => {
  const { data, isLoading, refetch } = useQuery('get contracts', async () => {
    const { contractLists } = await request(SUBGRAPH_URL_KOVAN, CONTRACT_LISTS, {
      type: 1
    })
    const { contractDatas } = await request(SUBGRAPH_URL_KOVAN, CONTRACT_DATA)
   
    return contractLists.map((contract: any) => ({
      ...contract,
      ...contractDatas.find((contract2: any) => contract2.address === contract.addr)
    }))
  },
  {
    refetchInterval: 10000,
    onError: (_error: any) => {
      console.group('❌ useGetContractData error')
      console.log(_error)
      console.groupEnd()
    }
  })

  return React.useMemo(() => ({
      contractlistsData: data,
      contractListsIsLoading: isLoading,
      contractListRefetch: refetch
    }), [data, isLoading, refetch])
}

const useGetPlatformContractLists = () => {
  const { data, isLoading } = useQuery('platformContractLists', async () => {
    const { contractLists } = await request(SUBGRAPH_URL_KOVAN, CONTRACT_LISTS, {
      type: 0
    })
    // console.group('🔎 ContractLists GraphQL result')
    // console.log(contractLists)
    // console.groupEnd()
    return contractLists
  })

  return React.useMemo(() => ({
    platformContractListsData: data,
    platformContractListsIsLoading: isLoading,
  }), [data, isLoading])
}

export const shouldCheck = (
  lastBlockNumber: number,
  tx: { addedTime: number; receipt?: {}; lastCheckedBlockNumber?: number, confirmed: boolean },
): boolean  => {
  //console.log('shouldCheck lastBlockNumber', lastBlockNumber)
  //console.log('shouldCheck tx', tx)
  if (tx.receipt) return false
  if (tx.confirmed) return false
  if (!tx.lastCheckedBlockNumber) return true
  const blocksSinceCheck = lastBlockNumber - tx.lastCheckedBlockNumber
  if (blocksSinceCheck < 1) return false
  const minutesPending = (new Date().getTime() - tx.addedTime) / 1000 / 60
  if (minutesPending > 60) {
    // every 10 blocks if pending for longer than an hour
    return blocksSinceCheck > 9
  } else if (minutesPending > 5) {
    // every 3 blocks if pending more than 5 minutes
    return blocksSinceCheck > 2
  } else {
    // otherwise every block
    return true
  }
}


const PlatformContextProvider: React.FC = ({ children }) => {
  const { t } = useTranslation('platform')
  const [PlatformState, PlatformDispatch] = React.useReducer(
    reducer,
    initialState,
  )
  const { txToast } = useToastify()
  const { chainId, account } = useWeb3React()
  const { chainEnabled, lastBlockNumber, transactions } = PlatformState
  const { library } = useActiveWeb3React()
  const [lastBlockNumberChecked, setLastBlockNumberChecked] = useState(0)
  
  const addTransaction = useCallback(
    (tx: any) => {
      //console.log(transactions)
      //console.log('addTransaction', tx)
      const hash = tx.hash

      const txs = transactions ?? {}
      txs[hash] = { hash, from:account, addedTime: moment().unix(), confirmed: false }
      
      PlatformDispatch({
        type: 'UPDATE',
        payload: {
          transactions: txs
        },
      })
      PlatformDispatch({
        type: 'UPDATE',
        payload: {
          waitTxConfirm: true
        },
      })

      
      
    },
    [account, transactions],
  )
  const [txHashChecking, setTxHashChecking] = useState('')
  useEffect(() => {
    if (!chainId || !library || !lastBlockNumber || lastBlockNumberChecked >= lastBlockNumber) return
    setLastBlockNumberChecked(lastBlockNumber)
    //console.log('lastBlockNumber', lastBlockNumber)
    //console.log('lastBlockNumberChecked', lastBlockNumberChecked)
    Object.keys(transactions)
      .filter(hash => shouldCheck(lastBlockNumber, transactions[hash]))
      .forEach(hash => {
        if (hash === txHashChecking) return
        setTxHashChecking(hash)
        library
          .getTransactionReceipt(hash)
          .then(receipt => {
            console.log('receipt', receipt)
            if (receipt) {
              const txs = transactions
              txs[hash].confirmed = true
              txs[hash].receipt = {
                blockHash: receipt.blockHash,
                blockNumber: receipt.blockNumber,
                contractAddress: receipt.contractAddress,
                from: receipt.from,
                status: receipt.status,
                to: receipt.to,
                transactionHash: receipt.transactionHash,
                transactionIndex: receipt.transactionIndex,
              }
              
              PlatformDispatch({
                type: 'UPDATE',
                payload: {
                  transactions: txs
                },
              })
              PlatformDispatch({
                type: 'UPDATE',
                payload: {
                  waitTxConfirm: false
                },
              })

              
              txToast(t('message-txconfirmed'), receipt.transactionHash, 'success')
              
              setTxHashChecking('')
              
            } else {
              const txs = transactions
              txs[hash].lastCheckedBlockNumber = lastBlockNumber
              
              PlatformDispatch({
                type: 'UPDATE',
                payload: {
                  transactions: txs
                },
              })
              setTxHashChecking('')
            }
          })
          .catch(error => {
            console.error(`failed to check transaction hash: ${hash}`, error)
          })
      })
  }, [txHashChecking, setTxHashChecking, lastBlockNumberChecked, setLastBlockNumberChecked, txToast, chainId, library, transactions, lastBlockNumber, t])

  const blockNumberCallback = useCallback(
    (blockNumber: number) => {
      //console.log('blockNumberCallback', blockNumber)
      PlatformDispatch({
        type: 'UPDATE',
        payload: {
          lastBlockNumber: blockNumber
        },
      })
    },
    [],
  )

  // attach/detach listeners
  useEffect(() => {
    if (!library || !chainId) return undefined

    //setState({ chainId, blockNumber: null })
    try {
      library
        .getBlockNumber()
        .then(blockNumberCallback)
        .catch(error =>
          console.error(
            `Failed to get block number for chainId: ${chainId}`,
            error,
          ),
        )

      library.on('block', blockNumberCallback)
    } catch (e) {
      console.log('wat wat', e)
    }
    return () => {
      library.removeListener('block', blockNumberCallback)
    }
  }, [PlatformDispatch, chainId, library, blockNumberCallback])

  useEffect(() => {
    if (!chainId) return
    if (chainId === NETWORK_CHAIN_ID) {
      //console.log('chainEnabled true',chainId)
      PlatformDispatch({
        type: 'UPDATE',
        payload: {
          chainEnabled: true
        },
      })
    } else {
      //console.log('chainEnabled false',chainId)
      PlatformDispatch({
        type: 'UPDATE',
        payload: {
          chainEnabled: false
        },
      })
      //txToast('Wrong chain', '', 'error')
    }
  }, [PlatformDispatch, chainId])

  const { activePair } = PlatformState
  const { contractlistsData, contractListsIsLoading, contractListRefetch } = useGetContractData()
  
  React.useEffect(() => {
    // if (!contractlistsData || !chainEnabled) return
    if (!contractlistsData) return
    PlatformDispatch({
      type: 'UPDATE_CONTRACT_LISTS',
      payload: {
        isLoading: contractListsIsLoading,
        data: contractlistsData.map((contract: any) => ({
          name: contract.name,
          addr: contract.addr,
          quoteAssetName: contract.quoteAssetName,
          quoteAssetAddr: contract.quoteAssetAddr,
          address: contract.address,
          ammPrice: {
            bignumber: new BigNumber(contract.ammPrice).dividedBy(1e18),
            decimal: numeral(new BigNumber(contract.ammPrice).dividedBy(1e18)).format(NUMBER_FORMAT[2])
          },
          oraclePrice: {
            bignumber: new BigNumber(contract.oraclePrice).dividedBy(1e18),
            decimal: numeral(new BigNumber(contract.oraclePrice).dividedBy(1e18)).format(NUMBER_FORMAT[2])
          },
          oraclePriceTimestamp: Number(contract.oracleTimestamp),
          //liquidityVolume: new BigNumber(contract.liquidityVolume).dividedBy(1e18),
          liquidityVolume: new BigNumber(contract.totalLpLiquidity).plus(new BigNumber(contract.totalLpUnrealizedPnl)).plus(new BigNumber(contract.totalLpFeesAsync)).dividedBy(1e18),
          tradingVolume: {
            long:  new BigNumber(contract.tradingVolumeLong).dividedBy(1e18),
            short:  new BigNumber(contract.tradingVolumeShort).dividedBy(1e18),
            total:  new BigNumber(contract.tradingVolumeTotal).dividedBy(1e18),
            last24hQuoteAsset:  new BigNumber(contract.tradingVolume24hQuoteAsset).dividedBy(1e18),
            last24h:  new BigNumber(contract.tradingVolume24h).dividedBy(1e18)
          },
          sentiment: {
            long: (new BigNumber(contract.tradingVolumeLong).dividedBy(1e18)).dividedBy(new BigNumber(contract.tradingVolumeTotal).dividedBy(1e18)).multipliedBy(new BigNumber(100)),
            short: (new BigNumber(contract.tradingVolumeShort).dividedBy(1e18)).dividedBy(new BigNumber(contract.tradingVolumeTotal).dividedBy(1e18)).multipliedBy(new BigNumber(100))
          },
          volumeChange: ((new BigNumber(contract.volumeCurrent).dividedBy(1e18)).minus(new BigNumber(contract.volumeBefore).dividedBy(1e18))).dividedBy(new BigNumber(contract.volumeBefore).dividedBy(1e18)).multipliedBy(100),
          priceHigh: new BigNumber(contract.priceHigh).dividedBy(1e18),
          priceLow: new BigNumber(contract.priceLow).dividedBy(1e18),
          MMliquidityVolume: new BigNumber(contract.MMliquidityVolume).dividedBy(1e18),
          exchangeTotalSupply: new BigNumber(contract.exchangeTotalSupply).dividedBy(1e18),
          lpTokenPrice: new BigNumber(contract.lpTokenPriceWithFee).dividedBy(1e18),
          lpTokenPriceNoFee: new BigNumber(contract.lpTokenPrice).dividedBy(1e18),
          totalLpLiquidity: new BigNumber(contract.totalLpLiquidity).dividedBy(1e18),
          totalLpUnrealizedPNL: new BigNumber(contract.totalLpUnrealizedPNL).dividedBy(1e18),
          totalLpFees: new BigNumber(contract.totalLpFeesAsync).dividedBy(1e18)
        })),
      },
    })

    let contract: any = {}

    if (!activePair?.addr) {
      contract = _.first(contractlistsData)
    } else {
      contract = contractlistsData?.find((data: any) => data.addr === activePair?.addr)
    }

    if (!contract) {
      console.log('fetching contract error', contract)
      return
    }
    PlatformDispatch({
      type: 'UPDATE_ACTIVE_PAIR',
      payload: {
        name: contract.name,
        addr: contract.addr,
        quoteAssetName: contract.quoteAssetName,
        quoteAssetAddr: contract.quoteAssetAddr,
        address: contract.address,
        ammPrice: {
          bignumber: new BigNumber(contract.ammPrice).dividedBy(1e18),
          decimal: numeral(new BigNumber(contract.ammPrice).dividedBy(1e18)).format(NUMBER_FORMAT[2])
        },
        oraclePrice: {
          bignumber: new BigNumber(contract.oraclePrice).dividedBy(1e18),
          decimal: numeral(new BigNumber(contract.oraclePrice).dividedBy(1e18)).format(NUMBER_FORMAT[2])
        },
        oraclePriceTimestamp: Number(contract.oracleTimestamp),
        //liquidityVolume: new BigNumber(contract.liquidityVolume).dividedBy(1e18),
        liquidityVolume: new BigNumber(contract.totalLpLiquidity).plus(new BigNumber(contract.totalLpUnrealizedPnl)).plus(new BigNumber(contract.totalLpFeesAsync)).dividedBy(1e18),
        tradingVolume: {
          long:  new BigNumber(contract.tradingVolumeLong).dividedBy(1e18),
          short:  new BigNumber(contract.tradingVolumeShort).dividedBy(1e18),
          total:  new BigNumber(contract.tradingVolumeTotal).dividedBy(1e18),
          last24hQuoteAsset:  new BigNumber(contract.tradingVolume24hQuoteAsset).dividedBy(1e18),
          last24h:  new BigNumber(contract.tradingVolume24h).dividedBy(1e18)
        },
        sentiment: {
          long: (new BigNumber(contract.tradingVolumeLong).dividedBy(1e18)).dividedBy(new BigNumber(contract.tradingVolumeTotal).dividedBy(1e18)).multipliedBy(new BigNumber(100)),
          short: (new BigNumber(contract.tradingVolumeShort).dividedBy(1e18)).dividedBy(new BigNumber(contract.tradingVolumeTotal).dividedBy(1e18)).multipliedBy(new BigNumber(100))
        },
        volumeChange: ((new BigNumber(contract.volumeCurrent).dividedBy(1e18)).minus(new BigNumber(contract.volumeBefore).dividedBy(1e18))).dividedBy(new BigNumber(contract.volumeBefore).dividedBy(1e18)).multipliedBy(100),
        priceHigh: new BigNumber(contract.priceHigh).dividedBy(1e18),
        priceLow: new BigNumber(contract.priceLow).dividedBy(1e18),
        MMliquidityVolume: new BigNumber(contract.MMliquidityVolume).dividedBy(1e18),
        exchangeTotalSupply: new BigNumber(contract.exchangeTotalSupply).dividedBy(1e18),
        lpTokenPrice: new BigNumber(contract.lpTokenPriceWithFee).dividedBy(1e18),
        lpTokenPriceNoFee: new BigNumber(contract.lpTokenPrice).dividedBy(1e18),
        totalLpLiquidity: new BigNumber(contract.totalLpLiquidity).dividedBy(1e18),
        totalLpUnrealizedPNL: new BigNumber(contract.totalLpUnrealizedPnl).dividedBy(1e18),
        totalLpFees: new BigNumber(contract.totalLpFeesAsync).dividedBy(1e18)
      },
    })
  }, [contractlistsData, contractListsIsLoading, chainEnabled, activePair?.addr])

  const { platformContractListsData, platformContractListsIsLoading } = useGetPlatformContractLists()
  //console.log('platformContractListsData', platformContractListsData)

  React.useEffect(() => {
    if (!platformContractListsData) return
    // if (!chainEnabled){ 
    //   PlatformDispatch({
    //     type: 'UPDATE_CONTRACT_LISTS',
    //     payload: {
    //       isLoading: true,
    //       data: {},
    //     },
    //   })
    //   return
    // }
    PlatformDispatch({
      type: 'UPDATE_PLATFORM_CONTRACT_LISTS',
      payload: {
        isLoading: platformContractListsIsLoading,
        data: {
          proxyAdmin: platformContractListsData.find((contract:ContractListDataProps) => contract.name === 'proxyAdmin')?.addr,
          exchange: platformContractListsData.find((contract:ContractListDataProps) => contract.name === 'exchange')?.addr,
          exchangeReader: platformContractListsData.find((contract:ContractListDataProps) => contract.name === 'exchangeReader')?.addr,
          quoteAsset: platformContractListsData.find((contract:ContractListDataProps) => contract.name === 'quoteAsset')?.addr,
          BSCPriceFeed: platformContractListsData.find((contract:ContractListDataProps) => contract.name === 'BSCPriceFeed')?.addr,
          L2PriceFeed: platformContractListsData.find((contract:ContractListDataProps) => contract.name === 'L2PriceFeed')?.addr,
          InsuranceFund: platformContractListsData.find((contract:ContractListDataProps) => contract.name === 'InsuranceFund')?.addr,
          CFD: platformContractListsData.find((contract:ContractListDataProps) => contract.name === 'CFD')?.addr,
          CFDVault: platformContractListsData.find((contract:ContractListDataProps) => contract.name === 'CFDVault')?.addr,
          CFDState: platformContractListsData.find((contract:ContractListDataProps) => contract.name === 'CFDState')?.addr,
          systemSettings: platformContractListsData.find((contract:ContractListDataProps) => contract.name === 'systemSettings')?.addr,
          CFDViewer: platformContractListsData.find((contract:ContractListDataProps) => contract.name === 'cfdViewer')?.addr,
        },
      },
    })
  }, [platformContractListsData, platformContractListsIsLoading, chainEnabled])

  
  // console.log('activePair', activePair)
  const balance = useBalanceOf(activePair?.quoteAssetAddr)
  const liquidityBalance = useBalanceOf(activePair?.addr)
  //console.log('liquidityBalance', liquidityBalance?.toString())
  React.useEffect(() => {
    if (!activePair || !balance) return
    PlatformDispatch({
      type: 'UPDATE_BALANCE',
      payload: {
        value: {
          decimal: numeral(balance?.toString()).format(NUMBER_FORMAT[2]),
          bigNumber: balance.toString()
        },
        tokenSymbol: activePair?.quoteAssetName,
        tokenAddress: activePair?.quoteAssetAddr,
        liquidityValue: {
          decimal: numeral(liquidityBalance?.toString()).format(NUMBER_FORMAT[2]),
          bigNumber: liquidityBalance.toString()
        },
      }
    })
  }, [activePair, balance, liquidityBalance])

  return (
    <PlatformContext.Provider
      value={{
        PlatformState,
        PlatformDispatch,
        ContractListRefetch: contractListRefetch,
        addTransaction: addTransaction
      }}
    >
      {children}
    </PlatformContext.Provider>
  )
}

export default PlatformContextProvider
