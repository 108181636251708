export const ENDPOINTS: {
  [x: number]: any
} = {
  42: {
    "url": "http://161.117.180.132:8100/subgraphs/name/cfdswap/cfdswap-subgraph",
    "statistics_url": "http://161.117.180.132:8103/graphql",
    "ws": "ws://161.117.180.132:8103/graphql",
    "frontend_api": "https://frontend-api.sakeswap.finance/graphql",
    "backend_api": "https://ethtest-sakeperp-faucet.sakeswap.finance/graphql"
  },
  97: {
    "url": "http://161.117.180.132:8000/subgraphs/name/cfdswap/cfdswap-subgraph",
    "statistics_url": "http://161.117.180.132:8003/graphql",
    "ws": "ws://161.117.180.132:8003/graphql",
    "frontend_api": "https://frontend-api.sakeswap.finance/graphql",
    "backend_api": "https://bsctest-sakeperp-faucet.sakeswap.finance/graphql"
  }
}

export const INFURA = {
  "networks": [
    "https://mainnet.infura.io/v3/3ae175771bf6401e99ad7749a1cdb6d3",
    "https://mainnet.infura.io/v3/cd72c111190044a7af63b7266f4ae0bb",
    "https://mainnet.infura.io/v3/6571738fe8e44df489b8a24d986ae078"
  ]
}
