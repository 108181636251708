import { useCallback } from 'react'

import { BigNumber } from '@ethersproject/bignumber'

import { openPosition } from '../utils/cfd'
import { usePlatform, useCfd } from '.'

const useOpenPosition = () => {
  const { cfdContract, account } = useCfd()
  
  const { PlatformState: { activePair } } = usePlatform()

  const handleOpenPosition = useCallback(
    async (
      side: any,
      quoteAssetAmount: any,
      leverage: any,
      baseAssetAmountLimit: any,
    ) => {
      if (cfdContract && account && activePair?.addr) {
        try {
          const tx = await openPosition(
            cfdContract,
            activePair?.addr,
            BigNumber.from(side),
            BigNumber.from(quoteAssetAmount),
            BigNumber.from(leverage),
            BigNumber.from(baseAssetAmountLimit),
          )
          return tx
        } catch (e) {
          return e
        }
      }
    },
    [cfdContract, account, activePair],
  )

  return { onOpenPosition: handleOpenPosition }
}

export default useOpenPosition
