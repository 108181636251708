import { ChainId, JSBI, Percent, Token, WETH } from '../sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'

import { NETWORK_CHAIN_ID } from '../connectors'
import { ENDPOINTS } from './endpoints'

import {
  fortmatic,
  injected,
  portis,
  walletconnect,
  walletlink,
} from '../connectors'

export let ENVIRONMENT: string
if (process.env.REACT_APP_ENV) ENVIRONMENT = process.env.REACT_APP_ENV
else ENVIRONMENT = 'development'

export const TradeType = {
  LONG: 'LONG',
  SHORT: 'SHORT',
}

export const TradeTypeContract: {
  [key: string]: string
} = {
  LONG: '0',
  SHORT: '1',
}

export const MarginType = {
  ADD: 'add',
  REMOVE: 'remove'
}

export const DefaultSlippageTolerance = {
  value: '0.001',
  activeButton: 1,
}

export const LiquidityType = {
  ADD_LIQUIDITY: 'addLiquidity',
  REMOVE_LIQUIDITY: 'removeLiquidity',
}
const EXCHANGE_URLS: {
  [key: string]: string
} = {
  development: 'https://exchange.ekas.x0.si',
  production: 'https://sakeswap.finance',
}

export const EXCHANGE_BASE_URL: string = EXCHANGE_URLS[ENVIRONMENT]

export const TradingInfo = {
  POSITIONS: 'positions',
  TRADING_HISTORY: 'tradingHistory',
  TRADE: 'trade',
  LIQUIDITY: 'liquidity',
  ORDER_BOOK: 'orderBook',
  LIQUIDITY_HISTORY: 'liquidityHistory',
}

export const MarketListsInfo = {
  ETH_LIST: 'ethList',
  BSC_LIST: 'bscList',
  HECO_LIST: 'hecoList',
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true,
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true,
  },
  WALLET_LINK: {
    connector: walletlink,
    name: 'Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5',
  },
  COINBASE_LINK: {
    name: 'Open in Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Open in Coinbase Wallet app.',
    href: 'https://go.cb-w.com/mtUDhEZPy1',
    color: '#315CF5',
    mobile: true,
    mobileOnly: true,
  },
  FORTMATIC: {
    connector: fortmatic,
    name: 'Fortmatic',
    iconName: 'fortmaticIcon.png',
    description: 'Login using Fortmatic hosted wallet',
    href: null,
    color: '#6748FF',
    mobile: true,
  },
  Portis: {
    connector: portis,
    name: 'Portis',
    iconName: 'portisIcon.png',
    description: 'Login using Portis hosted wallet',
    href: null,
    color: '#4A6C9B',
    mobile: true,
  },
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 500
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.ROPSTEN]: [WETH[ChainId.ROPSTEN]],
  [ChainId.RINKEBY]: [WETH[ChainId.RINKEBY]],
  [ChainId.GÖRLI]: [WETH[ChainId.GÖRLI]],
  [ChainId.KOVAN]: [WETH[ChainId.KOVAN]],
  [ChainId.BSCMAIN]: [WETH[ChainId.KOVAN]],
  [ChainId.BSCTEST]: [WETH[ChainId.KOVAN]],
}

export const SAKE = new Token(
  ChainId.MAINNET,
  '0x066798d9ef0833ccc719076Dab77199eCbd178b0',
  18,
  'SAKE',
  'SakeSwap',
)
// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], SAKE],
}

export const PINNED_PAIRS: {
  readonly [chainId in ChainId]?: [Token, Token][]
} = {
  [ChainId.MAINNET]: [],
}

export const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
  [ChainId.MAINNET]: null,
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Görli',
  [ChainId.KOVAN]: 'Kovan',
  [ChainId.BSCMAIN]: 'BSCMain',
  [ChainId.BSCTEST]: 'BSCTest',
}

export const SUBGRAPH_URL_KOVAN =
  ENDPOINTS[NETWORK_CHAIN_ID]?.url

export const SUBGRAPH_URL_STASTICS_KOVAN =
  ENDPOINTS[NETWORK_CHAIN_ID]?.statistics_url
  
export const DATE_FORMAT = 'YYYY/MM/DD HH:mm'

export const NUMBER_FORMAT = {
  '0': '0,0',
  '2': '0,0.00',
  '3': '0,0.000',
  '4': '0,0.0000'
}

export const BASIC_MULTIPLIER = 1e18

export const SUBGRAPH_URL_WS =
  ENDPOINTS[NETWORK_CHAIN_ID]?.ws

export const ACTIVE_NAV_STRINGS: {
  [x: string]: string
} = {
  HOME: 'home',
  TRADE: 'trade',
  LIQUIDITY: 'liquidity',
  LEADERBOARD: 'leaderboard'
}

export const APY_VALUE: {
  [x: string]: string
} = {
  // 'all-time' | '1m' | '1w' | '1d'
  'ALL_TIME': 'all-time',
  '1MONTH': '1m',
  '1WEEK': '1w',
  '1DAY': '1d'
}

export const LP_VALUE: {
  [x: string]: string
} = {
  'WITH_FEE': 'with_fee',
  'WITHOUT_FEE': 'without_fee',
}

export const TOTAL_LIQUIDITY_VALUE: {
  [x: string]: string
} = {
  'WITH_FEE': 'with_fee',
  'WITHOUT_FEE': 'without_fee',
}

export const FRONTEND_API_URL = ENDPOINTS[NETWORK_CHAIN_ID]?.frontend_api
export const BACKEND_API_URL = ENDPOINTS[NETWORK_CHAIN_ID]?.backend_api
