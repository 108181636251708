import React from 'react'
import { Flex, Box, Text } from 'rebass/styled-components'
// import { Scrollbars } from 'react-custom-scrollbars'
import SimpleBar from 'simplebar-react'
import _ from 'lodash'
// Components
import { Spacer, Loader } from '../../'
import { ChevronDown, ChevronUp } from 'react-feather'
// iterfaces
import { TableProps, TableColumnProps } from './interfaces'
import { rem } from 'polished'

export const RenderThumb: React.FC = props => (
  <Box variant='scrollbar-thumb' {...props} />
)

const SORT_DIRECTION: {
  [x: string]: 'asc' | 'desc'
} = {
  ASC: 'asc',
  DESC: 'desc'
}

const Table: React.FC<TableProps> = ({
  columns,
  dataSource,
  loading,
  scrollbarsStyle,
  tableHeaderStyle,
  tableHeaderVariant,
  sortable,
  defaultSortHeader = '',
  ...restprops
}) => {
  const [defaultWidth, setDefaultWidth] = React.useState<number>(0)
  const [sortHeader, setSortHeader] = React.useState<string | undefined>(columns[0]?.sortBy)
  const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>(SORT_DIRECTION.ASC)

  React.useEffect(() => {
    if (!columns.length) return
    setDefaultWidth(100 / columns.length)
  }, [columns.length])

  React.useEffect(() => {
    if (!defaultSortHeader) return
    setSortHeader(defaultSortHeader)
  }, [defaultSortHeader])
  
  return (
    <Flex variant='table'>
      <Flex variant={tableHeaderVariant ? tableHeaderVariant : 'table-header'} {...tableHeaderStyle}>
        {columns &&
          columns.map((col: TableColumnProps) => (
            <Box
              key={col.key}
              flex={col.width ? `1 1 ${col.width}` : `1 1 ${defaultWidth}%`}
            >
              {sortable ? 
                <Flex flexDirection='row' alignItems='center' justifyContent={!col.align ? 'flex-start' : col.align === 'left' ? 'flex-start' : col.align === 'right' ? 'flex-end' : 'center'}>
                  {col?.hideSort?
                    <Text as='span'>
                      {col.title}
                    </Text> :
                    <>
                      <Text
                        as='span'
                        onClick={() => {
                          setSortHeader(col?.sortBy)
                          setSortDirection(SORT_DIRECTION.ASC)
                        }}
                        sx={{ cursor: !col?.hideSort ? 'pointer' : 'default' }}
                      >
                        {col.title}
                      </Text>
                      {sortHeader === col?.sortBy && <>{sortDirection === SORT_DIRECTION.ASC ? 
                        <Text as='span' onClick={() => setSortDirection(SORT_DIRECTION.DESC)} sx={{ cursor: 'pointer' }}ml='3px'><ChevronUp height={rem('13px')} width={rem('13px')} /></Text> : 
                        <Text as='span' onClick={() => setSortDirection(SORT_DIRECTION.ASC)} sx={{ cursor: 'pointer' }} ml='3px'><ChevronDown  height={rem('13px')} width={rem('13px')} /></Text>}</>}
                    </>
                  }
                </Flex>
                : 
                <Text textAlign={col.align}>{col.title}</Text>
              }
            </Box>
          ))}
      </Flex>
      <SimpleBar style={{ ...scrollbarsStyle }}>
        {loading && 
          <Flex variant='loading-wrapper' justifyContent='center' alignItems='center' height='100%'>
            <Loader />
          </Flex>
        }
        <Box {...restprops}>
          {/* <Scrollbars
            renderThumbHorizontal={RenderThumb}
            renderThumbVertical={RenderThumb}
            style={{ ...scrollbarsStyle }}
          > */}
            <Spacer size='sm' />
            <Flex
              flexDirection='column'
              sx={{ position: 'relative' }}
              // overflowY='scroll'
            >
              {dataSource?.length > 0 &&
                _.orderBy(dataSource, [sortHeader], [sortDirection])?.map((source: any, i: number) => _.isArray(source) ? (
                  source?.map((data: any) => !data?.hide && (
                    <Flex
                      key={data?.key}
                      variant={i % 2 ? 'table-row-odd' : 'table-row-even'}
                    >
                      {columns &&
                        columns?.map((col: TableColumnProps) => (
                          <Box
                            key={col?.key}
                            flex={
                              col?.width
                                ? `1 1 ${col?.width}`
                                : `1 1 ${defaultWidth}%`
                            }
                          >
                            {col?.Render ? (
                              col?.Render(data, i)
                            ) : (
                              <Text textAlign={col.align}>
                                {data[col?.dataIndex]}
                              </Text>
                            )}
                          </Box>
                        ))}
                    </Flex>
                  ))
                ) : !source?.hide && (
                  <Flex
                    key={source?.key}
                    variant={i % 2 ? 'table-row-odd' : 'table-row-even'}
                  >
                    {columns &&
                      columns.map((col: TableColumnProps) => (
                        <Box
                          key={col?.key}
                          flex={
                            col?.width
                              ? `1 1 ${col?.width}`
                              : `1 1 ${defaultWidth}%`
                          }
                        >
                          {col?.Render ? (
                            col?.Render(source, i)
                          ) : (
                            <Text textAlign={col?.align}>
                              {source?.[col?.dataIndex]}
                            </Text>
                          )}
                        </Box>
                      ))}
                  </Flex>
                ))}
            </Flex>
          {/* </Scrollbars> */}
        </Box>
      </SimpleBar>
    </Flex>
  )
}

export default Table
