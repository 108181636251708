import { useCallback } from 'react'

import BigNumber from 'bignumber.js'
import { BigNumber as bb } from '@ethersproject/bignumber'

import { removeLiquidity } from '../utils/cfd-vault'
import { usePlatform, useCfd } from '.'

const useRemoveLiquidity = () => {
  const { cfdVaultContract, account } = useCfd()
  
  const { PlatformState: { activePair } } = usePlatform()

  const handleRemoveLiquidity = useCallback(
    async (
      lpTokenAmount: any,
    ) => {
      if (cfdVaultContract && account && activePair?.addr) {
        const lpTokenAmountRaw = new BigNumber(lpTokenAmount).multipliedBy(1e18)
        try {
          const tx = await removeLiquidity(
            cfdVaultContract,
            activePair?.addr,
            bb.from(lpTokenAmountRaw.toFixed(0))
          )
          return tx
        } catch (e) {
          return e
        }
      }
    },
    [cfdVaultContract, account, activePair],
  )

  return { onRemoveLiquidity: handleRemoveLiquidity }
}

export default useRemoveLiquidity
