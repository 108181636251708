import { useCallback } from 'react'

import BigNumber from 'bignumber.js'
import { BigNumber as bb } from '@ethersproject/bignumber'

import { addLiquidity } from '../utils/cfd-vault'
import { usePlatform, useCfd } from '.'

const useAddLiquidity = () => {
  const { cfdVaultContract, account } = useCfd()
  
  const { PlatformState: { activePair } } = usePlatform()

  const handleAddLiquidity = useCallback(
    async (
      quoteAssetAmount: any,
    ) => {
      if (cfdVaultContract && account && activePair?.addr) {
        const quoteAssetAmountRaw = new BigNumber(quoteAssetAmount).multipliedBy(1e18)
        try {
          const tx = await addLiquidity(
            cfdVaultContract,
            activePair?.addr,
            bb.from(quoteAssetAmountRaw.toFixed(0))
          )
          return tx
        } catch (e) {
          return e
        }
      }
    },
    [cfdVaultContract, account, activePair],
  )

  return { onAddLiquidity: handleAddLiquidity }
}

export default useAddLiquidity
