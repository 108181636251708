import './i18n'
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { Reset } from 'styled-reset'
// Components
import App from './App'
import { Loader } from './theme/ui'
// Styles
import { FallbackWrapper } from './index.css'
import 'react-toastify/dist/ReactToastify.css'
import 'rc-slider/assets/index.css'
import 'simplebar/dist/simplebar.min.css'
import useDefaultTheme, { GlobalStyle } from './theme'
import { NetworkContextName } from './constants'
import ApplicationUpdater from './state/application/updater'
import ListsUpdater from './state/lists/updater'
import MulticallUpdater from './state/multicall/updater'
//import TransactionUpdater from './state/transactions/updater'
import UserUpdater from './state/user/updater'
import store from './state'
import getLibrary from './utils/getLibrary'
import { ColorContextProvider } from './contexts'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

if ('ethereum' in window) {
  // eslint-disable-next-line @typescript-eslint/no-extra-semi
  ;(window.ethereum as any).autoRefreshOnNetworkChange = false
}

const Fallback: React.FC = () => (
  <FallbackWrapper>
    <Loader />
  </FallbackWrapper>
)

function Updaters() {
  return (
    <>
      <ListsUpdater />
      <UserUpdater />
      <ApplicationUpdater />
      <MulticallUpdater />
    </>
  )
}

const queryClient = new QueryClient()

const IndexWithTheme: React.FC = () => {
  const theme = useDefaultTheme()
  return (
    <ThemeProvider theme={theme}>
      <Reset />
      <GlobalStyle {...theme} />
      <App />
    </ThemeProvider>
  )
}

const Index: React.FC = () => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <QueryClientProvider client={queryClient}>
          <ColorContextProvider>
            <Provider store={store}>
              <Updaters />
              <React.Suspense fallback={<Fallback />}>
                <IndexWithTheme />
              </React.Suspense>
            </Provider>
          </ColorContextProvider>
          {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
        </QueryClientProvider>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  )
}

ReactDOM.render(
  <Index />,
  document.getElementById('root'),
)
