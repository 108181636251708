import React from 'react'
import numeral from 'numeral'
import BigNumber from 'bignumber.js'
import _ from 'lodash'
import { usePlatform } from '.'
import { ContractListDataProps } from '../contexts/interfaces'
import { useQuery, UseQueryResult } from 'react-query'
import { request } from 'graphql-request'
import { CONTRACT_LISTS, CONTRACT_DATA } from '../queries'
import { ENDPOINTS } from '../constants/endpoints'

const useGetMarkets = (): {
  data: {
    key: number | string
    asset: {
      tradeAsset: string
      quoteAsset: string
      address: string
    },
    price: string
    change: string
    volume: string
    liquidity: string
    sentiment: {
      long: number
      short: number
    },
  }[]
  isLoading: boolean
} => {
  const { PlatformState: { contractLists: { isLoading, data: exchanges } } } = usePlatform()
  
  return {
    isLoading: isLoading,
    data: React.useMemo(() => {
      if (!exchanges || typeof exchanges.map !== 'function') return []
      return exchanges.map((exchange: ContractListDataProps) => ({
        key: exchange.addr,
        asset: {
          tradeAsset: exchange.name?.split('/')[0],
          quoteAsset: exchange.quoteAssetName,
          address: exchange.addr
        },
        price: numeral(exchange?.ammPrice?.bignumber).value(),
        change: numeral(exchange.volumeChange).value(),
        volume: numeral(exchange?.tradingVolume?.last24hQuoteAsset).value(),
        liquidity: numeral(exchange.liquidityVolume).value(),
        sentiment: {
          long: exchange?.sentiment?.long?.toFixed(0) || 0,
          short: exchange?.sentiment?.short?.toFixed(0) || 0
        }
      }))

    }, [exchanges])
  }
}

export const useGetOtherMarkets = (chainId: any): {
  data: {
    key: number | string
    asset: {
      tradeAsset: string
      quoteAsset: string
      address: string
    },
    price: string
    change: string
    volume: string
    liquidity: string
    sentiment: {
      long: number
      short: number
    },
  }[]
  isLoading: boolean
} => {
  
  const { data: exchanges, isLoading } = useQuery('get other markets', async () => {
    const { contractLists } = await request(ENDPOINTS[chainId].url, CONTRACT_LISTS, {
      type: 1
    })
    const { contractDatas } = await request(ENDPOINTS[chainId].url, CONTRACT_DATA)
   
    return contractLists.map((contract: any) => ({
      ...contract,
      ...contractDatas.find((contract2: any) => contract2.address === contract.addr)
    }))
  },
  {
    refetchInterval: 10000,
    onError: (_error: any) => {
      console.group('❌ useGetOtherMarkets error')
      console.log(_error)
      console.groupEnd()
    }
  })

  return {
    isLoading: isLoading,
    data: React.useMemo(() => {
      if (!exchanges || typeof exchanges.map !== 'function') return []
      return exchanges.map((exchange: any) => ({
        key: exchange.addr,
        asset: {
          tradeAsset: exchange.name?.split('/')[0],
          quoteAsset: exchange.quoteAssetName,
          address: exchange.addr
        },
        price: numeral(new BigNumber(exchange.ammPrice).dividedBy(1e18)).value(),
        change: numeral(((new BigNumber(exchange.volumeCurrent).dividedBy(1e18)).minus(new BigNumber(exchange.volumeBefore).dividedBy(1e18))).dividedBy(new BigNumber(exchange.volumeBefore).dividedBy(1e18)).multipliedBy(100)).value(),
        volume: numeral(new BigNumber(exchange.tradingVolume24hQuoteAsset).dividedBy(1e18)).value(),
        liquidity: numeral(new BigNumber(exchange.liquidityVolume).dividedBy(1e18)).value(),
        sentiment: {
          long: (new BigNumber(exchange.tradingVolumeLong).dividedBy(1e18)).dividedBy(new BigNumber(exchange.tradingVolumeTotal).dividedBy(1e18)).multipliedBy(new BigNumber(100)).toFixed(0) || 0,
          short: (new BigNumber(exchange.tradingVolumeShort).dividedBy(1e18)).dividedBy(new BigNumber(exchange.tradingVolumeTotal).dividedBy(1e18)).multipliedBy(new BigNumber(100)).toFixed(0) || 0
        }
      }))

    }, [exchanges])
  }
}

export const useGetMarketCards = (): UseQueryResult<any, any> => {
  const result = useQuery('get market cards', async () => {
    const { contractLists: bscl } = await request(ENDPOINTS[97].url, CONTRACT_LISTS, {
      type: 1
    })
    const { contractLists: ethl } = await request(ENDPOINTS[42].url, CONTRACT_LISTS, {
      type: 1
    })
    const { contractDatas: bscd } = await request(ENDPOINTS[97].url, CONTRACT_DATA)
    const { contractDatas: ethd } = await request(ENDPOINTS[42].url, CONTRACT_DATA)

    const bscdata = bscd.map((exchange: any) => ({
      ...exchange,
      ...bscl.find((exchange2: any) => exchange2.addr === exchange.address),
      chainId: 97
    }))
    const ethdata = ethd.map((exchange: any) => ({
      ...exchange,
      ...ethl.find((exchange2: any) => exchange2.addr === exchange.address),
      chainId: 42
    }))

    const parsed = [...bscdata, ...ethdata].map((exchange: any, index: number) => ({
      key: index,
      name: exchange.name,
      price: new BigNumber(exchange.ammPrice).gt(0) ? numeral(new BigNumber(exchange.ammPrice).dividedBy(1e18)).value() : 0,
      change: new BigNumber(exchange.volumeCurrent).gt(0) ? numeral(((new BigNumber(exchange.volumeCurrent).dividedBy(1e18)).minus(new BigNumber(exchange.volumeBefore).dividedBy(1e18))).dividedBy(new BigNumber(exchange.volumeBefore).dividedBy(1e18)).multipliedBy(100)).value() : 0,
      volume: new BigNumber(exchange.tradingVolume24hQuoteAsset).gt(0) ? numeral(new BigNumber(exchange.tradingVolume24hQuoteAsset).dividedBy(1e18)).value() : 0,
      chainId: exchange.chainId
    }))

    return _.orderBy(parsed, [(data: any) => data.volume], ['desc']).slice(0, 3)
  },
  {
    refetchInterval: 100000,
    onError: (_error: any) => {
      console.group('❌ useGetMarketCards error')
      console.log(_error)
      console.groupEnd()
    }
  })
  return result
}

export default useGetMarkets