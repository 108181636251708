import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from './walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { PortisConnector } from '@web3-react/portis-connector'

import { FortmaticConnector } from './fortmatic'
import { NetworkConnector } from './network-connector'
import { INFURA } from '../constants/endpoints'

const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY
const PORTIS_ID = process.env.REACT_APP_PORTIS_ID


// 97 bsc testnet
// 42 kovan
// 256 heco testnet
export let NETWORK_CHAIN_ID = 97
if (process.env.REACT_APP_CHAIN_ID) NETWORK_CHAIN_ID = parseInt(process.env.REACT_APP_CHAIN_ID)

//export const NETWORK_CHAIN_ID: number = parseInt('42')
if (typeof INFURA === 'undefined') {
  throw new Error(
    `REACT_APP_NETWORK_URL must be a defined environment variable`,
  )
}
const randomNetwork = Math.floor(Math.random() * INFURA.networks?.length)
//console.log('NETWORK_URLS.length', NETWORK_URLS?.networks?.length)
//console.log('randomNetwork', randomNetwork)
export let NETWORK_URL = INFURA ? INFURA.networks[randomNetwork] : ''

if (NETWORK_CHAIN_ID === 56)
  NETWORK_URL = 'https://bsc-dataseed.binance.org/'
else if (NETWORK_CHAIN_ID === 97)
  NETWORK_URL = 'https://data-seed-prebsc-1-s1.binance.org:8545/'
//console.log('NETWORK_URL', NETWORK_URL)
if (typeof NETWORK_URL === 'undefined' || NETWORK_URL === '') {
  throw new Error(
    `REACT_APP_NETWORK_URL must be a defined environment variable`,
  )
}

export const network = new NetworkConnector({
  urls: { [NETWORK_CHAIN_ID]: NETWORK_URL },
})

class myWall extends WalletConnectConnector {
  constructor() {
    super({
      rpc: { 1: NETWORK_URL },
      bridge: 'https://bridge.walletconnect.org',
      qrcode: true,
      pollingInterval: 15000,
    })
    console.log('rpc', this.rpc)
    /*
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id: 1,
        jsonrpc: '2.0',
        method: 'eth_blockNumber',
        params: [],
      }),
    }
    const response = fetch(NETWORK_URL, requestOptions).then(data => {
      console.log('resprrrr', data)
      if (data?.status === 429) {
        this.rpc = {
          1: 'https://mainnet.infura.io/v3/cd72c111190044a7af63b7266f4ae0bb',
        }
        network = new NetworkConnector({
          urls: { [NETWORK_CHAIN_ID]: NETWORK_URL },
        })
        //walletconnect = new myWall()
        //networkReady = true
        console.log('rpc', this.rpc)
      }
    })
    */
  }

  setRpc() {}
}

// mainnet only
export const walletconnect = new myWall()

export const networkReady = true
/*
try {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      id: 1,
      jsonrpc: '2.0',
      method: 'eth_blockNumber',
      params: [],
    }),
  }
  const response = fetch(NETWORK_URL, requestOptions)
    .then(data => {
      console.log('resp', data)
      if (data?.status === 429) {
        NETWORK_URL =
          'https://mainnet.infura.io/v3/cd72c111190044a7af63b7266f4ae0bb'
        network = new NetworkConnector({
          urls: { [NETWORK_CHAIN_ID]: NETWORK_URL },
        })
        //walletconnect = new myWall()
        networkReady = true
      }
    })
    .catch(error => {
      console.error('There was an error!', error)
    })
} catch (error) {
  console.error('error', error)
}
*/
let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary =
    networkLibrary ?? new Web3Provider(network.provider as any))
}

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 56, 97],
})

// mainnet only
export const fortmatic = new FortmaticConnector({
  apiKey: FORMATIC_KEY ?? '',
  chainId: 1,
})

// mainnet only
export const portis = new PortisConnector({
  dAppId: PORTIS_ID ?? '',
  networks: [1],
})

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: NETWORK_URL,
  appName: 'qpro',
  appLogoUrl:
    'https://mpng.pngfly.com/20181202/bex/kisspng-emoji-domain-unicorn-pin-badges-sticker-unicorn-tumblr-emoji-unicorn-iphoneemoji-5c046729264a77.5671679315437924251569.jpg',
})
