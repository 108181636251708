import { useCallback } from 'react'

import BigNumber from 'bignumber.js'
import { BigNumber as bb } from '@ethersproject/bignumber'

import { closePosition } from '../utils/cfd'
import { usePlatform, useCfd } from '.'

const useClosePosition = (exhangeAddress:string) => {
  const { cfdContract, account } = useCfd()
  
  //const { PlatformState: { activePair } } = usePlatform()

  const handleClosePosition = useCallback(
    async (
      quoteAssetAmountLimit: any,
    ) => {
      //console.log('quoteAssetAmountLimit', quoteAssetAmountLimit)
      if (cfdContract && account && exhangeAddress) {
        const quoteAssetAmountLimitRaw = new BigNumber(quoteAssetAmountLimit).multipliedBy(1e18)
        //console.log('quoteAssetAmountLimitRaw', quoteAssetAmountLimitRaw.toFixed(0))
        try {
          /*
          console.group('⁉ handleClosePosition data')
          console.log('cfdContract', cfdContract)
          console.log('exhangeAddress', exhangeAddress)
          console.log('quoteAssetAmountLimitRaw', quoteAssetAmountLimitRaw.toFixed(0))
          console.groupEnd()
          */
          const tx = await closePosition(
            cfdContract,
            exhangeAddress,
            bb.from(quoteAssetAmountLimitRaw.toFixed(0)),
          )
          return tx
        } catch (e) {
          return e
        }
      }
    },
    [cfdContract, account, exhangeAddress],
  )

  return { onClosePosition: handleClosePosition }
}

export default useClosePosition
