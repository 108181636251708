
enum PnlCalcOption {
  SPOT_PRICE,
  TWAP,
}
/**
 * @notice cfdViewerContract stats
 * @param cfdViewerContract contract
 * @param account account Address
 * @param exchangeAddress exchangeAddress Address
 * @return getUnrealizedPnl
 * 
 */
export const getUnrealizedPnl = async (
  cfdViewerContract: any,
  account: any,
  exchangeAddress: any,
) => {
  return cfdViewerContract.functions.getUnrealizedPnl(
    exchangeAddress,
    account,
    PnlCalcOption.SPOT_PRICE,
  )
}

export const getPersonalPositionWithFundingPayment = async (
  cfdViewerContract: any,
  account: any,
  exchangeAddress: any,
) => {
  return cfdViewerContract.functions.getPersonalPositionWithFundingPayment(
    exchangeAddress,
    account,
  )
}

export const getMarginRatio = async (
  cfdViewerContract: any,
  account: any,
  exchangeAddress: any,
) => {
  return cfdViewerContract.functions.getMarginRatio(
    exchangeAddress,
    account,
  )
}
