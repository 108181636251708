import { useContext } from 'react'

import { PlatformContext } from '../contexts/platform'

const usePlatform = (): any => {
  const platform = useContext(PlatformContext)
  return platform
}

export default usePlatform
