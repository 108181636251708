//import CFD from '../constants/abis/cfd.json'
//import BigNumber from 'bignumber.js'
//import { BigNumber } from '@ethersproject/bignumber'
import { calculateGasMargin } from './common-contract'
// read

/**
 * @notice get margin ratio, marginRatio = (margin + funding payments + unrealized Pnl) / openNotional
 * use spot and twap price to calculate unrealized Pnl, final unrealized Pnl depends on which one is higher
 * @param _exchange IExchange address
 * @param _trader trader address
 * @return margin ratio in 18 digits
 */
export const getMarginRatio = async (
  CFDContract: any,
  account: string,
  exchange: string,
) => {
  return CFDContract.functions.getMarginRatio(exchange, account)
}

// Liquidity Volumn
export const getLiquidityVolume = async (
  CFDContract: any,
  exchange: string,
) => {
  return CFDContract.functions.getMMLiquidity(exchange)
}

/**
 * @notice get personal position information, and adjust size if migration is necessary
 * @param _exchange IExchange address
 * @param _trader trader address
 * @return struct Position
 * 
  struct Position {
    SignedDecimal.signedDecimal size;
    Decimal.decimal margin;
    Decimal.decimal openNotional;
    SignedDecimal.signedDecimal lastUpdatedCumulativePremiumFraction;
    Decimal.decimal lastUpdatedCumulativeOvernightFeeRate;
    uint256 liquidityHistoryIndex;
    uint256 blockNumber;
  }
 */
export const getPosition = async (
  CFDContract: any,
  account: string,
  exchange: string,
) => {
  return CFDContract.functions.getPosition(exchange, account)
}

// getMMLiquidity
export const getMMLiquidity = async (
  CFDContract: any,
  exchange: string,
) => {
  return CFDContract.functions.getMMLiquidity(exchange)
}

/**
 * @notice get position notional and unrealized Pnl without fee expense and funding payment
 * @param _exchange IExchange address
 * @param _trader trader address
 * @param _pnlCalcOption enum PnlCalcOption
    enum PnlCalcOption {SPOT_PRICE, TWAP}
 * @return positionNotional position notional
 * @return unrealizedPnl unrealized Pnl
*/
export const getPositionNotionalAndUnrealizedPnl = async (
  CFDContract: any,
  account: string,
  exchange: string,
  PnlCalcOption: any,
) => {
  return CFDContract.functions.getPositionNotionalAndUnrealizedPnl(
    exchange,
    account,
    PnlCalcOption,
  )
}

// write

/**
 * @notice add margin to increase margin ratio
 * @param _exchange IExchange address
 * @param _addedMargin added margin in 18 digits
 */
export const addMargin = async (
  CFDContract: any,
  exchange: string,
  addedMargin: any,
) => {
  console.log('addMargin addedMargin', addedMargin.toString())
  let useExact = false
  const estimatedGas = await CFDContract.estimateGas
    .addMargin(exchange, [addedMargin])
    .catch((e: any) => {
      console.log('e', e)
      // general fallback for tokens who restrict approval amounts
      useExact = true
      return CFDContract.estimateGas.addMargin(exchange, [addedMargin])
    })

  console.log('addMargin estimatedGas', estimatedGas.toString())
  console.log(
    'addMargin calculateGasMargin(estimatedGas)',
    calculateGasMargin(estimatedGas),
  )
  return CFDContract.addMargin(exchange, [addedMargin], {
    gasLimit: calculateGasMargin(estimatedGas),
  })
    .then((response: any) => {
      console.log(response)
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to addMargin', error)
      throw error
    })
}

/**
 * @notice remove margin to decrease margin ratio
 * @param _exchange IExchange address
 * @param _removedMargin removed margin in 18 digits
 */
export const removeMargin = async (
  CFDContract: any,
  exchange: string,
  removedMargin: any,
) => {
  let useExact = false
  const estimatedGas = await CFDContract.estimateGas
    .removeMargin(exchange, [removedMargin])
    .catch((e: any) => {
      console.log('e', e)
      // general fallback for tokens who restrict approval amounts
      useExact = true
      return CFDContract.estimateGas.removeMargin(exchange, [removedMargin])
    })

  return CFDContract.removeMargin(exchange, [removedMargin], {
    gasLimit: calculateGasMargin(estimatedGas),
  })
    .then((response: any) => {
      console.log(response)
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to removeMargin', error)
      throw error
    })
}

/**
 * @notice open a position
 * @param _exchange exchange address
 * @param _side enum Side; BUY for long and SELL for short
 * 

    enum Side {BUY, SELL}
 * @param _quoteAssetAmount quote asset amount in 18 digits. Can Not be 0
 * @param _leverage leverage in 18 digits. Can Not be 0
 * @param _baseAssetAmountLimit minimum base asset amount expected to get to prevent from slippage.
 */
export const openPosition = async (
  CFDContract: any,
  exchange: string,
  side: any,
  quoteAssetAmount: any,
  leverage: any,
  baseAssetAmountLimit: any,
) => {
  console.log('openPosition exchange', exchange)
  console.log('openPosition side', side)
  console.log('openPosition quoteAssetAmount', quoteAssetAmount)
  console.log('openPosition leverage', leverage)
  console.log('openPosition baseAssetAmountLimit', baseAssetAmountLimit)
  let useExact = false
  const estimatedGas = await CFDContract.estimateGas
    .openPosition(
      exchange,
      side,
      [quoteAssetAmount],
      [leverage],
      [baseAssetAmountLimit],
    )
    .catch((e: any) => {
      console.log('e', e)
      // general fallback for tokens who restrict approval amounts
      useExact = true
      return CFDContract.estimateGas.openPosition(
        exchange,
        side,
        [quoteAssetAmount],
        [leverage],
        [baseAssetAmountLimit],
      )
    })

  console.log('openPosition estimatedGas', estimatedGas.toString())
  console.log(
    'openPosition calculateGasMargin(estimatedGas)',
    calculateGasMargin(estimatedGas),
  )
  return CFDContract.openPosition(
    exchange,
    side,
    [quoteAssetAmount],
    [leverage],
    [baseAssetAmountLimit],
    {
      gasLimit: calculateGasMargin(estimatedGas),
    },
  )
    .then((response: any) => {
      console.log('openPosition', response)
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to openPosition', error)
      throw error
    })
}

/**
 * @notice close all the positions
 * @param _exchange IExchange address
 */
export const closePosition = async (
  CFDContract: any,
  exchange: string,
  quoteAssetAmountLimit: any,
) => {
  let useExact = false
/*
  console.group('⁉ closePosition utils data')
  console.log('CFDContract', CFDContract)
  console.log('exchange', exchange)
  console.log('quoteAssetAmountLimit <- going to contract call', quoteAssetAmountLimit)
  console.log('quoteAssetAmountLimit.toString()', quoteAssetAmountLimit.toString())
  console.groupEnd()
  */
  const estimatedGas = await CFDContract.estimateGas
    .closePosition(exchange, [quoteAssetAmountLimit])
    .catch((e: any) => {
      console.log('e', e)
      // general fallback for tokens who restrict approval amounts
      useExact = true
      return CFDContract.estimateGas.closePosition(exchange, [
        quoteAssetAmountLimit,
      ])
    })

  return CFDContract.closePosition(exchange, [quoteAssetAmountLimit], {
    gasLimit: calculateGasMargin(estimatedGas),
  })
    .then((response: any) => {
      console.log('closePosition', response)
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to closePosition', error)
      throw error
    })
}
