import React from 'react'
import { useQuery } from 'react-query'
import { request } from 'graphql-request'
import moment from 'moment'
import BigNumber from 'bignumber.js'
import numeral from 'numeral'
// Contants
import { POSITION_CHANGED_EVENTS_ALL_TRADES } from '../queries'
import { SUBGRAPH_URL_KOVAN, DATE_FORMAT, NUMBER_FORMAT } from '../constants'
// Contexts
import { PlatformContext } from '../contexts'
// Utils
import { bnToDec } from '../utils'

interface PositionChangedEventResponse {
  id: string
  exchangedPositionSize: string
  positionNotional: string
  timestamp: string
}

const useGetAllTrades = (): {
  allTrades: any
  allTradesIsLoading: boolean,
  allTradesIsFetching: boolean,
  allTradesRefetch: any
} => { 
  const { PlatformState } = React.useContext(PlatformContext)
  const { activePair, chainEnabled } = PlatformState
  const [active, setActive] = React.useState<boolean>(false)

  const { data, isLoading, isFetching, refetch } = useQuery('allTrades', async () => {
    if(!activePair?.addr) return
    const { positionChangedEvents } = await request(SUBGRAPH_URL_KOVAN, POSITION_CHANGED_EVENTS_ALL_TRADES, {
      first: 100, 
      orderBy: 'timestamp', 
      orderDirection: 'desc',
      amm: activePair?.addr
    })
    return positionChangedEvents
  }, {
    enabled: active,
    refetchInterval: 10000,
    onSuccess: (_data: any) => {
      /*
      console.group('🔎 GetAllTrades GraphQL result')
      console.log(_data)
      console.groupEnd()
      */
    },
    onError: (_error: any) => {
      console.group('❌ GetAllTrades GraphQL error')
      console.log(_error)
      console.groupEnd()
    }
  })

  React.useEffect(() => {
    if (!activePair) return
    // if (!chainEnabled) {
    //   setActive(false)
    //   return
    // }
    setActive(true)
    refetch()
  }, [activePair, refetch, chainEnabled])

  return {
    allTrades: data?.map((position: PositionChangedEventResponse) => ({
      key: position.id,
      size: numeral(bnToDec(new BigNumber(position.exchangedPositionSize))).format(NUMBER_FORMAT[4]),
      price: numeral((new BigNumber(position.positionNotional).dividedBy(1e18).abs()).dividedBy(new BigNumber(position.exchangedPositionSize).dividedBy(1e18).abs())).format(NUMBER_FORMAT[2]),
      time: moment.unix(Number(position.timestamp)).format(DATE_FORMAT)
    })),
    allTradesIsLoading: isLoading,
    allTradesIsFetching: isFetching,
    allTradesRefetch: refetch
  }
}

export default useGetAllTrades