import React from 'react'
// import numeral from 'numeral'
import BigNumber from 'bignumber.js'
import _ from 'lodash'
import { useQuery, UseQueryResult } from 'react-query'
import { request } from 'graphql-request'
import { COMPETITION_RANKING } from '../queries'
import { BACKEND_API_URL } from '../constants'

interface competitionRanking {
  id: number | string
  address: string
  pnl: BigNumber
  roi: BigNumber
  faucetAmount: BigNumber
}

export const useGetLeaderboard = (): {
  data: {
    key: number | string
    rank: number
    address: string
    roi: number | BigNumber
    pnl: number | BigNumber
    faucetAmount: number | BigNumber
  }[]
  isLoading: boolean
} => {
  
  const { data, isLoading }: UseQueryResult<any, any> = useQuery('get other markets', async () => {
    const { getCompetitionRanking }: {
      getCompetitionRanking: competitionRanking[]
    } = await request(BACKEND_API_URL, COMPETITION_RANKING)
    
    return getCompetitionRanking.map((rank: competitionRanking) => ({
      id: rank.id,
      address: rank.address,
      roi: new BigNumber(rank.roi).multipliedBy(100),
      pnl: new BigNumber(rank.pnl).dividedBy(1e18),
      faucetAmount: new BigNumber(rank.faucetAmount).dividedBy(1e18) 
    })).slice(0, 100)
  },
  {
    refetchInterval: 60000,
    initialData: [],
    onError: (_error: any) => {
      console.group('❌ useGetLeaderboard error')
      console.log(_error)
      console.groupEnd()
    }
  })

  return {
    isLoading: isLoading,
    data: React.useMemo(() => {
      return data.length > 0 ? data.map((trader: any, index: number) => ({
        key: trader.id,
        rank: index + 1,
        address: trader.address,
        roi: trader.roi,
        pnl: trader.pnl,
        faucetAmount: trader.faucetAmount 
      })) : []
    }, [data])
  }
}

export const useGetLeaderboardMeta = (): UseQueryResult<any, any> => {
  const result: UseQueryResult<any, any> = useQuery('get leaderboard meta', async () => {
    const { getCompetitionRanking }: {
      getCompetitionRanking: competitionRanking[]
    } = await request(BACKEND_API_URL, COMPETITION_RANKING)

    let faucetPayouts = new BigNumber(0)

    for (let i = 0; i < getCompetitionRanking.length; i+=1) {
      faucetPayouts = faucetPayouts.plus(new BigNumber(getCompetitionRanking[i].faucetAmount).dividedBy(1e18))
    }

    return {
      totalTraders: getCompetitionRanking.length,
      faucetPayouts
    }
  },
  {
    refetchInterval: 60000,
    initialData: {
      totalTraders: 0,
      faucetPayouts: new BigNumber(0)
    },
    onError: (_error: any) => {
      console.group('❌ useGetLeaderboardMeta error')
      console.log(_error)
      console.groupEnd()
    }
  })

  return result
}

export default useGetLeaderboard