import { useMediaQuery } from 'react-responsive'

export const useMediaQueries = (): {
  isPlus1600: boolean
  isPlus1440: boolean
  isPlus1280: boolean
  isPlus1024: boolean
  isPlus800: boolean
} => {
  const isPlus1600 = useMediaQuery({ query: '(min-width: 1600px)' })
  const isPlus1440 = useMediaQuery({ query: '(min-width: 1440px)' })
  const isPlus1280 = useMediaQuery({ query: '(min-width: 1280px)' })
  const isPlus1024 = useMediaQuery({ query: '(min-width: 1024px)' })
  const isPlus800 = useMediaQuery({ query: '(min-width: 800px)' })
  
  return {
    isPlus1600,
    isPlus1440,
    isPlus1280,
    isPlus1024,
    isPlus800
  }
}