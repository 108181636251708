import React from 'react'
import { Flex, Box } from 'rebass/styled-components'
// Hooks
import { useTheme, useMediaQueries } from '../../../../hooks'
import { CloseIcon } from '../drawer/index.css'
// Interfaces
import { DrawerContextProps } from './interfaces'

export const Context = React.createContext<DrawerContextProps>({
  onPresent: () => null,
  onDismiss: () => null,
})

const DrawerContext: React.FC = ({ children }) => {
  const { modal, variants } = useTheme()
  const [left, setLeft] = React.useState<string>(variants.drawer.left)
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [content, setContent] = React.useState<React.ReactNode>()
  const [translateX, setTranslateX] = React.useState<number>(360)
  const { isPlus1600, isPlus1440, isPlus1280, isPlus1024, isPlus800 } = useMediaQueries()

  // React.useEffect(() => {
  //   if(isPlus1600) setTranslateX(360)
  //   if(isPlus1440) setTranslateX(360)
  //   if(isPlus1280) setTranslateX(410)
  //   if(isPlus1024) setTranslateX(410)
  //   if(isPlus800) setTranslateX(410)
  // }, [isPlus1600, isPlus1440, isPlus1280, isPlus1024, isPlus800])

  const handlePresent = React.useCallback(
    (modalContent: React.ReactNode) => {
      setContent(modalContent)
      setLeft('0')
      setIsOpen(true)
    },
    [setContent],
  )

  const handleDismiss = React.useCallback(() => {
    setContent(undefined)
    setLeft(variants.drawer.left)
    setIsOpen(false)
  }, [variants.drawer.left])

  return (
    <Context.Provider
      value={{
        content,
        left,
        isOpen,
        onPresent: handlePresent,
        onDismiss: handleDismiss,
      }}
    >
      {children}
      <Flex
        alignItems='center'
        justifyContent='center'
        sx={{
          visibility: isOpen ? 'visible' : 'hidden',
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 997,
        }}
      >
        <Box
          onClick={handleDismiss}
          backgroundColor={modal.backdrop}
          sx={{
            position: 'absolute',
            backdropFilter: 'blur(2px)',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        />
      </Flex>
      <Flex
        variant='drawer'
        sx={{
          transform: isOpen ? `translateX(${translateX}px)` : 'translateX(0)',
        }}
      >
        <CloseIcon cursor='pointer' onClick={handleDismiss} />
        {React.isValidElement(content) && 
          React.cloneElement(content, {
            onDismiss: handleDismiss,
          })}
      </Flex>
    </Context.Provider>
  )
}

export default DrawerContext
