import { calculateGasMargin } from './common-contract'
import BigNumber from 'bignumber.js'

// Read
export const balanceOf = async (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  tokenContract: any,
  account: string | null | undefined,
): Promise<any> => {
  return tokenContract.functions.balanceOf(account)
}

export const allowance = async (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  tokenContract: any,
  account: string | null | undefined,
  contractAddress: string | null | undefined,
): Promise<any> => {
  return tokenContract.functions.allowance(account, contractAddress)
}

// Write
/**
 * @notice approve exchange
 * @param tokenContract token Contract
 * @param exchangeAddress exchange address
 */
export const approve = async (
  tokenContract: any,
  exchangeAddress: string,
) => {
  const maxUint256 = new BigNumber(2).pow(255).toFixed()
  console.log('maxUint256', maxUint256)
  let useExact = false
  const estimatedGas = await tokenContract.estimateGas
    .approve(exchangeAddress, maxUint256)
    .catch((e: any) => {
      console.log('e', e)
      // general fallback for tokens who restrict approval amounts
      useExact = true
      return tokenContract.estimateGas.approve(exchangeAddress, maxUint256)
    })

  return tokenContract.approve(exchangeAddress, maxUint256, {
    gasLimit: calculateGasMargin(estimatedGas),
  })
    .then((response: any) => {
      console.log('approve', response)
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to approve', error)
      throw error
    })
}
