import React from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { Flex, Box } from 'rebass/styled-components'
import { ToastContainer } from 'react-toastify';
// Hooks
import { useTranslation } from 'react-i18next'
// Providers
import { ModalsProvider, DrawerProvider } from './theme/ui'
import Cfd from './contexts/cfd'
import { PlatformContextProvider } from './contexts'
// Interfaces
import { AppRoutes } from './interfaces'
// Contexts
import { RouteContext } from './contexts/route'
import Web3ReactManager from './components/web3-react-manager'
import { NETWORK_CHAIN_ID } from './connectors'
// Components
// import Ui from './pages/ui'
// import Home from './pages/home'
// import Trade from './pages/trade'
import { Loader } from './theme/ui'
const Ui: React.FC = React.lazy(() => import('./pages/ui'))
const Home: React.FC = React.lazy(() => import('./pages/home'))
const Trade: React.FC = React.lazy(() => import('./pages/trade'))
const Liquidity: React.FC = React.lazy(() => import('./pages/liquidity'))
const Leaderboard: React.FC = React.lazy(() => import('./pages/leaderboard'))
const RedirectComponent: React.FC = React.lazy(() => import('./components/redirect'))

const App: React.FC = () => {
  const { t } = useTranslation('common')
  
  const routes = React.useMemo(
    (): AppRoutes[] => [
      {
        key: 1,
        title: t('routes.home'),
        path: '/home',
        component: <Home />,
      },
      {
        key: 6,
        title: t('routes.trade'),
        path: '/trade/:address',
        component: <Trade />,
      },
      {
        key: 2,
        title: t('routes.trade'),
        path: '/trade',
        component: <Trade />,
      },
      {
        key: 7,
        title: t('routes.liquidity'),
        path: '/liquidity/:address',
        component: <Liquidity />,
      },
      {
        key: 3,
        title: t('routes.liquidity'),
        path: '/liquidity',
        component: <Liquidity />,
      },
      {
        key: 8,
        title: t('routes.leaderboard'),
        path: '/leaderboard',
        component: <Leaderboard />,
      },
      {
        key: 4,
        title: t('routes.ui'),
        path: '/ui',
        component: <Ui />,
      },
      {
        key: 5,
        title: t('routes.redirect'),
        path: '/redirect/:category/:address',
        component: <RedirectComponent />,
      },
      
    ],
    [t],
  )

  return (
    <React.Suspense fallback={
      <Flex variant='loading-wrapper' justifyContent='center' alignItems='center'>
        <Loader />
      </Flex>
    }>
      <Box p={['md', 'md', 'md', 'xl']} minWidth='1260px' id='root-main-wrapper'>
        <BrowserRouter>
          <Providers>
            <Web3ReactManager>
              <Switch>
                {routes &&
                  routes.map(route => {
                    if (NETWORK_CHAIN_ID === 42 && route.key === 8) return null
                    return (
                    <Route key={route.key} exact path={route.path}>
                      <RouteContext.Provider
                        value={{
                          pageTitle: route.title,
                        }}
                      >
                        {route.component}
                      </RouteContext.Provider>
                    </Route>
                  )})}
                <Redirect from='/' to='/home' />
              </Switch>
            </Web3ReactManager>
          </Providers>
        </BrowserRouter>
      </Box>
    </React.Suspense>
  )
}



const Providers: React.FC = ({ children }) => (
  
      <PlatformContextProvider>
        <Cfd>
          <DrawerProvider>
            <ModalsProvider>
              {children}
              <ToastContainer
                position='top-right'
                autoClose={800}
                hideProgressBar
                newestOnTop
                closeOnClick
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </ModalsProvider>
          </DrawerProvider>
        </Cfd>
      </PlatformContextProvider>

)

export default App
