import { Contract } from '@ethersproject/contracts'
import { ChainId, WETH } from '../sdk'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { useMemo } from 'react'
import ENS_ABI from '../constants/abis/ens-registrar.json'
import ENS_PUBLIC_RESOLVER_ABI from '../constants/abis/ens-public-resolver.json'
import ERC20_BYTES32_ABI from '../constants/abis/erc20_bytes32.json'
import ERC20_ABI from '../constants/abis/erc20.json'
//import { MIGRATOR_ABI, MIGRATOR_ADDRESS } from '../constants/abis/migrator'
import UNISOCKS_ABI from '../constants/abis/unisocks.json'
import WETH_ABI from '../constants/abis/weth.json'
import CFD_ABI from '../constants/abis/cfd.json'
import CFD_VAULT_ABI from '../constants/abis/cfd-vault.json'
import CFD_VIEWER_ABI from '../constants/abis/cfd-viewer.json'
import CFD_STATE_ABI from '../constants/abis/cfd-state.json'
import EXCHANGE_ABI from '../constants/abis/exchange.json'
import EXCHANGE_READER_ABI from '../constants/abis/exchange-reader.json'
import SYSTEM_SETTINGS_ABI from '../constants/abis/system-settings.json'
import { MULTICALL_ABI, MULTICALL_NETWORKS } from '../constants/multicall'
//import { V1_EXCHANGE_ABI, V1_FACTORY_ABI, V1_FACTORY_ADDRESSES } from '../constants/v1'
import { getContract } from '../utils'
import { useActiveWeb3React } from './index'

// returns null on errors
function useContract(
  address: string | undefined,
  ABI: any,
  withSignerIfPossible = true,
): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(
        address,
        ABI,
        library,
        withSignerIfPossible && account ? account : undefined,
      )
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useCFDContract(
  cfdContractAddress?: string,
  withSignerIfPossible?: boolean,
): Contract | null {
  return useContract(cfdContractAddress, CFD_ABI, withSignerIfPossible)
}

export function useCFDVaultContract(
  cfdVaultContractAddress?: string,
  withSignerIfPossible?: boolean,
): Contract | null {
  return useContract(cfdVaultContractAddress, CFD_VAULT_ABI, withSignerIfPossible)
}

export function useCFDViewerContract(
  cfdViewerContractAddress?: string,
  withSignerIfPossible?: boolean,
): Contract | null {
  return useContract(cfdViewerContractAddress, CFD_VIEWER_ABI, withSignerIfPossible)
}

export function useCFDStateContract(
  cfdStateContractAddress?: string,
  withSignerIfPossible?: boolean,
): Contract | null {
  return useContract(cfdStateContractAddress, CFD_STATE_ABI, withSignerIfPossible)
}

export function useSystemSettingsContract(
  systemSettingsAddress?: string,
  withSignerIfPossible?: boolean,
): Contract | null {
  return useContract(
    systemSettingsAddress,
    SYSTEM_SETTINGS_ABI,
    withSignerIfPossible,
  )
}

export function useExchangeContract(
  exchangeAddress?: string,
  withSignerIfPossible?: boolean,
): Contract | null {
  return useContract(
    exchangeAddress,
    EXCHANGE_ABI,
    withSignerIfPossible,
  )
}

function getExchangeContract(
  library: any, 
  account: any,
  address: string | undefined,
  ABI: any,
  withSignerIfPossible = true,
): Contract | null {
  if (!address || !ABI || !library) return null
  try {
    return getContract(
      address,
      ABI,
      library,
      withSignerIfPossible && account ? account : undefined,
    )
  } catch (error) {
    console.error('Failed to get contract', error)
    return null
  }
}

export function useExchangeContracts(
  exchangeAddresses: any = [],
  withSignerIfPossible?: boolean,
): Contract[] {
  const { library, account } = useActiveWeb3React()
  // console.log('useExchangeContracts')
  return useMemo(() => exchangeAddresses.map((exchangeAddress: string) => getExchangeContract(
    library,
    account,
    exchangeAddress,
    EXCHANGE_ABI,
    withSignerIfPossible,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  )), [exchangeAddresses, library, account])
}

export function useExchangeReaderContract(
  exchangeReaderAddress?: string,
  withSignerIfPossible?: boolean,
): Contract | null {
  return useContract(
    exchangeReaderAddress,
    EXCHANGE_READER_ABI,
    withSignerIfPossible,
  )
}

export function useTokenContract(
  tokenAddress?: string,
  withSignerIfPossible?: boolean,
): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(
  withSignerIfPossible?: boolean,
): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(
    chainId ? WETH[chainId].address : undefined,
    WETH_ABI,
    withSignerIfPossible,
  )
}

export function useENSRegistrarContract(
  withSignerIfPossible?: boolean,
): Contract | null {
  const { chainId } = useActiveWeb3React()
  let address: string | undefined
  if (chainId) {
    switch (chainId) {
      case ChainId.MAINNET:
      case ChainId.GÖRLI:
      case ChainId.ROPSTEN:
      case ChainId.RINKEBY:
        address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
        break
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(
  address: string | undefined,
  withSignerIfPossible?: boolean,
): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(
  tokenAddress?: string,
  withSignerIfPossible?: boolean,
): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function usePairContract(
  pairAddress?: string,
  withSignerIfPossible?: boolean,
): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(
    chainId && MULTICALL_NETWORKS[chainId],
    MULTICALL_ABI,
    false,
  )
}

export function useSocksController(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(
    chainId === ChainId.MAINNET
      ? '0x65770b5283117639760beA3F867b69b3697a91dd'
      : undefined,
    UNISOCKS_ABI,
    false,
  )
}
