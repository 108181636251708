import React, { useEffect, useMemo, useState } from 'react'
import BigNumber from 'bignumber.js'
import {
  Bar,
  ErrorCallback,
  HistoryCallback,
  IBasicDataFeed,
  IDatafeedQuotesApi,
  LibrarySymbolInfo,
  OnReadyCallback,
  ResolveCallback,
  SearchSymbolsCallback,
  SubscribeBarsCallback,
  ResolutionString
} from '../lib/charting_library'
import { request } from 'graphql-request'
//import { subscribeOnStream, unsubscribeFromStream } from './streaming.js';
// Contexts
import { PlatformContext } from '../contexts'

import { CandleStick, QUERY_POSITION_CHANGED_EVENTS, subscribeCandles } from '../queries'
import { SUBGRAPH_URL_WS } from '../constants'
import { QUERY_CANDLES } from '../queries'
import { SUBGRAPH_URL_KOVAN, DATE_FORMAT, NUMBER_FORMAT } from '../constants'
import { WebSocketLink } from '@apollo/client/link/ws';
import { ApolloClient, gql, InMemoryCache, useSubscription } from '@apollo/client';
//import ApolloClient from 'apollo-client';
//import { SubscriptionClient } from 'subscriptions-transport-ws';
//import { UseQueryResult } from 'react-query'
import { useActiveWeb3React } from './useActiveWeb3React'
import EXCHANGE_ABI from '../constants/abis/exchange.json'
import { getContract } from '../utils'
import { getSpotPrice } from '../utils/exchange'
//import { ApolloClient, gql, InMemoryCache, useSubscription } from '@apollo/client';
//import { QUERY_CANDLES } from '../queries'

export const SUBSCRIBE_CANDLES_TEST = gql`
  subscription SpotPriceStatistics {
    spotPriceStatistics(
      first: 1,
      orderBy: timestamp,
      orderDirection: desc,
      where: {
        type: 0,
        pairName: "BTC/USDC"
      }
    ) {
      type
      version
      seq
      startTime
      open
      high
      low
      close
      time
      volume
      quoteAssetVol
      txCount
      timestamp
    }
  }
`
export const Q_TEST_CANDLES = gql`
query SpotPriceStatistics {
  spotPriceStatistics(
    first: 1,
    orderBy: timestamp,
    orderDirection: desc,
    where: {
      type: 0,
      pairName: "BTC/USDC"
    }
  ) {
    type
    version
    seq
    startTime
    open
    high
    low
    close
    volume
    quoteAssetVol
    txCount
    timestamp
  }
}
`
export const Q_TEST_STAT = gql`
query StatisticalQuery {
  statisticalQuery(
      amm: "0x7E22E23162423d679dD5088f91D0CA6ff95606FA",
      eid: 3,
      time: 0,
  ) {
    eid
    result
    timeStamp
    count
    amm
  }
}
`
export const SUBSCRIBE_TEST = gql`
  subscription PositionChangedEvents{
    positionNewEvent {
      trader
      exchange
      quoteAssetVol
      timeStamp
      volume
    }
  }
`



export function stick2Bar(stick: CandleStick): Bar {
  return {
    time: stick.startTime * 1000,
    open: Number(new BigNumber(stick.open).dividedBy(1e18).toFixed(2)),
    close: Number(new BigNumber(stick.close).dividedBy(1e18).toFixed(2)),
    high: Number(new BigNumber(stick.high).dividedBy(1e18).toFixed(2)),
    low: Number(new BigNumber(stick.low).dividedBy(1e18).toFixed(2)),
    volume: Number(new BigNumber(stick.volume).dividedBy(1e18).absoluteValue().toFixed(2)),
  }
}



type DataFeed = IBasicDataFeed | (IBasicDataFeed & IDatafeedQuotesApi)

const resolutionIds = ['1', '5', '15', '30', '60', '240', '480', '1D', '7D']  as ResolutionString[]




export function useDataFeed(client?: any) {
  const { PlatformState } = React.useContext(PlatformContext)
  const { activePair, contractLists } = PlatformState

  const [resolutionCall, setResolutionCall] = useState(1)

  const [aClient, setAClient]:any = useState(new ApolloClient({
    cache: new InMemoryCache()
  }))
/*
  const { data, loading } = useSubscription(
    SUBSCRIBE_TEST,
    { variables: { },
      client: aClient
     }
  );
  */
  //console.log('loading', loading)
  //console.log('data', data?.spotPriceStatistics[0])
  //console.log('data', data)
// ws://95.217.9.45:8001/subgraphs/name/cfdswap/cfdswap-subgraph
/*
  //console.log('loading', loading)
  //console.log('data', data)

  useEffect(() => {
    if (aClient?.subscriptionClient?.uri !== SUBGRAPH_URL_WS) {
      const wsLink = new WebSocketLink({
        uri: SUBGRAPH_URL_WS,
        options: {
          reconnect: true
        }
      });
      console.log('wsLink', wsLink)
      const client = new ApolloClient({
        cache: new InMemoryCache(),
        link: wsLink,
      });
      const observable = client.subscribe<any>({
        query: SUBSCRIBE_TEST
      })
      setAClient(client)
    }
  }, [setAClient])
  */
  const { library, account } = useActiveWeb3React()

  const resolveResolutionToType = (resolution:string) => {
    let res = 0;
    switch (resolution) {
      case '7D':
        setResolutionCall(8)
        res = 8
        break;
      case '1D':
        setResolutionCall(7)
        res = 7
        break;
      case '480':
        setResolutionCall(6)
        res = 6
        break;
      case '240':
        setResolutionCall(5)
        res = 5
        break;
      case '60':
        setResolutionCall(4)
        res = 4
        break;
      case '30':
        setResolutionCall(3)
        res = 3
        break;
      case '15':
        setResolutionCall(2)
        res = 2
        break;
      case '5':
        setResolutionCall(1)
        res = 1
        break;
      case '1':
        setResolutionCall(0)
        res = 0
        break;
      default:
        setResolutionCall(4)
        res = 4
        break;
    }
    return res;
  }

  function handleReady(callback: OnReadyCallback) {
      setTimeout(() => callback({ supported_resolutions: resolutionIds }), 0)
  }

  function handleResolveSymbol(symbolName: string, onResolve: ResolveCallback, onError: ErrorCallback) {
    console.log('[resolveSymbol]: Method call', symbolName);
      const symbolInfo = {
          name: symbolName,
          supported_resolutions: resolutionIds,
          session: '24x7',
          has_intraday: true,
          has_empty_bars: true,
          minmov: 1,
          pricescale: 100,
      }
      setTimeout(() => onResolve(symbolInfo as LibrarySymbolInfo), 0)
  }
  const globalBars: any = React.useRef([])
  const handleGetBars = React.useCallback(async (
    symbolInfo: LibrarySymbolInfo,
    resolution: string,
    rangeStartDate: number,
    rangeEndDate: number,
    onResult: HistoryCallback,
    onError: ErrorCallback,
    isFirstCall: boolean,
  ) => {
    //console.log('[getBars]: Method call', symbolInfo, resolution);
    //console.log('[getBars]: Method library, account', library, account);
    //console.log('contractLists', contractLists)
    try {
      
      const exchangeSelected = contractLists?.data.find((a:any) => a.name === symbolInfo.name) 
      /*
      const exchangeContract = getContract(
        String(exchangeSelected?.addr),
        EXCHANGE_ABI,
        library!,
        undefined,
      )
      */
      //console.log('activePair!.addr', activePair!.addr)
      //console.log('exchangeContract', exchangeContract)
      
      //const spotPrice = (PlatformState.chainEnabled) ? new BigNumber(await getSpotPrice(exchangeContract)).dividedBy(1e18) : new BigNumber(0)
      //const ammAddress = activePair!.addr
      //const sticks: CandleStick[] = await queryCandles(client!, ammAddress, resolution)
      /*
      const { candles } = bars
      await bars.candlesRefetch()
      console.log('rangeStartDate',rangeStartDate)
      console.log('rangeEndDate',rangeEndDate)
      console.log('bars', bars)
      console.log('candles', candles)
      const sticks: CandleStick[] = candles
      console.log('sticks', sticks)
      const bars2: Bar[] = sticks
          .filter(stick => stick.startTime >= rangeStartDate && stick.startTime < rangeEndDate && stick.txCount !== 0)
          .sort((a, b) => a.startTime - b.startTime)
          .map(stick => stick2Bar(stick))
      const noData = bars2.length === 0
      console.log('bars2', bars2)
      console.log('noData', noData)
      //setCallOnResult(onResult)
      //if (!noData) {
        onResult(bars2, { noData })
      //}
      */
    const type = resolveResolutionToType(resolution)
    const { spotPriceStatistics } = await request(SUBGRAPH_URL_KOVAN, QUERY_CANDLES, {
      type: type, 
      pairName: symbolInfo.full_name,
    })


    const { positionChangedEvents } = await request(SUBGRAPH_URL_KOVAN, QUERY_POSITION_CHANGED_EVENTS, {
      ammHex: String(exchangeSelected?.addr).toLowerCase()
    })
    
    // console.log('spotPriceStatistics', spotPriceStatistics)
    const sticks: CandleStick[] = spotPriceStatistics
      //console.log('sticks', sticks)
      const bars2: Bar[] = sticks
          .filter(stick => stick.startTime >= rangeStartDate && stick.startTime < rangeEndDate && stick.txCount !== 0)
          .sort((a, b) => a.startTime - b.startTime)
          .map(stick => stick2Bar(stick))
      const noData = bars2.length === 0
      /*
      console.log('bars2', bars2)
      console.log('noData', noData)
      console.log('spotPrice', spotPrice?.toFixed(18))
      console.log('bars2', bars2[bars2.length-1])
      */
     //console.log('positionChangedEvents', positionChangedEvents)
     //console.log(new BigNumber(positionChangedEvents[0]?.positionNotional).dividedBy(positionChangedEvents[0]?.exchangedPositionSize).absoluteValue().toFixed(2))
      if (bars2[bars2.length-1])
        bars2[bars2.length-1].close = Number(new BigNumber(positionChangedEvents[0]?.positionNotional).dividedBy(positionChangedEvents[0]?.exchangedPositionSize).absoluteValue().toFixed(2))

      globalBars.current = bars2
      onResult(bars2, { noData })
    } catch (e) {
      console.error('[getBars]: Error', e)
    }
    
  },[contractLists?.data])

  function handleSearchSymbols(
      userInput: string,
      exchange: string,
      symbolType: string,
      onResult: SearchSymbolsCallback,
  ) {
      // TBD
  }

  const subscription: ZenObservable.Subscription | any = React.useRef<any>()

  const handleSubscribeBars = React.useCallback((
    symbolInfo: LibrarySymbolInfo,
    resolution: string,
    onTick: SubscribeBarsCallback,
    subscribeUID: string,
    onResetCacheNeededCallback: () => void,
  ) => {
    //console.log('[subscribeBars]: Method call with symbolInfo:', symbolInfo);
    //console.log('[subscribeBars]: Method call with subscribeUID:', subscribeUID);
    //console.log('[subscribeBars]: Method call with resolution:', resolution);
    //console.log('[subscribeBars]: Method call with contractLists:', contractLists);
    try {
      //console.log('PlatformState', PlatformState)
      const exchangeSelected = contractLists?.data.find((a:any) => a.name === symbolInfo.name) 
      //console.log('[subscribeBars]: Method call with exchangeSelected:', exchangeSelected);

      // const exchangeContract = getContract(
      //   String(exchangeSelected?.addr),
      //   EXCHANGE_ABI,
      //   library!,
      //   undefined,
      // )
  
      subscription.current = subscribeCandles(async (resp) => {
        //console.log('sub resp', resp)
        if (resp?.data?.positionNewEvent?.exchange === String(exchangeSelected?.addr).toLowerCase()) {
          //console.log('our pair!', resp)
          const newPrice = new BigNumber(resp?.data?.positionNewEvent?.quoteAssetVol).dividedBy(resp?.data?.positionNewEvent?.volume).absoluteValue().toFixed(2)
          const updateCandle = globalBars.current[globalBars.length-1]
          if (updateCandle?.close) {
            updateCandle.close = newPrice
            onTick(updateCandle)
          }
        }
        //console.log('exchangeContract', exchangeContract)
        //const spotPrice = (PlatformState.chainEnabled) ? new BigNumber(await getSpotPrice(exchangeContract)).dividedBy(1e18) : new BigNumber(0)
        //updateCandle.time = new BigNumber(updateCandle.time).dividedBy(1000).plus(100).toNumber()
        //console.log('updateCandle', updateCandle)
        //onTick(stick2Bar(updateCandle))
        
      })

    } catch (e) {
      console.log('e', e)
    }
    
  },[contractLists?.data, globalBars])
  /*
  function handleSubscribeBars(
      symbolInfo: LibrarySymbolInfo,
      resolution: string,
      onTick: SubscribeBarsCallback,
      subscribeUID: string,
      onResetCacheNeededCallback: () => void,
  ) {
    console.log('[subscribeBars]: Method call with subscribeUID:', subscribeUID);
    console.log('[subscribeBars]: Method call with resolution:', resolution);
    console.log('[subscribeBars]: Method call with onTick:', onTick);
    console.log(onTick);
      const ammAddress = activePair!.addr
      //console.log('resolution', resolution)
      //console.log('dataFeed2.setResolutionCall', resolutionCall)
      //bars.candlesRefetch()
      /*
      subscription = subscribeCandles(client!, ammAddress, resolution, res => {
          onTick(stick2Bar(res.data.onUpsertCandleStick))
      })
      */
      //onTick(stick2Bar())
      /*
      subscription = subscribeCandles(client!, ammAddress, resolution, res => {
          onTick(stick2Bar(res.data.onUpsertCandleStick))
      })
      */
     /*
     subscribeOnStream(
      symbolInfo,
      resolution,
      onTick,
      subscribeUID,
      onResetCacheNeededCallback,
      lastBarsCache.get(symbolInfo.full_name)
      );
      */
  //}

  function handleUnsubscribeBars(subscriberUID: string) {
    console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
    //unsubscribeFromStream(subscriberUID);
      if (subscription) {
          subscription.current.unsubscribe()
      }
  }

  const datafeed: DataFeed | null = useMemo(
    function createDatafeed() {
      if (activePair) {
        return {
          onReady: handleReady,
          resolveSymbol: handleResolveSymbol,
          getBars: handleGetBars,
          searchSymbols: handleSearchSymbols,
          subscribeBars: handleSubscribeBars,
          unsubscribeBars: handleUnsubscribeBars,
        }
      } else {
        return null
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client, activePair],
  )
  
  return { datafeed }
}
