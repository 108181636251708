import { useCallback } from 'react'

import BigNumber from 'bignumber.js'
import { BigNumber as bb } from '@ethersproject/bignumber'
import { useCfd } from '.'

import { addMargin } from '../utils/cfd'

const useAddMargin = (exchangeAddress: string) => {
  const { cfdContract, account } = useCfd()

  const handleAddMargin = useCallback(
    async (addedMargin: any) => {
      console.log('handleAddMargin addedMargin', addedMargin)
      const addedMarginRaw = new BigNumber(addedMargin).multipliedBy(1e18)
      if (cfdContract && account && exchangeAddress) {
        try {
          const tx = await addMargin(
            cfdContract,
            exchangeAddress,
            bb.from(addedMarginRaw.toFixed(0)),
          )
          return tx
        } catch (e) {
          return e
        }
      }
    },
    [cfdContract, account, exchangeAddress],
  )

  return { onAddMargin: handleAddMargin }
}

export default useAddMargin
