import { useEffect, useState } from 'react'

const DEFAULT_DELAY = 200

interface DebounceArgs<T> {
    value: T
    delay?: number
    onBeforeDebounce?: Function
    onAfterDebounce?: Function
}

// modified from https://usehooks.com/useDebounce/
export default function useDebounce<T>({ value, delay = DEFAULT_DELAY, onBeforeDebounce, onAfterDebounce }: DebounceArgs<T>) {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    if (onBeforeDebounce) {
        onBeforeDebounce(value)
    }
    const handler = setTimeout(() => {
        setDebouncedValue(value)
        if (onAfterDebounce) {
            onAfterDebounce(value)
        }
    }, delay)

    // Cancel the timeout if value changes (also on delay change or unmount)
    // This is how we prevent debounced value from updating if value is changed ...
    // .. within the delay period. Timeout gets cleared and restarted.
    return () => {
      clearTimeout(handler)
    }
  }, [value, delay, onBeforeDebounce, onAfterDebounce])

  return debouncedValue
}
