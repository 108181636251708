//import CFD from '../constants/abis/cfd.json'
//import BigNumber from 'bignumber.js'
import { calculateGasMargin } from './common-contract'
// read
// getLpTokenPrice
export const getLpTokenPrice = async (
  CFDVaultContract: any,
  exchange: string,
) => {
  return CFDVaultContract.functions.getLpTokenPrice(exchange)
}

// write

export const addLiquidity = async (
  CFDVaultContract: any,
  exchange: string,
  quoteAssetAmount: any,
) => {
  let useExact = false
  const estimatedGas = await CFDVaultContract.estimateGas
    .addLiquidity(exchange, [quoteAssetAmount])
    .catch((e: any) => {
      console.log('e', e)
      // general fallback for tokens who restrict approval amounts
      useExact = true
      return CFDVaultContract.estimateGas.addLiquidity(exchange, [quoteAssetAmount])
    })

  return CFDVaultContract.addLiquidity(exchange, [quoteAssetAmount], {
    gasLimit: calculateGasMargin(estimatedGas),
  })
    .then((response: any) => {
      console.log(response)
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to addLiquidity', error)
      throw error
    })
}

export const removeLiquidity = async (
  CFDVaultContract: any,
  exchange: string,
  lpTokenAmount: any,
) => {
  let useExact = false
  const estimatedGas = await CFDVaultContract.estimateGas
    .removeLiquidity(exchange, [lpTokenAmount])
    .catch((e: any) => {
      console.log('e', e)
      // general fallback for tokens who restrict approval amounts
      useExact = true
      return CFDVaultContract.estimateGas.removeLiquidity(exchange, [lpTokenAmount])
    })

  return CFDVaultContract.removeLiquidity(exchange, [lpTokenAmount], {
    gasLimit: calculateGasMargin(estimatedGas),
  })
    .then((response: any) => {
      console.log(response)
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to removeLiquidity', error)
      throw error
    })
}

export const getTotalLpLiquidityAndUnrealizedPNL = async (
  CFDVaultContract: any,
  exchange: string,
) => {
  return CFDVaultContract.functions.getTotalLpLiquidityAndUnrealizedPNL(exchange)
}

export const getMMCachedLiquidity = async (
  CFDVaultContract: any,
  exchange: string,
) => {
  return CFDVaultContract.functions.getMMCachedLiquidity(exchange)
}